import * as React from 'react';

export const Location = React.memo(
	({ size = 16, color = '#363E4F', ...rest }) => (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M6.5 7C6.5 6.17157 7.17157 5.5 8 5.5C8.82843 5.5 9.5 6.17157 9.5 7C9.5 7.82843 8.82843 8.5 8 8.5C7.17157 8.5 6.5 7.82843 6.5 7Z"
				fill={color}
			/>
			<path
				d="M8 1C11.3137 1 14 3.68629 14 7C14 8.97111 13.0387 10.5862 11.87 11.8723C10.8294 13.0175 9.58368 13.9443 8.62805 14.6553C8.51431 14.7399 8.40469 14.8215 8.3 14.9C8.12222 15.0333 7.87778 15.0333 7.7 14.9C7.59538 14.8215 7.48562 14.7399 7.37195 14.6553C6.41632 13.9443 5.17063 13.0175 4.12996 11.8723C2.96125 10.5862 2 8.97111 2 7C2 3.68629 4.68629 1 8 1ZM3 7C3 8.62489 3.78875 10.0098 4.87004 11.1997C5.79729 12.2201 6.89837 13.0556 8 13.876C9.10162 13.0556 10.2027 12.2201 11.13 11.1997C12.2113 10.0098 13 8.62489 13 7C13 4.23858 10.7614 2 8 2C5.23858 2 3 4.23858 3 7Z"
				fill={color}
			/>
		</svg>
	)
);

Location.displayName = 'Location';
