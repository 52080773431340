import * as React from 'react';

export const ItiliteLogoWithoutText = React.memo(
	({ size = 16, color = '#EC5D25', className, ...rest }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...rest}
			>
				<path
					d="M16 8C16 12.4172 12.4172 16 8 16C3.58282 16 0 12.4172 0 8C0 3.58282 3.58282 0 8 0C12.4172 0 16 3.58282 16 8ZM13.9877 4.42945L13.8282 4.31902L13.7178 4.30675H13.3988H13.2883H13.1779L12.9571 4.41718L12.6258 4.62577L12.184 4.95706L11.7423 5.28834L11.2147 5.71779L9.32515 7.27607L8.82209 7.28834L6.7362 7.10429L5.37423 7.01841L5.22699 7.05522L3.70552 7.90184L3.6319 8L3.61963 8.08589L3.70552 8.13497L6.74847 9.6319L5.22699 11.1656L3.33742 10.9325L2.95706 10.9448L1.97546 11.5951L1.92638 11.7791L3.91411 12.6871H4.28221L5.23926 12.2577L7.82822 10.8589L10.908 8.23313L13.0798 6.29448L13.7546 5.61963L13.9386 5.30061L14.0491 5.09203L14.1595 4.76074V4.65031L14.1104 4.53988L13.9877 4.42945Z"
					fill={color}
				/>
			</svg>
		);
	}
);

ItiliteLogoWithoutText.displayName = 'ItiliteLogoWithoutText';
