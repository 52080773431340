import * as React from 'react';

export const Check = React.memo(
	({ size = 14, color = '#111827', className, ...rest }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 14 10"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...rest}
			>
				<path
					d="M13.4225 0.244078C13.7479 0.569515 13.7479 1.09715 13.4225 1.42259L5.08917 9.75592C4.76374 10.0814 4.2361 10.0814 3.91066 9.75592L0.57733 6.42259C0.251893 6.09715 0.251893 5.56951 0.57733 5.24408C0.902767 4.91864 1.4304 4.91864 1.75584 5.24408L4.49992 7.98816L12.244 0.244078C12.5694 -0.0813592 13.0971 -0.0813592 13.4225 0.244078Z"
					fill={color}
				/>
			</svg>
		);
	}
);
