import { styles } from './__variable';

const __globals = {
	token: {
		fontFamily: ["'Inter'", 'sans-serif'].join(','),
		...styles, //Need to revisit this once component colors and hover states are finalised.
	},
};

export default __globals;
