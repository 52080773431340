import React from 'react';
import PropTypes from 'prop-types';

const TableComp = (props) => {
	const { columns, dataSource, ...rest } = props;
	return <Table dataSource={dataSource} columns={columns} {...rest} />;
};

TableComp.propTypes = {
	columns: PropTypes.array,
	dataSource: PropTypes.array,
};

export const Table = React.memo(TableComp);
