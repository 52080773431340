import * as React from 'react';
import { AutoComplete as AntAutoComplete } from 'antd';
import PropTypes from 'prop-types';

const AutoCompleteComp = ({
	// children,
	filterOption,
	options,
	onSelect,
	className,
	style,
	...rest
}) => {
	return (
		<AntAutoComplete
			className={className}
			style={style}
			options={options}
			filterOption={filterOption}
			onSelect={onSelect}
			{...rest}
		/>
	);
};

AutoCompleteComp.propTypes = {
	className: PropTypes.string,
	filterOption: PropTypes.func,
	options: PropTypes.any,
	style: PropTypes.any,
	onSelect: PropTypes.func,
};

export const AutoComplete = React.memo(AutoCompleteComp);
