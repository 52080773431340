import { appModules } from 'auth/appModuleConfig';

import routeURLs from '../utils/urls/routeUrls';

function routeDefaultRedirect(route) {
	let returnRoute = '';
	switch (route) {
		case appModules.createTrip:
			returnRoute = routeURLs.createTrip.results;
			break;
		case appModules.teamEvents:
			returnRoute = routeURLs.teamEvents.default;
			break;
		case appModules.carServices:
			returnRoute = routeURLs.carServices.results;
			break;
		default:
			returnRoute = null;
			break;
	}
	return returnRoute;
}

export { routeDefaultRedirect };
