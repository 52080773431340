import * as React from 'react';
import { Upload as FileUploader } from 'antd';
import PropTypes from 'prop-types';

const UploadComp = (props) => {
	const {
		accept = 'image/*',
		action = null,
		children,
		onChange = () => {},
		onDrop = () => {},
		onDownload = () => {},
		onPreview = () => {},
		onRemove = () => {},
		...rest
	} = props;
	return (
		<FileUploader
			action={action}
			accept={accept}
			onChange={onChange}
			onDrop={onDrop}
			onDownload={onDownload}
			onPreview={onPreview}
			onRemove={onRemove}
			{...rest}
		>
			{children}
		</FileUploader>
	);
};

UploadComp.propTypes = {
	title: PropTypes.string,
	placement: PropTypes.string,
	color: PropTypes.string,
};

export const Upload = React.memo(UploadComp);
