import React from 'react';
import { Dropdown as AntDropdown } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './Dropdown.module.scss';

const DropdownComp = (props) => {
	const { className, items = [], children } = props;
	return (
		<AntDropdown
			className={clsx(classes.dropdown, className)}
			menu={{
				items,
			}}
			trigger={['click', 'hover']}
		>
			{children}
		</AntDropdown>
	);
};

DropdownComp.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	items: PropTypes.array,
};

export const Dropdown = React.memo(DropdownComp);
