import * as React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './DotSlider.module.scss';

const DotComp = (props) => {
	const {
		show,
		numberOfDots,
		currentIndex,
		totalCount,
		setCurrentSlide,
		currentAction,
	} = props;

	const [activeDot, setActiveDot] = React.useState(0);

	const dotsLength = numberOfDots.length;

	React.useEffect(() => {
		if (currentAction === 'rt') {
			if (currentIndex === totalCount - 1) {
				setActiveDot(dotsLength - 1);
			}
			if (currentIndex > 2 && currentIndex !== totalCount - 1) {
				if (activeDot < dotsLength - 3) {
					setActiveDot(activeDot + 1);
				} else {
					setActiveDot(dotsLength - 2);
				}
			}
			if (currentIndex <= 2) {
				setActiveDot(currentIndex);
			}
		} else {
			if (currentIndex === 0) {
				setActiveDot(0);
			} else {
				if (activeDot > 2) setActiveDot(activeDot - 1);
				else setActiveDot(1);
			}
		}
	}, [currentIndex]);

	return show ? (
		<div
			className={clsx(
				'tw-absolute tw-bottom-0 tw-flex tw-justify-center tw-to-black/10 !tw-rounded-xl tw-w-full tw-h-9',
				classes['shadow']
			)}
		>
			{numberOfDots.map((key, index) => {
				return (
					<div
						onClick={() => {
							setCurrentSlide((prevState) => ({
								...prevState,
								currentSlide: prevState.currentSlide + 1,
							}));
						}}
						key={key}
						className={clsx({
							'tw-text-white tw-cursor-pointer tw-h-1 tw-w-1 tw-bg-white tw-rounded-full tw-mb-0 tw-ml-1 tw-mr-1 tw-opacity-70 tw-mt-4': true,
							'!tw-w-2 tw-rounded-xl tw-opacity-100 tw-transition tw-opacity tw-duration-250 tw-ease-in':
								index === activeDot,
						})}
					></div>
				);
			})}
		</div>
	) : null;
};

DotComp.propTypes = {
	show: PropTypes.bool,
	className: PropTypes.string,
	count: PropTypes.number,
	currentIndex: PropTypes.number,
	totalCount: PropTypes.number,
};

export const DotSlider = React.memo(DotComp);
