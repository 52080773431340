import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import { logErrors } from 'utils';

import carConfirmationReducer from './features/CarServices/slices/Confirmation/carConfirmation.slice';
import carResultsReducer from './features/CarServices/slices/Results/carResults.slice';
import carSelectionReducer from './features/CarServices/slices/Results/carSelection.slice';
import authServiceReducer from './features/common/slice/authService.slice';
import approvalReducer from './features/Createtrip/slices/Approvals/approvals.slice';
import cancelBookingReducer from './features/Createtrip/slices/CancelBooking/cancelBooking.slice';
import confirmationReducer from './features/Createtrip/slices/Confirmation/confirmation.slice';
import paymentServiceReducer from './features/Createtrip/slices/payment/paymentService.slice';
import rescheduleBookingReducer from './features/Createtrip/slices/RescheduleBooking/rescheduleBooking.slice';
import userTripSelectionsReducer from './features/Createtrip/slices/Results/userTripSelections.slice';
import { fastAPI } from './services/apiFast';
import { servicesNonloggedAPI } from './services/apiNonloggedService';
import { paymentAPI } from './services/apiPayment';
import { servicesAPI } from './services/apiService';
import { streamAPI } from './services/apiStream';
import { usmAPI } from './services/apiUSM';

const rtkQueryErrorLogger = (api) => (next) => (action) => {
	if (isRejectedWithValue(action)) {
		const company_ids = api.getState().authService?.clientInfo?.company_ids;
		const user_ids = api.getState().authService?.clientInfo?.user_ids;
		const company_name =
			api.getState().authService?.clientInfo?.company_name;
		const error = {
			Issue: action?.payload,
			'Issue Type': 'Backend API error',
			URL: action?.meta?.baseQueryMeta?.request?.url,
			'Payload/queries': action?.meta?.arg?.originalArgs,
			Browser_URL: window.location.href,
			company_ids,
			user_ids,
			company_name,
		};
		logErrors(error);
	}
	return next(action);
};

const store = configureStore({
	reducer: {
		[usmAPI.reducerPath]: usmAPI.reducer,
		[servicesAPI.reducerPath]: servicesAPI.reducer,
		[servicesNonloggedAPI.reducerPath]: servicesNonloggedAPI.reducer,
		[fastAPI.reducerPath]: fastAPI.reducer,
		[streamAPI.reducerPath]: streamAPI.reducer,
		[paymentAPI.reducerPath]: paymentAPI.reducer,
		userTripSelections: userTripSelectionsReducer,
		authService: authServiceReducer,
		confirmationDetails: confirmationReducer,
		cancelBooking: cancelBookingReducer,
		rescheduleBooking: rescheduleBookingReducer,
		paymentService: paymentServiceReducer,
		approvals: approvalReducer,
		carResults: carResultsReducer,
		carSelection: carSelectionReducer,
		carConfirmation: carConfirmationReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		}).concat(
			usmAPI.middleware,
			servicesAPI.middleware,
			fastAPI.middleware,
			streamAPI.middleware,
			paymentAPI.middleware,
			servicesNonloggedAPI.middleware,
			rtkQueryErrorLogger
		),
});

export { store };
