export const reschedulePageType = {
	SELECTION: 'selection',
	MODIFY: 'modify',
};

export const rescheduleCardType = {
	EDIT: 'edit', // Edit of Card
	NO_ACTION: 'no_action', // Edit of card but disabled
	VIEW: 'view', // View State of card
};
export const rescheduleSummaryStatusCodes = {
	SUCCESS: 'success',
	IN_PROGRESS: 'inprogress',
	FAILED: 'failed',
	NULL: null,
};
export const rescheduleStatusCodes = {
	AUTOMATED_PACKAGING: 'automated_packaging',
	AUTOMATED_BOOKING_INPROGRESS: 'automated_booking_inprogress',
	INPROGRESS: 'inprogress',
	AUTOMATED_BOOKING_COMPLETED: 'automated_booking_completed',
	COMPLETED: 'completed',
	FAILED: 'failed',
};
export const rescheduleUIStatusCodes = {
	NOT_RESCHEDULED: 0,
	RESCHEDULE_PENDING: 1,
	PARTIAL_RESCHEDULE_PENDING: 2,
	PARTIALLY_RESCHEDULED: 3,
	RESCHEDULED: 4,
};
