import { lazy, Suspense, useEffect } from 'react';
import { roleBasedPermission } from 'auth/roleBasedPermissions';

import LocalStorageService from '../services/LocalStorage';

const Page404 = lazy(() => import('views/404'));

const RouteAllowed = (props) => {
	const { fallback = <></>, route, children } = props;
	const role = LocalStorageService.getRole();
	const selectedData = roleBasedPermission[route];
	const isAllowed = selectedData && selectedData.indexOf(role) !== -1;

	const returnData = isAllowed ? children : <Page404 />;
	useEffect(() => {
		if (props.getAppRoute) {
			props.getAppRoute(route);
		}
	}, [props, route]);

	return <Suspense fallback={fallback}>{returnData}</Suspense>;
};

export default RouteAllowed;
