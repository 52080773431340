import React from 'react';

import AnalyticsLoggerContext from './AnalyticsLogContext';
import { sendLog } from './utils';

function getEventHandlers(child, consumedProps, actionProps, logEventProps) {
	const eventHandlers = {};
	// Loop through each action prop
	for (const [eventName, specificHandlerProps] of Object.entries(
		actionProps
	)) {
		// Create a modified event handler that logs data
		eventHandlers[eventName] = (...eventHandlerArgs) => {
			// Call the original event handler
			child.props[eventName]?.apply(child, eventHandlerArgs);
			// Log the event with combined props
			sendLog({
				...consumedProps,
				...specificHandlerProps,
				...logEventProps,
			});
		};
	}
	return eventHandlers;
}

const LogEvent = ({ elementType, children, analytics }) => {
	const { actionProps, logEventProps } = analytics;
	// Check if required props are present
	if (!logEventProps || !actionProps) {
		return children;
	}

	// Wrap the children in a Log component to inherit data props
	return (
		<AnalyticsLoggerContext elementType={elementType} {...logEventProps}>
			{(consumedProps) =>
				// Map over children to inject logging code into event handlers
				React.Children.map(children, (child) => {
					// Get modified event handlers with logging functionality
					const eventHandlers = getEventHandlers(
						child,
						consumedProps,
						actionProps,
						logEventProps
					);
					// Clone the child element with modified event handlers
					return React.cloneElement(child, eventHandlers);
				})
			}
		</AnalyticsLoggerContext>
	);
};

export default LogEvent;
