import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowUpOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import classes from './BackToTopButton.module.scss';

const BackToTopButton = ({ onClick, className }) => {
	const { t } = useTranslation('generic');
	const [showBackToTopButton, setShowBackToTopButton] = useState(false);
	const handleScroll = () => {
		if (
			window.document.getElementById(
				'result-container-for-flights-hotels'
			)?.scrollTop
		) {
			if (
				window.document.getElementById(
					'result-container-for-flights-hotels'
				)?.scrollTop > 100
			) {
				setShowBackToTopButton(true);
			} else {
				setShowBackToTopButton(false);
			}
		}
	};

	useEffect(() => {
		window.document
			.getElementById('result-container-for-flights-hotels')
			?.addEventListener(`scroll`, handleScroll);
		return () =>
			window.document
				.getElementById('result-container-for-flights-hotels')
				?.removeEventListener(`scroll`, handleScroll);
	}, []);

	return showBackToTopButton ? (
		<button
			onClick={onClick}
			className={clsx(classes.backButton, className)}
		>
			{t('back_to_top')} {<ArrowUpOutlined />}
		</button>
	) : null;
};

export default BackToTopButton;
