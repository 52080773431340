import * as React from 'react';

export const Cancel = React.memo(
	({ size = 16, color = '#363E4F', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					d="M12.7929 13L14.6464 14.8536C14.8417 15.0488 15.1583 15.0488 15.3536 14.8536C15.5488 14.6583 15.5488 14.3417 15.3536 14.1464L2.35355 1.14645C2.15829 0.951184 1.84171 0.951184 1.64645 1.14645C1.45118 1.34171 1.45118 1.65829 1.64645 1.85355L2.84095 3.04805C2.07177 3.23225 1.5 3.92435 1.5 4.74999V6.49999C1.5 6.77613 1.72386 6.99999 2 6.99999C2.55228 6.99999 3 7.4477 3 7.99999C3 8.55227 2.55228 8.99999 2 8.99999C1.72386 8.99999 1.5 9.22384 1.5 9.49999V11.25C1.5 12.2165 2.2835 13 3.25 13H12.7929ZM11.7929 12H3.25C2.83579 12 2.5 11.6642 2.5 11.25V9.93698C3.36261 9.71496 4 8.93191 4 7.99999C4 7.06807 3.36261 6.28502 2.5 6.06299V4.74999C2.5 4.33577 2.83579 3.99999 3.25 3.99999H3.79288L11.7929 12Z"
					fill={color}
				/>
				<path
					d="M14.5 11.25C14.5 11.4262 14.4392 11.5882 14.3375 11.7162L15.0466 12.4253C15.3284 12.1147 15.5 11.7024 15.5 11.25V9.49999C15.5 9.22384 15.2761 8.99999 15 8.99999C14.4477 8.99999 14 8.55227 14 7.99999C14 7.4477 14.4477 6.99999 15 6.99999C15.2761 6.99999 15.5 6.77613 15.5 6.49999V4.74999C15.5 3.78349 14.7165 2.99999 13.75 2.99999H5.62134L6.62134 3.99999H13.75C14.1642 3.99999 14.5 4.33577 14.5 4.74999V6.06299C13.6374 6.28502 13 7.06807 13 7.99999C13 8.93191 13.6374 9.71496 14.5 9.93698V11.25Z"
					fill={color}
				/>
			</svg>
		);
	}
);

Cancel.displayName = 'Cancel';
