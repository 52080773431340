import React from 'react';

export const Heart = React.memo(
	({ size = 16, color = '#CF2231', className, ...rest }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...rest}
			>
				<path
					d="M7.54118 3.94749C6.26949 2.6758 4.21213 2.66962 2.94594 3.93581C1.67975 5.202 1.68593 7.25936 2.95762 8.53105L7.66511 13.2385C7.86038 13.4338 8.17696 13.4338 8.37222 13.2385L13.0553 8.55827C14.3185 7.28796 14.3145 5.23637 13.0426 3.96446C11.7686 2.69048 9.7103 2.6843 8.44184 3.95276L7.99458 4.40088L7.54118 3.94749Z"
					fill={color}
				/>
			</svg>
		);
	}
);

Heart.displayName = 'Heart';
