import * as React from 'react';
import { Modal as AntModal } from 'antd';
import clsx from 'clsx';
import { Close } from 'icons';
import PropTypes from 'prop-types';

import { Button } from '../Button';

import { MODAL_VARIANT } from './utils';

import './Modal.scss';

const ModalComp = (props) => {
	const {
		className = '',
		title = '',
		children,
		open = false,
		handleOk = () => {},
		handleCancel = () => {},
		okText = '',
		cancelText = '',
		closeIcon = <Close color="#6B7280" />,
		bodyClassName = 'modalBody',
		outsideClickDisable = false,
		hideCross = false,
		okButtonLoading = false,
		okButtonDisabled = false,
		variant = MODAL_VARIANT.PRIMARY,
		okBtnClasses = '',
		cancelButtonAnalytics = {},
		okButtonAnalytics = {},
		footerMargintopDisable = true,
		...rest
	} = props;
	return (
		<div onClick={(e) => e.stopPropagation()}>
			<AntModal
				title={title}
				open={open}
				onOk={handleOk}
				onCancel={handleCancel}
				className={clsx('modalMain', className, {
					footerMargintopDisable: footerMargintopDisable,
				})}
				okText={okText}
				cancelText={cancelText}
				closeIcon={closeIcon}
				closable={!hideCross}
				maskClosable={!outsideClickDisable}
				footer={
					okText || cancelText ? (
						<div
							className={clsx({
								primaryFooter:
									variant === MODAL_VARIANT.PRIMARY,
								secondaryFooter:
									variant === MODAL_VARIANT.SECONDARY,
							})}
						>
							{cancelText && (
								<Button
									onClick={handleCancel}
									type="default"
									className="cancelBtn"
									{...cancelButtonAnalytics}
								>
									{cancelText}
								</Button>
							)}
							{okText && (
								<Button
									onClick={handleOk}
									className={clsx(okBtnClasses, 'okBtn')}
									loading={okButtonLoading}
									disabled={okButtonDisabled}
									{...okButtonAnalytics}
								>
									{okText}
								</Button>
							)}
						</div>
					) : null
				}
				{...rest}
			>
				<div className={bodyClassName}>{children}</div>
			</AntModal>
		</div>
	);
};

ModalComp.propTypes = {
	title: PropTypes.node,
	open: PropTypes.bool,
	className: PropTypes.string,
	okBtnClasses: PropTypes.string,
	outsideClickDisable: PropTypes.bool,
	hideCross: PropTypes.bool,
	okText: PropTypes.string,
	okButtonLoading: PropTypes.bool,
	okButtonDisabled: PropTypes.bool,
	cancelText: PropTypes.string,
	handleOk: PropTypes.func,
	handleCancel: PropTypes.func,
	closeIcon: PropTypes.element,
	okButtonAnalytics: PropTypes.object,
	cancelButtonAnalytics: PropTypes.object,
};

export const Modal = React.memo(ModalComp);
