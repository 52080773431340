import { Form } from 'antd';
import clsx from 'clsx';

import classes from './FormItem.module.scss';

export const FormItem = ({ name, label, className, children, ...rest }) => {
	return (
		<Form.Item
			name={name}
			label={label}
			className={clsx(classes.FormItemClasses, className)}
			{...rest}
		>
			{children}
		</Form.Item>
	);
};
