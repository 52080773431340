import { ampli } from 'analytics/ampli';

export const sendLog = (props) => {
	const { eventName, customProperties, logImpression } = props;
	const event_properties = {
		...customProperties,
	};
	const event = {
		event_type: eventName,
		event_properties,
		// groups: { 'role': 'engineering' },
		// group_properties: { 'groupPropertyKey': 'groupPropertyValue' }
	};
	if (logImpression) {
		ampli.track(event);
	}
};
