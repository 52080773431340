import * as React from 'react';
import { Segmented } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './SegmentedButton.module.scss';

const SegmentedComp = (props) => {
	const {
		className = '',
		value = null,
		onChange = () => {},
		style = {},
		options = [],
		type = '',
		...rest
	} = props;

	return (
		<Segmented
			className={clsx(
				classes['segmentedButton'],
				classes[type],
				className
			)}
			// value={value}
			onChange={onChange}
			options={options}
			style={style}
			{...rest}
		/>
	);
};

SegmentedComp.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	value: PropTypes.any,
	options: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.object])
	).isRequired,
	style: PropTypes.object,
};

export const SegmentedButton = React.memo(SegmentedComp);
