import * as React from 'react';

export const Promotion = React.memo(
	({ size = 16, color = '#6B7280', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					d="M15 5.12132V11C15 12.1046 14.2165 13 13.25 13H2.75C1.7835 13 1 12.1046 1 11V5C1 3.89543 1.7835 3 2.75 3H13.1438L12.2688 4H2.75C2.26675 4 1.875 4.44772 1.875 5V11C1.875 11.5523 2.26675 12 2.75 12H13.25C13.7332 12 14.125 11.5523 14.125 11V6.12132L15 5.12132ZM2.75 6.5C2.75 6.22386 2.94588 6 3.1875 6H4.9375C5.17912 6 5.375 6.22386 5.375 6.5C5.375 6.77614 5.17912 7 4.9375 7H3.1875C2.94588 7 2.75 6.77614 2.75 6.5ZM2.75 9.5C2.75 9.22386 2.94588 9 3.1875 9H7.125C7.36662 9 7.5625 9.22386 7.5625 9.5C7.5625 9.77614 7.36662 10 7.125 10H3.1875C2.94588 10 2.75 9.77614 2.75 9.5ZM14.8719 3.14645C15.0427 3.34171 15.0427 3.65829 14.8719 3.85355L9.62186 9.85355C9.451 10.0488 9.17399 10.0488 9.00314 9.85355C8.83229 9.65829 8.83229 9.34171 9.00314 9.14645L14.2531 3.14645C14.424 2.95118 14.701 2.95118 14.8719 3.14645Z"
					fill={color}
				/>
			</svg>
		);
	}
);

Promotion.displayName = 'Promotion';
