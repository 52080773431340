import * as React from 'react';

export const Share = React.memo(
	({ size = 16, color = '#fff', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					d="M10.307 2.10533C10.1562 1.98809 9.95188 1.96694 9.78034 2.05085C9.60879 2.13475 9.50001 2.30904 9.50001 2.5V4.49274C9.45712 4.49456 9.41219 4.49701 9.36537 4.5002C8.96247 4.52766 8.41128 4.61105 7.82119 4.83704C7.11343 5.10809 6.34877 5.58508 5.72601 6.41126C5.10338 7.23727 4.64499 8.38259 4.50206 9.95474C4.48301 10.1644 4.5973 10.3635 4.78793 10.4528C4.97857 10.5421 5.20471 10.5024 5.35356 10.3536C6.54248 9.16464 7.72298 8.57773 8.59562 8.28685C8.9558 8.16679 9.2643 8.09693 9.50001 8.0563V10C9.50001 10.1969 9.61557 10.3755 9.79518 10.4561C9.9748 10.5368 10.185 10.5045 10.3322 10.3737L14.8322 6.37371C14.9432 6.27502 15.0046 6.13207 14.9997 5.98361C14.9949 5.83514 14.9242 5.69653 14.807 5.60533L10.307 2.10533ZM9.94293 5.49679L9.94571 5.49705C10.0865 5.51223 10.2279 5.46706 10.3335 5.37257C10.4394 5.27772 10.5 5.14223 10.5 5V3.52232L13.7186 6.02564L10.5 8.88658V7.5C10.5 7.22386 10.2762 7 10 7L9.99892 7L9.99759 7.00001L9.99425 7.00003L9.98483 7.00014L9.9552 7.00087C9.93074 7.00166 9.89695 7.00316 9.85445 7.00599C9.76945 7.01166 9.64951 7.02268 9.49961 7.04409C9.1999 7.08691 8.77971 7.17139 8.2794 7.33816C7.55493 7.57965 6.66479 7.99299 5.7319 8.69863C5.9264 7.98158 6.2077 7.43355 6.52456 7.01319C7.01593 6.36132 7.61523 5.98675 8.17883 5.7709C8.65371 5.58903 9.10252 5.52044 9.43338 5.49788C9.59809 5.48666 9.73137 5.48699 9.82114 5.48988C9.86596 5.49133 9.89972 5.49341 9.92089 5.49498L9.94293 5.49679ZM4.5 3C3.11929 3 2 4.11929 2 5.5V11.5C2 12.8807 3.11929 14 4.5 14H10.5C11.8807 14 13 12.8807 13 11.5V10.5C13 10.2239 12.7761 10 12.5 10C12.2239 10 12 10.2239 12 10.5V11.5C12 12.3284 11.3284 13 10.5 13H4.5C3.67157 13 3 12.3284 3 11.5V5.5C3 4.67157 3.67157 4 4.5 4H6.5C6.77614 4 7 3.77614 7 3.5C7 3.22386 6.77614 3 6.5 3H4.5Z"
					fill={color}
				/>
			</svg>
		);
	}
);

Share.displayName = 'Share';
