import * as React from 'react';

export const FlightTopBar = React.memo(
	({ size = 40, color = '#868D9A', className, ...rest }) => {
		return (
			<svg
				width="14"
				height="15"
				viewBox="0 0 14 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0.75 9.35073L4.63909 13.2398L5.06268 12.8162C5.44313 12.4358 5.53745 11.8546 5.29683 11.3733L4.99264 10.7649L7.82107 8.64362L10.4727 13.4166L11.7275 12.1618C12.0447 11.8446 12.1676 11.3817 12.0496 10.949L10.6495 5.81519L12.6427 3.82203C12.9568 3.50793 13.1775 3.10811 13.2165 2.66562C13.2658 2.10739 13.2623 1.35693 12.9476 1.04222C12.6329 0.727515 11.8824 0.724063 11.3242 0.77328C10.8817 0.812292 10.4819 1.03306 10.1678 1.34716L8.17462 3.34032L3.20437 2.09776C2.7784 1.99127 2.3278 2.11608 2.01732 2.42655L0.75 3.69387L5.34619 6.16875L3.22487 8.99717L2.61649 8.69298C2.13526 8.45237 1.55404 8.54668 1.17359 8.92713L0.75 9.35073Z"
					stroke={color}
					strokeWidth="1.5"
					strokeLinejoin="round"
				/>
			</svg>
		);
	}
);

FlightTopBar.displayName = 'FlightTopBar';
