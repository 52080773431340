import * as React from 'react';

export const BusinessCabin = React.memo(
	({ size = 16, color = '#1B7E49', className }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				viewBox="0 0 16 16"
				fill="none"
				className={className}
			>
				<path
					d="M1.09438 2.20764C1.18836 2.07726 1.33928 2 1.5 2H3.13962C3.57005 2 3.95219 2.27543 4.08831 2.68377L5.86038 8H11.7792C12.4249 8 12.9981 8.41315 13.2023 9.02566L13.755 10.6838C13.9708 11.3313 13.4888 12 12.8063 12H5.22076C4.57512 12 4.00191 11.5869 3.79774 10.9743L1.02566 2.65811C0.974837 2.50564 1.00041 2.33803 1.09438 2.20764Z"
					fill={color}
				/>
				<path
					d="M4.5 13C4.22386 13 4 13.2239 4 13.5C4 13.7761 4.22386 14 4.5 14H13.5C13.7761 14 14 13.7761 14 13.5C14 13.2239 13.7761 13 13.5 13H4.5Z"
					fill={color}
				/>
			</svg>
		);
	}
);

BusinessCabin.displayName = 'BusinessCabin';
