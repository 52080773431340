import * as React from 'react';

export const SystemError = React.memo(
	({ size = 97, color = '#CA8A04', className, ...rest }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 97 96"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...rest}
			>
				<path
					d="M49.811 16L16.4453 16C12.027 16 8.44531 19.5817 8.44531 24V32H49.811C48.9265 29.4978 48.4453 26.8051 48.4453 24C48.4453 21.1949 48.9265 18.5022 49.811 16Z"
					fill="#B6BAC3"
				/>
				<path
					d="M8.44531 32H48.9453"
					stroke="#111827"
					strokeWidth="2"
					strokeLinecap="round"
				/>
				<path
					d="M88.4453 42V80C88.4453 84.4183 84.8636 88 80.4453 88H16.4453C12.027 88 8.44531 84.4183 8.44531 80V24C8.44531 19.5817 12.027 16 16.4453 16H49.4453"
					stroke="#111827"
					strokeWidth="2"
					strokeLinecap="round"
				/>
				<circle
					cx="72.4453"
					cy="24"
					r="19"
					fill="white"
					stroke={color}
					strokeWidth="2"
				/>
				<rect
					x="70.9453"
					y="14"
					width="3"
					height="12"
					rx="1.5"
					fill={color}
				/>
				<circle cx="72.4453" cy="31" r="2" fill={color} />
			</svg>
		);
	}
);

SystemError.displayName = 'SystemError';
