import * as React from 'react';

export const CreditCardWithClock = React.memo(
	({ size = '16', color = '#6B7280', className }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox={`0 0 16 16`}
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
			>
				<path
					d="M3.5 3C2.11929 3 1 4.11929 1 5.5V10.5C1 11.8807 2.11929 13 3.5 13H5.59971C5.43777 12.6832 5.30564 12.3486 5.20703 12H3.5C2.67157 12 2 11.3284 2 10.5V7H6.25716C6.57052 6.62057 6.93379 6.28389 7.33692 6H2V5.5C2 4.67157 2.67157 4 3.5 4H12.5C13.3284 4 14 4.67157 14 5.5V6.25716C14.3794 6.57052 14.7161 6.93379 15 7.33692V5.5C15 4.11929 13.8807 3 12.5 3H3.5Z"
					fill={color}
				/>
				<path
					d="M15 10.5C15 12.9853 12.9853 15 10.5 15C8.01472 15 6 12.9853 6 10.5C6 8.01472 8.01472 6 10.5 6C12.9853 6 15 8.01472 15 10.5ZM10.5 8C10.2239 8 10 8.22386 10 8.5V10.5C10 10.7761 10.2239 11 10.5 11H12C12.2761 11 12.5 10.7761 12.5 10.5C12.5 10.2239 12.2761 10 12 10H11V8.5C11 8.22386 10.7761 8 10.5 8Z"
					fill={color}
				/>
			</svg>
		);
	}
);
