import * as React from 'react';

export const CreditCard = React.memo(({ size = '16', color = '#6B7280' }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox={`0 0 16 16`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.5 10C10.2239 10 10 10.2239 10 10.5C10 10.7761 10.2239 11 10.5 11H12.5C12.7761 11 13 10.7761 13 10.5C13 10.2239 12.7761 10 12.5 10H10.5ZM1 5.5C1 4.11929 2.11929 3 3.5 3H12.5C13.8807 3 15 4.11929 15 5.5V10.5C15 11.8807 13.8807 13 12.5 13H3.5C2.11929 13 1 11.8807 1 10.5V5.5ZM14 6V5.5C14 4.67157 13.3284 4 12.5 4H3.5C2.67157 4 2 4.67157 2 5.5V6H14ZM2 7V10.5C2 11.3284 2.67157 12 3.5 12H12.5C13.3284 12 14 11.3284 14 10.5V7H2Z"
				fill={color}
			/>
		</svg>
	);
});
