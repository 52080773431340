import * as React from 'react';

export const Bill = React.memo(({ color = '#6B7280' }) => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5 4.5C5 4.22386 5.22386 4 5.5 4H10.5C10.7761 4 11 4.22386 11 4.5C11 4.77614 10.7761 5 10.5 5H5.5C5.22386 5 5 4.77614 5 4.5Z"
				fill={color}
			/>
			<path
				d="M5.5 7C5.22386 7 5 7.22386 5 7.5C5 7.77614 5.22386 8 5.5 8H7.5C7.77614 8 8 7.77614 8 7.5C8 7.22386 7.77614 7 7.5 7H5.5Z"
				fill={color}
			/>
			<path
				d="M3 2C3 1.44772 3.44771 1 4 1H12C12.5523 1 13 1.44771 13 2V14.5C13 14.6801 12.9031 14.8463 12.7463 14.9351C12.5896 15.0239 12.3972 15.0214 12.2428 14.9287L10.031 13.6017L8.3 14.9C8.12222 15.0333 7.87778 15.0333 7.7 14.9L5.96896 13.6017L3.75725 14.9287C3.60278 15.0214 3.4104 15.0239 3.25365 14.9351C3.0969 14.8463 3 14.6801 3 14.5V2ZM12 2L4 2V13.6169L5.74275 12.5713C5.91707 12.4667 6.13737 12.478 6.3 12.6L8 13.875L9.7 12.6C9.86263 12.478 10.0829 12.4667 10.2572 12.5713L12 13.6169V2Z"
				fill={color}
			/>
		</svg>
	);
});
