import * as React from 'react';

export const FirstCabin = React.memo(
	({ size = 16, color = '#7855DF', className }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				viewBox="0 0 16 16"
				className={className}
				fill="none"
			>
				<path
					d="M1.09438 2.20764C1.18836 2.07726 1.33928 2 1.5 2H4.13955C4.56998 2 4.95211 2.27543 5.08823 2.68377L5.47427 3.84189C5.52509 3.99436 5.49952 4.16197 5.40555 4.29236C5.31157 4.42274 5.16065 4.5 4.99992 4.5H4.69372L5.52705 7H8.88197C9.45013 7 9.96952 7.321 10.2236 7.82918L10.809 9H12.5C12.9722 9 13.4168 9.22229 13.7 9.6L14.9 11.2C15.0137 11.3515 15.0319 11.5542 14.9473 11.7236C14.8626 11.893 14.6894 12 14.5 12H12V13.5C12 13.7761 11.7761 14 11.5 14H5.5C5.22386 14 5 13.7761 5 13.5V11.9837C4.4474 11.9017 3.97828 11.516 3.79774 10.9743L1.02566 2.65811C0.974837 2.50564 1.00041 2.33803 1.09438 2.20764Z"
					fill={color}
				/>
			</svg>
		);
	}
);

FirstCabin.displayName = 'FirstCabin';
