import * as React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './card.module.scss';

const CardComp = (props) => {
	const { className = '', hoverable = false, children, ...rest } = props;
	return (
		<div
			className={clsx(classes.card, className)}
			hoverable={hoverable}
			{...rest}
		>
			{children}
		</div>
	);
};

CardComp.propTypes = {
	className: PropTypes.string,
	hoverable: PropTypes.bool,
};

export const Card = React.memo(CardComp);
