import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	consolidatedData: null,
	confirmationSteps: {
		currentStep: 0,
		completedSteps: [],
		mandatorySteps: true,
		steps: null,
	},
	legIDs: {},
	flightLegIDs: {},
	confirmation_id: null,
	trip_id: null,
	membershipDetails: null, // transformed membership mapping which is sent in proceed/payment/summary api payload
	savedMembershipDetails: {}, // state which saves currently selected and saved memberships
	checkin_details: null,
	checkin_switch: true,
	viewMode: null,
	paymentRetryMode: false,
	initPassengerDetails: null,
	editStep: null, // currently editing step (to disable other section)
	highCostReason: null,
	confirmBtnLoading: false, //to match loading state in both the CTAs - on confirmation header and approval section
	creditsApplied: {},
	customSeatSelected: {},
	mealsSelected: {},
	adminViewMode: null,
	rescheduleModificationID: null,
	membership_applicable: false,
	noErrors: false,
	bookedData: null,
};
export const confirmationSlice = createSlice({
	name: 'confirmationSlice',
	initialState,
	reducers: {
		setConsolidatedSingleData: (state, action) => {
			state.consolidatedData[action.payload.step] = action.payload.data;
		},
		setConsolidatedData: (state, action) => {
			state.consolidatedData = action.payload;
		},
		setInitPassengerDetails: (state, action) => {
			state.initPassengerDetails = action.payload;
		},
		setTripIDRedux: (state, action) => {
			state.trip_id = action.payload;
		},
		setConfirmationIDRedux: (state, action) => {
			state.confirmation_id = action.payload;
		},
		setMembershipDetails: (state, action) => {
			state.membershipDetails = action.payload;
		},
		setSavedMembershipDetails: (state, action) => {
			state.savedMembershipDetails = action.payload;
		},
		setCheckinDetails: (state, action) => {
			state.checkin_details = action.payload;
		},
		setCheckinSwitch: (state, action) => {
			state.checkin_switch = action.payload;
		},
		setConfirmationStepFlow: (state, action) => {
			state.confirmationSteps.steps = action.payload;
		},
		setConfirmationLegIDs: (state, action) => {
			let tempLegIDs = {},
				tempFlightIDs = {};
			action.payload.forEach((item) => {
				if (item.mode === 'flight')
					tempFlightIDs[item.leg_request_id] = true;
				tempLegIDs[item.leg_request_id] = true;
			});
			state.flightLegIDs = tempFlightIDs;
			state.legIDs = tempLegIDs;
		},
		setConfirmationStep: (state, action) => {
			if (action.payload.completed)
				state.confirmationSteps.completedSteps.push(
					state.confirmationSteps.steps[
						state.confirmationSteps.currentStep
					]?.step
				);
			state.confirmationSteps.currentStep++;
			if (action.payload.mandatorySteps === false) {
				state.confirmationSteps.mandatorySteps = false;
			}
			state.confirmationSteps.steps.map((item, index) => {
				if (index === state.confirmationSteps.currentStep) {
					item.mode = action.payload.mode;
					if (item.step === 'checkin' || item.step === 'gstInfo') {
						if (
							state.confirmationSteps.completedSteps.includes(
								'travelerDetails'
							)
						) {
							item.mode = 'edit';
						}
					}
					if (item.step === 'membership') {
						if (action.payload.membershipApplicable) {
							item.mode = 'edit';
						}
					}

					return item;
				}
				if (!state.confirmationSteps.mandatorySteps) {
					item.mode = 'preview';
				}
				return item;
			});
			if (
				!state.confirmationSteps.mandatorySteps &&
				state.confirmationSteps.steps[
					state.confirmationSteps.steps.length - 1
				].step !== 'approval'
			) {
				confirmationSlice.caseReducers.setConfirmationStepFlow(state, {
					payload: [
						...state.confirmationSteps.steps,
						{
							step: 'approval',
							title: 'Approval',
							mode: 'preview',
						},
					],
				});
			}
		},
		setConfirmationData: (state, action) => {
			state.confirmationSteps.steps.map((item) => {
				if (item.step === action.payload.step) {
					item.data = action.payload.data;
					return item;
				}
				return item;
			});
		},
		setEditViewMode: (state, action) => {
			if (
				action.payload.type === 'edit' &&
				action.payload.step !== 'itinerary'
			)
				state.editStep = action.payload.step;
			else state.editStep = null;
			state.confirmationSteps.steps.map((item) => {
				if (item.step === action.payload.step) {
					item.mode = action.payload.type;
					return item;
				}
				return item;
			});
		},
		setPageViewMode: (state, action) => {
			state.viewMode = action.payload;
		},
		setPaymentRetryMode: (state, action) => {
			state.paymentRetryMode = action.payload;
		},
		resetConfirmationSteps: (state) => {
			state.consolidatedData = null;
			state.confirmationSteps = {
				currentStep: 0,
				completedSteps: [],
				mandatorySteps: true,
				steps: null,
			};
			state.bookedData = null;
		},
		setHighCostReason: (state, action) => {
			state.highCostReason = action.payload;
		},
		setErrorsInMembership: (state, action) => {
			state.noErrors = action.payload;
		},
		setConfirmBtnLoading: (state, action) => {
			state.confirmBtnLoading = action.payload;
		},
		setCreditsApplied: (state, action) => {
			state.creditsApplied = action.payload;
		},
		setMealsSelected: (state, action) => {
			state.mealsSelected = action.payload;
		},
		setCustomSeatSelected: (state, action) => {
			state.customSeatSelected = action.payload;
		},
		setMembershipApplicable: (state, action) => {
			state.membership_applicable = action.payload;
		},
		setAdminViewMode: (state, action) => {
			const selection_status = action.payload.selection_status;
			const payment_status = action.payload.payment_status;
			let adminViewMode = null;
			if (selection_status === 0) {
				adminViewMode = {
					hideChangeOption: false,
				};
				if (payment_status === 3 || payment_status === 4) {
					adminViewMode = {
						hideChangeOption: true,
					};
				}
			}
			if (
				selection_status === 2 ||
				selection_status === 3 ||
				selection_status === 4 ||
				selection_status === 5
			) {
				adminViewMode = {
					hideChangeOption: true,
				};
			}
			if (selection_status === 6 || selection_status === 7) {
				adminViewMode = {
					hideChangeOption: false,
				};
			}
			state.adminViewMode = adminViewMode;
		},
		setConfirmationRescheduleModificationID: (state, action) => {
			state.rescheduleModificationID = action.payload;
		},
		setBookedData: (state, action) => {
			state.bookedData = action.payload;
		},
	},
});

export const {
	setConsolidatedSingleData,
	setConsolidatedData,
	setConfirmationLegIDs,
	setTripIDRedux,
	setConfirmationIDRedux,
	setEditViewMode,
	setConfirmationData,
	setConfirmationStep,
	setMembershipDetails,
	setConfirmationStepFlow,
	setCheckinDetails,
	setCheckinSwitch,
	setPageViewMode,
	setPaymentRetryMode,
	resetConfirmationSteps,
	setSelectedMembership,
	setInitPassengerDetails,
	setSavedMembershipDetails,
	setHighCostReason,
	setConfirmBtnLoading,
	setCreditsApplied,
	setMealsSelected,
	setAdminViewMode,
	setConfirmationRescheduleModificationID,
	setMembershipApplicable,
	setErrorsInMembership,
	setCustomSeatSelected,
	setBookedData,
} = confirmationSlice.actions;

export default confirmationSlice.reducer;
