import { createContext, useState } from 'react';

// Create a context for logging data
export const LogContext = createContext('');

const AnalyticsLoggerContext = ({ children, ...directProps }) => {
	const [combinedProps, setCombinedProps] = useState(directProps);
	return (
		<LogContext.Provider value={combinedProps}>
			{children()}
		</LogContext.Provider>
	);
};

export default AnalyticsLoggerContext;
