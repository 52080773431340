import * as React from 'react';
import { Divider as AntDivider } from 'antd';
import PropTypes from 'prop-types';

const DividerComp = (props) => {
	const {
		className = '',
		dashed = false,
		orientation = '',
		type = 'horizontal',
		children,

		...rest
	} = props;
	return (
		<AntDivider
			className={className}
			dashed={dashed}
			type={type}
			orientation={orientation}
			{...rest}
		>
			{children}
		</AntDivider>
	);
};

DividerComp.propTypes = {
	className: PropTypes.string,
	orientation: PropTypes.string,
	type: PropTypes.string,
	dashed: PropTypes.bool,
};

export const Divider = React.memo(DividerComp);
