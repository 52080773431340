import { useState } from 'react';
import * as React from 'react';
import { Popover as AntPopover } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './Popover.module.scss';

const PopoverComp = (props) => {
	const [popOpen, setPopOpen] = useState(false);
	const {
		content = '',
		open = false,
		className = '',
		children,
		onOpenChange = () => {},
		placement,
		...rest
	} = props;
	return (
		<AntPopover
			className={clsx(classes.popoverMain, className)}
			content={content}
			title=""
			trigger="click"
			open={popOpen}
			onOpenChange={() => setPopOpen(!popOpen)}
			placement={placement}
			{...rest}
		>
			{children}
		</AntPopover>
	);
};

PopoverComp.propTypes = {
	content: PropTypes.any,
	className: PropTypes.string,
	onOpenChange: PropTypes.func,
	open: PropTypes.bool,
	placement: PropTypes.oneOf([
		'bottom',
		'bottomLeft',
		'bottomRight',
		'right',
		'rightTop',
		'rightBottom',
		'top',
		'topLeft',
		'topRight',
		'left',
		'leftTop',
		'leftBottom',
	]),
};

export const Popover = React.memo(PopoverComp);
