import * as React from 'react';

export const Warning = React.memo(({ size = 16, ...rest }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		fill="none"
		viewBox="0 0 16 16"
		{...rest}
	>
		<path
			fill="#EAC54F"
			d="M9.092 2.638a1.25 1.25 0 00-2.182 0L2.157 11.14A1.25 1.25 0 003.247 13h9.504a1.25 1.25 0 001.091-1.86l-4.75-8.502z"
		></path>
		<path
			fill="#111827"
			d="M8.75 10.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 8V5.5a.5.5 0 011 0V8a.5.5 0 01-1 0z"
		></path>
	</svg>
));

Warning.displayName = 'Warning';
