import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	carId: null,
	tripId: null,
	serviceMasterId: null,
	tripDetail: null,
	selectedCarObj: null,
	confirmCarClicked: false,
	filterCount: null,
	carTripRequest: null,
};
export const carResults = createSlice({
	name: 'carResultsSlice',
	initialState,
	reducers: {
		setCarId: (state, action) => {
			state.carId = action.payload;
		},
		setTripId: (state, action) => {
			state.tripId = action.payload;
		},
		setServiceMasterId: (state, action) => {
			state.serviceMasterId = action.payload;
		},
		setTripDetail: (state, action) => {
			state.tripDetail = action.payload;
		},
		setSelectedCarObj: (state, action) => {
			state.selectedCarObj = action.payload;
		},
		setConfirmCarClicked: (state, action) => {
			state.confirmCarClicked = action.payload;
		},
		setFilterCount: (state, action) => {
			state.filterCount = action.payload;
		},
		setCarTripRequest: (state, action) => {
			state.carTripRequest = action.payload;
		},
	},
});

export const {
	setCarId,
	setTripId,
	setServiceMasterId,
	setTripDetail,
	setSelectedCarObj,
	setConfirmCarClicked,
	setFilterCount,
	setCarTripRequest,
} = carResults.actions;

export default carResults.reducer;
