import dayjs from 'dayjs';
import { logErrors } from 'utils';
import { apiUrls } from 'utils/urls/apiUrls';

import LocalStorageService from '../services/LocalStorage';

async function validateAccessToken() {
	if (localStorage.getItem('token')) {
		let tokenObj = JSON.parse(localStorage.getItem('token'));
		let accessToken = tokenObj.access;
		let exp = tokenObj.acc_exp_in;
		let currTimeUnix = dayjs().unix(); // secs
		if (currTimeUnix >= exp) {
			let refreshToken = tokenObj.refresh;
			let resfreshTokenExp = tokenObj.ref_exp_in;

			const data = {
				refresh: refreshToken,
			};
			if (currTimeUnix <= resfreshTokenExp) {
				await fetch(apiUrls.AUTH.FETCH_REFRESH_TOKEN, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(data),
				})
					.then((response) => response.json())
					.then((res) => {
						const _token = res.token;
						accessToken = _token.access;
						LocalStorageService.setAccessToken(_token.access);
						LocalStorageService.setRefreshToken(_token.refresh);
						localStorage.setItem('token', JSON.stringify(_token));
					})
					.catch((error) => {
						logErrors(
							{
								type: 'Token validation failed',
								url: window.location.href,
								...tokenObj,
								...error,
							},
							true
						);
						LocalStorageService.removeAllToken();
						window.location.href = process.env.REACT_APP_LOGIN_URL;
					});
			} else {
				logErrors(
					{
						type: 'Referesh token expired',
						url: window.location.href,
						...tokenObj,
					},
					true
				);
				LocalStorageService.removeAllToken();
				window.location.href = process.env.REACT_APP_LOGIN_URL;
				return false;
			}
		}
		return accessToken;
	}
	LocalStorageService.removeAllToken();
	window.location.href = process.env.REACT_APP_LOGIN_URL;
}

export { validateAccessToken };
