import { useRive } from '@rive-app/react-canvas';

export const Rive = (props) => {
	const {
		style,
		src,
		animations,
		artboard = null,
		stateMachines,
		onStateChange = () => {}
	} = props;
	const { RiveComponent } = useRive({
		src: src,
		artboard: artboard,
		autoplay: true,
		animations: animations,
		stateMachines:stateMachines,
		onStateChange: (state) => {
			onStateChange(state); // Optionally, you can pass the state to the original callback
		}
	});

	return (
		<div style={style}>
			<RiveComponent />
		</div>
	);
};