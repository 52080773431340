import * as React from 'react';

export const ArrowRound = React.memo(
	({ size = 16, color = '#868D9A', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox={`0 0 ${size} ${size}`}
			>
				<path
					fill={color}
					d="M11.854 4.127a.5.5 0 00-.708.708l1.147 1.146H5.5a.5.5 0 100 1h6.793l-1.147 1.146a.5.5 0 00.708.708l2-2a.5.5 0 000-.708l-2-2zM4.146 9.127a.5.5 0 11.708.708L3.707 10.98H10.5a.5.5 0 110 1H3.707l1.147 1.146a.5.5 0 11-.708.707l-2-2a.5.5 0 010-.707l2-2z"
				></path>
			</svg>
		);
	}
);

ArrowRound.displayName = 'ArrowRound';
