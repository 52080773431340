import * as React from 'react';
export const ToastIcon = React.memo(
	({ size = 12, color = '#6b7280', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="17"
				height="17"
				viewBox="0 0 17 17"
				fill="none"
			>
				<g clip-path="url(#clip0_8960_208040)">
					<path
						d="M8.875 13.2051C9.42729 13.2051 9.875 12.7574 9.875 12.2051C9.875 11.6528 9.42729 11.2051 8.875 11.2051C8.32272 11.2051 7.875 11.6528 7.875 12.2051C7.875 12.7574 8.32272 13.2051 8.875 13.2051Z"
						fill="white"
					/>
					<path
						d="M9.625 3.95508C9.625 3.54086 9.28921 3.20508 8.875 3.20508C8.46079 3.20508 8.125 3.54086 8.125 3.95508V9.45508C8.125 9.86929 8.46079 10.2051 8.875 10.2051C9.28921 10.2051 9.625 9.86929 9.625 9.45508V3.95508Z"
						fill="white"
					/>
					<path
						d="M8.875 0.205078C4.45672 0.205078 0.875 3.7868 0.875 8.20508C0.875 12.6234 4.45672 16.2051 8.875 16.2051C13.2933 16.2051 16.875 12.6234 16.875 8.20508C16.875 3.7868 13.2933 0.205078 8.875 0.205078ZM2.375 8.20508C2.375 4.61523 5.28515 1.70508 8.875 1.70508C12.4649 1.70508 15.375 4.61523 15.375 8.20508C15.375 11.7949 12.4649 14.7051 8.875 14.7051C5.28515 14.7051 2.375 11.7949 2.375 8.20508Z"
						fill="white"
					/>
				</g>
				<defs>
					<clipPath id="clip0_8960_208040">
						<rect
							width="16"
							height="16"
							fill="white"
							transform="translate(0.875 0.205078)"
						/>
					</clipPath>
				</defs>
			</svg>
		);
	}
);

ToastIcon.displayName = 'ToastIcon';
