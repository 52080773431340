import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

const TextCyclingComp = (props) => {
	const { textArray = [], className = '' } = props;
	const [index, setIndex] = useState(0);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setIndex((prevIndex) => (prevIndex + 1) % textArray.length);
		}, 1500);

		return () => clearInterval(intervalId);
	}, [textArray.length]);

	return (
		<div className={clsx('tw-ml-1.5', className)}>
			<AnimatePresence mode="wait">
				<motion.div
					key={index}
					initial={{ y: 0, opacity: 0 }}
					animate={{ y: '0%', opacity: 1 }}
					exit={{ y: -20, opacity: 0 }}
					transition={{ duration: 0.5 }}
					className="tw-animated-text tw-transition-transform tw-duration-500 tw-typography-title3"
				>
					{textArray[index]}
				</motion.div>
			</AnimatePresence>
		</div>
	);
};

TextCyclingComp.propTypes = {
	className: PropTypes.string,
	textArray: PropTypes.array,
};

export const TextCycling = React.memo(TextCyclingComp);
