import * as React from 'react';

export const Night = React.memo(
	({ size = 10, color = '#6B7280', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size + 1}
				height={size}
				fill="none"
				viewBox="0 0 11 10"
				className={className}
				{...rest}
			>
				<path
					fill={color}
					d="M3.656.873a.315.315 0 01.426.426 3.78 3.78 0 005.12 5.119.315.315 0 01.426.427A4.409 4.409 0 113.656.873zm4.82 6.495l-.03.031.03-.031z"
				></path>
			</svg>
		);
	}
);

Night.displayName = 'Night';
