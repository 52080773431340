import * as React from 'react';
import { Timeline } from 'antd';
import PropTypes from 'prop-types';

export const TimelineItem = React.memo(
	({ color, dot, label, position, className, ...item }) => {
		return (
			<Timeline.Item
				color={color}
				dot={dot}
				label={label}
				position={position}
				className={className}
				{...item}
			/>
		);
	}
);

TimelineItem.PropTypes = {
	color: PropTypes.string,
	dot: PropTypes.node,
	label: PropTypes.node,
	position: PropTypes.oneOf(['left', 'right']),
};
