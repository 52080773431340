import * as React from 'react';

export const Sort = React.memo(
	({ size = 16, color = '#6B7280', className }) => (
		<svg
			width={size + 1}
			height={size}
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.35355 2.14645C5.15829 1.95118 4.84171 1.95118 4.64645 2.14645L1.64645 5.14645C1.45118 5.34171 1.45118 5.65829 1.64645 5.85355C1.84171 6.04882 2.15829 6.04882 2.35355 5.85355L4.5 3.70711V13.5C4.5 13.7761 4.72386 14 5 14C5.27614 14 5.5 13.7761 5.5 13.5V3.70711L7.64645 5.85355C7.84171 6.04882 8.15829 6.04882 8.35355 5.85355C8.54882 5.65829 8.54882 5.34171 8.35355 5.14645L5.35355 2.14645ZM11.6525 13.8595C11.8463 14.0468 12.1537 14.0468 12.3475 13.8595L15.3475 10.9594C15.5461 10.7675 15.5514 10.4509 15.3595 10.2524C15.1676 10.0538 14.851 10.0485 14.6525 10.2404L12.5 12.3212L12.5 2.5001C12.5 2.22395 12.2761 2.0001 12 2.0001C11.7239 2.0001 11.5 2.22395 11.5 2.5001L11.5 12.3212L9.34752 10.2404C9.14898 10.0485 8.83244 10.0538 8.64051 10.2524C8.44858 10.4509 8.45394 10.7675 8.65248 10.9594L11.6525 13.8595Z"
				fill={color}
			/>
		</svg>
	)
);

Sort.displayName = 'Sort';
