import * as React from 'react';

export const Dots = React.memo(
	({ size = 5, width, height, color = '#868D9A', className, ...rest }) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || size}
			height={height || size}
			viewBox="0 0 5 5"
			fill="none"
			className={className}
			{...rest}
		>
			<circle cx="2.5" cy="2.5" r="2.5" fill={color} />
		</svg>
	)
);

Dots.displayName = 'Dots';
