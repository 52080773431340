import * as React from 'react';

export const LoyaltyPoints = React.memo(
	({ size = 16, color = '#EC5D25', className }) => (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M8.46767 4.83848C8.32104 4.38717 7.68256 4.38718 7.53593 4.83848L6.96107 6.60772H5.10077C4.62624 6.60772 4.42895 7.21494 4.81285 7.49386L6.31786 8.58732L5.74299 10.3566C5.59636 10.8079 6.11289 11.1831 6.49679 10.9042L8.0018 9.81077L9.50681 10.9042C9.89071 11.1831 10.4072 10.8079 10.2606 10.3566L9.68574 8.58732L11.1908 7.49386C11.5747 7.21494 11.3774 6.60772 10.9028 6.60772H9.04253L8.46767 4.83848Z"
				fill={color}
			/>
			<path
				d="M2.5 2C1.11929 2 0 3.11929 0 4.5V11.5C0 12.8807 1.11929 14 2.5 14H13.5C14.8807 14 16 12.8807 16 11.5V4.5C16 3.11929 14.8807 2 13.5 2H2.5ZM1 4.5C1 3.67157 1.67157 3 2.5 3H13.5C14.3284 3 15 3.67157 15 4.5V11.5C15 12.3284 14.3284 13 13.5 13H2.5C1.67157 13 1 12.3284 1 11.5V4.5Z"
				fill={color}
			/>
		</svg>
	)
);

LoyaltyPoints.displayName = 'LoyaltyPoints';
