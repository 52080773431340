import React, { Suspense } from 'react';
import { appModules } from 'auth/appModuleConfig';

import { CreateTripLoader } from './modules/createTripLoader';
import { DefaultPageLoader } from './modules/defaultLoader';

const AppLoaderComp = (props) => {
	const { module = 'default' } = props;
	const loaderMapper = {
		[appModules.createTrip]: <CreateTripLoader {...props} />,
		default: <DefaultPageLoader {...props} />,
	};
	return (
		<div className="tw-h-screen tw-w-full tw-flex tw-items-center tw-justify-center">
			<Suspense fallback={<></>}>
				{loaderMapper[module]}
			</Suspense>
		</div>
	);
};

export const AppLoader = React.memo(AppLoaderComp);
