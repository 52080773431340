import { useCallback } from 'react';
import { toast,ToastContainer } from 'react-toastify';
import { ToastIcon } from 'icons/ToastIcon';

import styles from './Toast.module.scss';

export function Toast() {
	return (
		<ToastContainer
			position={toast.POSITION.TOP_RIGHT}
			autoClose={3000}
			closeOnClick
			className={styles.ToastContainer}
		/>
	);
}

export function useToast() {
	const showToastSuccess = useCallback((message, options) => {
		toast.success(message, options);
	}, []);

	const showToastError = useCallback((message, options) => {
		toast.error(message, {
			icon: <ToastIcon />,
			...options,
		});
	}, []);

	return { showToastSuccess, showToastError };
}
