import * as React from 'react';

export const FilledCheck = React.memo(({ size = 16, color = 'white' }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2ZM10.1203 6.16398L7.24953 9.04242L5.85355 7.64645C5.65829 7.45118 5.34171 7.45118 5.14645 7.64645C4.95118 7.84171 4.95118 8.15829 5.14645 8.35355L6.89645 10.1036C7.09189 10.299 7.40884 10.2988 7.60403 10.1031L10.8283 6.87014C11.0233 6.67462 11.0229 6.35804 10.8274 6.16304C10.6319 5.96803 10.3153 5.96846 10.1203 6.16398Z"
			fill={color}
		/>
	</svg>
));

FilledCheck.displayName = 'FilledCheck';
