import React from 'react';

const GradientComp = ({
	colors = ['tw-from-black', 'tw-to-white'],
	spread = 'tw-via-black-700',
	direction = 'tw-bg-gradient-to-r',
	className = '',
	length = 'full',
	diagonal = false, // New prop to toggle diagonal gradient
	children,
}) => {
	const gradientClass = `${direction} ${colors.join(
		' '
	)} ${spread} ${className}`;

	const customStyles = diagonal
		? {
			background: `linear-gradient(45deg, ${colors.join(', ')})`, // 45deg diagonal gradient
			backgroundSize:
					length !== 'full' ? `${length} 100%` : '100% 100%',
			backgroundRepeat: 'no-repeat',
		  }
		: {
			backgroundSize:
					length !== 'full' ? `${length} 100%` : '100% 100%',
			backgroundRepeat: 'no-repeat',
		  };

	return (
		<div className={`${gradientClass}`} style={customStyles}>
			{children}
		</div>
	);
};

GradientComp.propTypes = {};

export const Gradient = React.memo(GradientComp);
