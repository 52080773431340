import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { SystemError, SystemErrorBlock } from 'icons';
import { errorTypeConstants } from 'utils/constants/errortypes';

import { Button } from 'components/atoms';

const SystemErrors = (props) => {
	const { errorType = 500, goBackURL = null } = props;
	const navigate = useNavigate();
	const { t } = useTranslation(['generic']);
	const goBack = () => {
		if (goBackURL) window.location.href = goBackURL;
		else navigate(-1);
	};

	let errorDetails;
	switch (errorType) {
		case errorTypeConstants[400]:
			errorDetails = {
				image: <SystemError />,
				label: t('error.400.label'),
				error: `${t('error.error_text')} 400`,
				description: t('error.400.description'),
				buttonSection: (
					<Button
						onClick={() => navigate(0)}
						type="primary"
						className="tw-mt-2"
					>
						{t('error.400.button.primary')}
					</Button>
				),
			};
			break;

		case errorTypeConstants[403]:
			errorDetails = {
				image: <SystemErrorBlock />,
				label: t('error.403.label'),
				error: `${t('error.error_text')} 403`,
				description: t('error.403.description'),
				buttonSection: (
					<Button
						onClick={() =>
							(window.location.href =
								process.env.REACT_APP_ITILITE_BASE_URL +
								'/staff-dashboard')
						}
						type="primary"
						className="tw-mt-2"
					>
						{t('error.403.button.primary')}
					</Button>
				),
			};
			break;
		case errorTypeConstants[502]:
			errorDetails = {
				image: <SystemError color="#CF2231" />,
				label: t('error.502.label'),
				error: `${t('error.error_text')} 502`,
				description: (
					<>
						{t('error.502.description')}{' '}
						<a
							href="https://www.itilite.com/contact-us"
							className="tw-text-color-text-primary tw-no-underline tw-cursor-pointer tw-border-0 tw-border-b tw-border-color-primary tw-mb-2 tw-border-solid"
						>
							{t('error.502.description_link')}
						</a>
						.
					</>
				),
				buttonSection: (
					<div className="tw-flex">
						<Button
							onClick={() => navigate(0)}
							type="primary"
							className="tw-mt-2"
						>
							{t('error.502.button.primary')}
						</Button>
						<Button
							onClick={() =>
								(window.location.href =
									'https://www.itilite.com/contact-us')
							}
							type="secondary"
							className="tw-mt-2 tw-ml-2"
						>
							{t('error.502.button.secondary')}
						</Button>
					</div>
				),
			};
			break;
		case errorTypeConstants[404]:
			errorDetails = {
				image: <SystemError />,
				label: t('error.404.label'),
				error: `${t('error.error_text')} 404`,
				description: t('error.404.description'),
				buttonSection: (
					<div className="tw-flex tw-justify-between">
						<ul className="tw-flex tw-mt-0 tw-pl-0 marker:text-green">
							<li className="tw-list-none">
								<Button
									type="link"
									href={
										process.env.REACT_APP_ITILITE_BASE_URL +
										'/staff-dashboard'
									}
									className="tw-mt-2 tw-text-center tw-underline"
								>
									{t('error.404.button.link1')}
								</Button>
							</li>
							<li className="tw-text-color-text-primary tw-ml-6">
								<Button
									type="link"
									href="https://www.itilite.com/contact-us"
									className="tw-mt-2 tw-text-center tw-underline"
								>
									{t('error.404.button.link2')}
								</Button>
							</li>
							<li className="tw-text-color-text-primary tw-ml-6">
								<Button
									type="link"
									href="https://www.itilite.com/faq"
									className="tw-mt-2 tw-text-center tw-underline"
								>
									{t('error.404.button.link3')}
								</Button>
							</li>
						</ul>
					</div>
				),
			};
			break;
		case errorTypeConstants['MAINTAINANCE']:
			errorDetails = {
				image: <SystemError />,
				label: t('error.600.label'),
				error: `${t('error.error_text_600')}`,
				description: t('error.600.description'),
				buttonSection: null,
			};
			break;
		case 'no_data':
			errorDetails = {
				image: <SystemError />,
				label: t('error.no_data.label'),
				description: t('error.no_data.description'),
				buttonSection: (
					<div className="tw-flex">
						<Button
							onClick={() => goBack()}
							type="primary"
							className="tw-mt-2"
						>
							{t('error.500.button.primary')}
						</Button>
						<Button
							onClick={() => {
								window.location.href =
									process.env.REACT_APP_ITILITE_BASE_URL +
									'/staff-dashboard';
							}}
							type="secondary"
							className="tw-mt-2 tw-ml-2"
						>
							{t('error.500.button.secondary')}
						</Button>
					</div>
				),
			};
			break;
		case 500:
		default:
			errorDetails = {
				image: <SystemError color="#CF2231" />,
				label: t('error.500.label'),
				error: `${t('error.error_text')} 500`,
				description: <>{t('error.500.description')}</>,
				buttonSection: (
					<div className="tw-flex">
						<Button
							onClick={() => goBack()}
							type="primary"
							className="tw-mt-2"
						>
							{t('error.500.button.primary')}
						</Button>
						<Button
							onClick={() => {
								window.location.href =
									process.env.REACT_APP_ITILITE_BASE_URL +
									'/staff-dashboard';
							}}
							type="secondary"
							className="tw-mt-2 tw-ml-2"
						>
							{t('error.500.button.secondary')}
						</Button>
					</div>
				),
			};
			break;
	}
	return (
		<div
			className={clsx(
				'tw-flex tw-items-center tw-justify-center tw-flex-col tw-h-[90vh]'
			)}
		>
			{errorDetails.image}
			<p className="tw-text-color-text-subtle tw-mb-0 tw-typography-title2">
				{errorDetails.label}
			</p>
			<p
				className={clsx(
					'tw-typography-body2 tw-text-color-text-weak error_page',
					errorDetails?.error
				)}
			>
				{errorDetails.error}
			</p>
			<p className="tw-typography-body2 tw-text-color-text-subtle tw-text-center tw-mt-0 tw-whitespace-pre-line">
				{errorDetails.description}
			</p>
			{errorDetails.buttonSection}
		</div>
	);
};

export { SystemErrors };
