import * as React from 'react';
import { Steps as AntSteps } from 'antd';
import clsx from 'clsx';
import { Check } from 'icons';
import PropTypes from 'prop-types';

import classes from './Steps.module.scss';

const StepsComp = ({
	stepItems = [],
	icon = (current, ind) => {
		if (stepItems)
			return (
				<div
					className={clsx(
						classes.stepIcon,
						current >= ind && classes.doneIcon
					)}
				>
					{current >= ind && <Check color="white" />}
				</div>
			);
	},
	className = '',
	current = 1,
	size = 'small',
	direction = 'vertical',
	...rest
}) => {
	return (
		<AntSteps
			className={clsx(classes.steps, className)}
			direction={direction}
			size={size}
			current={current}
			items={stepItems.map((item, ind) => ({
				title: <div className="tw-mb-10">{item}</div>,
				icon: icon(current, ind),
			}))}
			{...rest}
		/>
	);
};

StepsComp.propTypes = {
	className: PropTypes.string,
	stepItems: PropTypes.array,
	icon: PropTypes.func,
	current: PropTypes.number,
	size: PropTypes.string,
	direction: PropTypes.string,
};

export const Steps = React.memo(StepsComp);
