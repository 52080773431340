import * as React from 'react';
import { Avatar as AntAvatar } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Image } from '../Image';

const AvatarComp = ({
	name,
	size = 32,
	image = '',
	className = '',
	preview,
	...rest
}) => {
	return (
		<AntAvatar
			className={clsx(
				'tw-bg-[#fff7ed] tw-text-color-text-primary',
				className
			)}
			size={size}
			src={
				image && (
					<Image
						src={image}
						style={{ height: size }}
						className="tw-object-cover"
						preview={preview}
					/>
				)
			}
			role="avatar"
			{...rest}
		>
			{!image &&
				name
					.replace(/\s+/g, ' ')
					.split(' ')
					.map((item) => item?.[0]?.toUpperCase())}
		</AntAvatar>
	);
};

AvatarComp.propTypes = {
	name: PropTypes.string,
	size: PropTypes.number,
	image: PropTypes.string,
	className: PropTypes.string,
};

export const Avatar = React.memo(AvatarComp);
