import * as React from 'react';

export const Cancellation = React.memo(
	// defalut color is changed from green to black
	({ size = 16, color = '#363E4F', className, ...rest }) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill="none"
			viewBox="0 0 16 16"
			className={className}
			{...rest}
		>
			<path
				fill={color}
				d="M12.293 13l1.853 1.854a.5.5 0 00.708-.708l-13-13a.5.5 0 10-.708.708l1.195 1.194A1.75 1.75 0 001 4.75V6.5a.5.5 0 00.5.5 1 1 0 110 2 .5.5 0 00-.5.5v1.75c0 .966.784 1.75 1.75 1.75h9.543zm-1-1H2.75a.75.75 0 01-.75-.75V9.937a2 2 0 000-3.874V4.75A.75.75 0 012.75 4h.543l8 8zM14 11.25c0 .176-.06.338-.162.466l.709.71c.281-.311.453-.724.453-1.176V9.5a.5.5 0 00-.5-.5 1 1 0 110-2 .5.5 0 00.5-.5V4.75A1.75 1.75 0 0013.25 3H5.121l1 1h7.129a.75.75 0 01.75.75v1.313a2 2 0 000 3.874v1.313z"
			></path>
		</svg>
	)
);

Cancellation.displayName = 'Cancellation';
