import * as React from 'react';

export const Diamond = React.memo(
	({ size = 16, color = '#EC5D25', className, ...rest }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...rest}
			>
				<path
					d="M3.75 2C3.47302 2 3.21859 2.15266 3.08824 2.39706L1.08824 6.14706C0.948686 6.40872 0.976416 6.72804 1.15899 6.96173L7.40899 14.9617C7.55112 15.1437 7.76913 15.25 8 15.25C8.23088 15.25 8.44889 15.1437 8.59102 14.9617L14.841 6.96173C15.0236 6.72804 15.0513 6.40872 14.9118 6.14706L12.9118 2.39706C12.7814 2.15266 12.527 2 12.25 2H3.75ZM2.86667 6L4.2 3.5H5.47683L4.85183 6H2.86667ZM3.483 7.5H5.02664L6.05574 10.7931L3.483 7.5ZM6.59818 7.5H9.40177L7.99998 11.9857L6.59818 7.5ZM10.9733 7.5H12.517L9.94418 10.7932L10.9733 7.5ZM13.1333 6H11.1481L10.5231 3.5H11.8L13.1333 6ZM9.60196 6H6.39799L7.02299 3.5H8.97696L9.60196 6Z"
					fill={color}
				/>
			</svg>
		);
	}
);

Diamond.displayName = 'Diamond';
