/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull browser'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/itilite/Amplitude%20POC/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/itilite/Amplitude%20POC/implementation/browser)
 */

import * as amplitude from '@amplitude/analytics-browser';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [configuration]
 * @property {BrowserClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'amplitudepoc'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} amplitudepoc
 */
export const ApiKey = {
	amplitudepoc: '',
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
	plan: {
		version: '1',
		branch: 'main',
		source: 'browser',
		versionId: '67044a08-c326-4acc-b2d5-5f3d3032108d',
	},
	...{
		ingestionMetadata: {
			sourceName: 'browser-javascript-ampli',
			sourceVersion: '2.0.0',
		},
	},
};

export class CustomPageVisit {
	constructor(properties) {
		this.event_type = '[custom] page-visit';
		this.event_properties = properties;
	}
}

export class ButtonClicked {
	constructor(properties) {
		this.event_type = 'button-clicked';
		this.event_properties = properties;
	}
}

/**
 * @typedef PromiseResult
 * @type {object}
 * @property {Promise|undefined} promise
 */

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {BrowserClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
  }

  /**
   * @return {BrowserClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   *
   * @return {PromiseResult}
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user.
   *
   * @param {string|undefined} userId The user's id.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  identify(userId, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options, user_id: userId};
    }

    const ampIdentify = new amplitude.Identify();
    return this.amplitude.identify(ampIdentify, options);
  }

  /**
  * Flush the event.
  */
  flush() {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.flush();
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  track(event, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.track(event, undefined, options);
  }

  /**
   * [custom] page-visit
   *
   * [View in Tracking Plan](https://data.amplitude.com/itilite/Amplitude%20POC/events/main/latest/%5Bcustom%5D%20page-visit)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.url Property has no description in tracking plan.
   * @param {string} properties.userInfo Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  customPageVisit(properties, options) {
    return this.track(new CustomPageVisit(properties), options);
  }

  /**
   * button-clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/itilite/Amplitude%20POC/events/main/latest/button-clicked)
   *
   * Owner: Shubam Bhasin
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.buttonColor Property has no description in tracking plan.
   * @param {string} properties.buttonText Property has no description in tracking plan.
   * @param {string} properties.eventType Property has no description in tracking plan.
   * @param {string} properties.locizeKey Property has no description in tracking plan.
   * @param {string} properties.pageURL Property has no description in tracking plan.
   * @param {string} properties.userInfo Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  buttonClicked(properties, options) {
    return this.track(new ButtonClicked(properties), options);
  }
}

export const ampli = new Ampli();
