import * as React from 'react';

export const Failure = React.memo(
	({ size = 16, color = '#6b7280', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<rect width="16" height="16" y="0" fill={color} rx="8"></rect>
				<path
					stroke="#fff"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.5"
					d="M5 5l6 6m-6 0l6-6"
				></path>
			</svg>
		);
	}
);

Failure.displayName = 'Failure';
