import * as React from 'react';

export const PriceDecrease = React.memo(
	({ size = 16, color = '#6b7280', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					fill={color}
					d="M10.669 12.72a.75.75 0 010-1.5h1.69l-3.91-3.91-1.47 1.47a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.97 3.97 1.47-1.47a.75.75 0 011.06 0l4.44 4.439v-1.69a.75.75 0 111.5 0v3.5a.75.75 0 01-.75.75h-3.5z"
				></path>
			</svg>
		);
	}
);

PriceDecrease.displayName = 'Price Decrease';
