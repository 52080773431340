import * as React from 'react';
import { Drawer as AntDrawer } from 'antd';
import clsx from 'clsx';
import { Close } from 'icons/Close';
import PropTypes from 'prop-types';

import classes from './Drawer.module.scss';

const DrawerComp = (props) => {
	const {
		className = '',
		placement = 'bottom',
		open = true,
		onClose = () => {},
		title = '',
		children,
		height,
		...rest
	} = props;
	return (
		<AntDrawer
			className={clsx(classes.drawer, className)}
			title={title}
			placement={placement}
			onClose={onClose}
			open={open}
			extra={<Close onClick={onClose} />}
			closeIcon={null}
			height={height}
			{...rest}
		>
			{children}
		</AntDrawer>
	);
};

DrawerComp.propTypes = {
	className: PropTypes.string,
	placement: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	content: PropTypes.node,
	title: PropTypes.string,
};

export const Drawer = React.memo(DrawerComp);
