import * as React from 'react';

export const Print = React.memo(
	({ size = 16, color = '#fff', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					d="M4.0001 3.5C4.0001 2.67157 4.67167 2 5.5001 2H10.5001C11.3285 2 12.0001 2.67157 12.0001 3.5V3.99996H13C14.1046 3.99996 15 4.89539 15 5.99996V10.5C15 11.3284 14.3284 12 13.5 12H12.1V12.5C12.1 13.3284 11.4284 14 10.6 14H5.5001C4.67167 14 4.0001 13.3284 4.0001 12.5V12H2.5C1.67157 12 1 11.3284 1 10.5V5.99996C1 4.89539 1.89543 3.99996 3 3.99996H4.0001V3.5ZM11.0001 3.99996V3.5C11.0001 3.22386 10.7762 3 10.5001 3H5.5001C5.22396 3 5.0001 3.22386 5.0001 3.5V3.99996H11.0001ZM4.0001 4.99996H3C2.44772 4.99996 2 5.44768 2 5.99996V10.5C2 10.7761 2.22386 11 2.5 11H4.0001V10.5C4.0001 9.67154 4.67167 8.99996 5.5001 8.99996H10.6C11.4284 8.99996 12.1 9.67154 12.1 10.5V11H13.5C13.7761 11 14 10.7761 14 10.5V5.99996C14 5.44768 13.5523 4.99996 13 4.99996H12.0001H4.0001ZM5.5001 9.99996C5.22396 9.99996 5.0001 10.2238 5.0001 10.5V12.5C5.0001 12.7761 5.22396 13 5.5001 13H10.6C10.8761 13 11.1 12.7761 11.1 12.5V10.5C11.1 10.2238 10.8761 9.99996 10.6 9.99996H5.5001Z"
					fill={color}
				/>
			</svg>
		);
	}
);

Print.displayName = 'Print';
