import * as React from 'react';

export const TrippleChevron = React.memo(({ size = 24, ...rest }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		fill="none"
		viewBox="0 0 24 24"
		{...rest}
	>
		<path
			d="M9.3295 17.6705C8.89016 17.2312 8.89016 16.5188 9.3295 16.0795L13.409 12L9.3295 7.9205C8.89016 7.48115 8.89016 6.76884 9.3295 6.3295C9.76884 5.89017 10.4812 5.89017 10.9205 6.3295L15.7955 11.2045C16.0065 11.4155 16.125 11.7016 16.125 12C16.125 12.2984 16.0065 12.5845 15.7955 12.7955L10.9205 17.6705C10.4812 18.1098 9.76884 18.1098 9.3295 17.6705Z"
			fill="#EC5D25"
		/>
		<path
			d="M1.8295 17.6705C1.39017 17.2312 1.39017 16.5188 1.8295 16.0795L5.90901 12L1.8295 7.9205C1.39017 7.48115 1.39017 6.76884 1.8295 6.3295C2.26884 5.89017 2.98116 5.89017 3.4205 6.3295L8.29549 11.2045C8.50647 11.4155 8.625 11.7016 8.625 12C8.625 12.2984 8.50647 12.5845 8.2955 12.7955L3.4205 17.6705C2.98116 18.1098 2.26884 18.1098 1.8295 17.6705Z"
			fill="#EC5D25"
		/>
		<path
			d="M16.8295 17.6705C16.3902 17.2312 16.3902 16.5188 16.8295 16.0795L20.909 12L16.8295 7.9205C16.3902 7.48115 16.3902 6.76884 16.8295 6.3295C17.2688 5.89017 17.9812 5.89017 18.4205 6.3295L23.2955 11.2045C23.5065 11.4155 23.625 11.7016 23.625 12C23.625 12.2984 23.5065 12.5845 23.2955 12.7955L18.4205 17.6705C17.9812 18.1098 17.2688 18.1098 16.8295 17.6705Z"
			fill="#EC5D25"
		/>
	</svg>
));

TrippleChevron.displayName = 'TrippleChevron';
