import * as React from 'react';

export const HotelTopBar = React.memo(
	({ size = 40, color = '#868D9A', className, ...rest }) => {
		return (
			<svg
				width="16"
				height="11"
				viewBox="0 0 16 11"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1.25 0C1.66421 0 2 0.335786 2 0.75V7H8V3.5C8 2.67157 8.67157 2 9.5 2H13.5C14.8807 2 16 3.11929 16 4.5V10.25C16 10.6642 15.6642 11 15.25 11C14.8358 11 14.5 10.6642 14.5 10.25V8.5H2V10.25C2 10.6642 1.66421 11 1.25 11C0.835786 11 0.5 10.6642 0.5 10.25V0.75C0.5 0.335786 0.835786 0 1.25 0ZM14.5 7V4.5C14.5 3.94772 14.0523 3.5 13.5 3.5H9.5V7H14.5ZM3 4C3 2.89543 3.89543 2 5 2C6.10457 2 7 2.89543 7 4C7 5.10457 6.10457 6 5 6C3.89543 6 3 5.10457 3 4ZM5 3.5C4.72386 3.5 4.5 3.72386 4.5 4C4.5 4.27614 4.72386 4.5 5 4.5C5.27614 4.5 5.5 4.27614 5.5 4C5.5 3.72386 5.27614 3.5 5 3.5Z"
					fill={color}
					fill-role="even-odd"
				/>
			</svg>
		);
	}
);

HotelTopBar.displayName = 'HotelTopBar';
