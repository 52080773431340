import * as React from 'react';

export const Edit = React.memo(
	({ size = 15, color = '#6b7280', className, ...rest }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 15 15"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...rest}
			>
				<path
					d="M9.71967 1.72065C10.7029 0.737402 12.2971 0.737404 13.2803 1.72065C14.2636 2.7039 14.2636 4.29806 13.2803 5.28131L4.14645 14.4152C3.77137 14.7903 3.26267 15.001 2.73223 15.001H1C0.447715 15.001 0 14.5533 0 14.001V12.2688C0 11.7383 0.210714 11.2296 0.585786 10.8545L9.71967 1.72065ZM12.2197 2.78131C11.8222 2.38385 11.1778 2.38385 10.7803 2.78131L10.0607 3.50098L11.5 4.94032L12.2197 4.22065C12.6171 3.82319 12.6171 3.17878 12.2197 2.78131ZM10.4393 6.00098L9 4.56164L1.64645 11.9152C1.55268 12.009 1.5 12.1361 1.5 12.2688V13.501H2.73223C2.86484 13.501 2.99202 13.4483 3.08579 13.3545L10.4393 6.00098Z"
					fill={color}
				/>
			</svg>
		);
	}
);

Edit.displayName = 'Edit';
