import * as React from 'react';

export const Error = React.memo(
	({ size = 16, color = '#6b7280', className, ...rest }) => (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			{...rest}
		>
			<path
				d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM6 4C6 3.44772 6.44772 3 7 3C7.55229 3 8 3.44772 8 4V6C8 6.55229 7.55229 7 7 7C6.44772 7 6 6.55229 6 6V4ZM6 10C6 9.44771 6.44772 9 7 9C7.55229 9 8 9.44771 8 10C8 10.5523 7.55229 11 7 11C6.44772 11 6 10.5523 6 10Z"
				fill={color}
			/>
		</svg>
	)
);

Error.displayName = 'Error';
