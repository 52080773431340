import * as React from 'react';

export const Car = React.memo(
	({ size = 16, color = 'white', status = 'default', ...rest }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...rest}
			>
				<path
					d="M6 10C6 10.5523 5.55228 11 5 11C4.44772 11 4 10.5523 4 10C4 9.44772 4.44772 9 5 9C5.55228 9 6 9.44772 6 10Z"
					fill={color}
				/>
				<path
					d="M12 10C12 10.5523 11.5523 11 11 11C10.4477 11 10 10.5523 10 10C10 9.44772 10.4477 9 11 9C11.5523 9 12 9.44772 12 10Z"
					fill={color}
				/>
				<path
					d="M5.58706 2C4.42519 2 3.41646 2.80045 3.15245 3.93193L2.90324 5H2C1.72386 5 1.5 5.22386 1.5 5.5C1.5 5.77614 1.72386 6 2 6H2.71941L2.5622 7.04806C1.6682 7.2477 1 8.04582 1 9V14.25C1 14.6642 1.33579 15 1.75 15C2.16421 15 2.5 14.6642 2.5 14.25V13H13.5V14.25C13.5 14.6642 13.8358 15 14.25 15C14.6642 15 15 14.6642 15 14.25V9C15 8.04582 14.3318 7.2477 13.4378 7.04806L13.2806 6H14C14.2761 6 14.5 5.77614 14.5 5.5C14.5 5.22386 14.2761 5 14 5H13.0968L12.8475 3.93193C12.5835 2.80045 11.5748 2 10.4129 2H5.58706ZM3.58059 7L3.79149 5.59404L4.12629 4.15916C4.2847 3.48027 4.88994 3 5.58706 3H10.4129C11.1101 3 11.7153 3.48027 11.8737 4.15916L12.2085 5.59405L12.4194 7H3.58059ZM2 9C2 8.44772 2.44772 8 3 8H13C13.5523 8 14 8.44772 14 9V12H2V9Z"
					fill={color}
				/>
			</svg>
		);
	}
);

Car.displayName = 'Car';
