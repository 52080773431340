import * as React from 'react';

export const ImageMore = React.memo(
	({ size = 24, color = '#6b7280', className, ...rest }) => {
		return (
			<svg
				width="14"
				height="14"
				viewBox="0 0 14 14"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M2.33337 13.6667C1.77782 13.6667 1.3056 13.4722 0.916707 13.0833C0.527818 12.6944 0.333374 12.2222 0.333374 11.6667V2.33333C0.333374 1.77778 0.527818 1.30556 0.916707 0.916667C1.3056 0.527778 1.77782 0.333333 2.33337 0.333333H11.6667C12.2223 0.333333 12.6945 0.527778 13.0834 0.916667C13.4723 1.30556 13.6667 1.77778 13.6667 2.33333V11.6667C13.6667 12.2222 13.4723 12.6944 13.0834 13.0833C12.6945 13.4722 12.2223 13.6667 11.6667 13.6667H2.33337ZM2.33337 12.6667H11.6667C11.9556 12.6667 12.1945 12.5694 12.3834 12.375C12.5723 12.1806 12.6667 11.9444 12.6667 11.6667V2.33333C12.6667 2.04444 12.5723 1.80556 12.3834 1.61667C12.1945 1.42778 11.9556 1.33333 11.6667 1.33333H2.33337C2.0556 1.33333 1.81949 1.42778 1.62504 1.61667C1.4306 1.80556 1.33337 2.04444 1.33337 2.33333V11.6667C1.33337 11.9444 1.4306 12.1806 1.62504 12.375C1.81949 12.5694 2.0556 12.6667 2.33337 12.6667ZM3.40004 10.8333L5.66671 8.56667L6.88337 9.76667L8.33337 7.93333L10.65 10.8333H3.40004ZM4.33337 5.5C4.01115 5.5 3.73615 5.38611 3.50837 5.15833C3.2806 4.93056 3.16671 4.65556 3.16671 4.33333C3.16671 4.01111 3.2806 3.73611 3.50837 3.50833C3.73615 3.28056 4.01115 3.16667 4.33337 3.16667C4.6556 3.16667 4.9306 3.28056 5.15837 3.50833C5.38615 3.73611 5.50004 4.01111 5.50004 4.33333C5.50004 4.65556 5.38615 4.93056 5.15837 5.15833C4.9306 5.38611 4.6556 5.5 4.33337 5.5Z"
					fill="white"
				/>
			</svg>
		);
	}
);
