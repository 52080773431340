import * as React from 'react';

export const PremiumEconomyCabin = React.memo(({ size = 16, color = '#0A65E7', className }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 16 16"
			className={className}
			fill="none"
		>
			<path
				d="M14.6581 3.97434C14.8623 3.90628 15 3.71522 15 3.5C15 3.28478 14.8623 3.09372 14.6581 3.02566L13.3953 2.60472L12.9743 1.34189C12.9063 1.13772 12.7152 1 12.5 1C12.2848 1 12.0937 1.13772 12.0257 1.34189L11.6047 2.60472L10.3419 3.02566C10.1377 3.09372 10 3.28478 10 3.5C10 3.71522 10.1377 3.90628 10.3419 3.97434L11.6047 4.39528L12.0257 5.65811C12.0937 5.86229 12.2848 6 12.5 6C12.7152 6 12.9063 5.86229 12.9743 5.65811L13.3953 4.39528L14.6581 3.97434Z"
				fill={color}
			/>
			<path
				d="M1.09438 2.20764C1.18836 2.07726 1.33928 2 1.5 2H2.13962C2.57005 2 2.95219 2.27543 3.08831 2.68377L4.72062 7.58073L5.56536 7.15836C5.77364 7.05422 6.00331 7 6.23618 7L9.00011 7C9.27625 7 9.50011 7.22386 9.50011 7.5C9.50011 7.77614 9.27625 8 9.00011 8H6.23618C6.15856 8 6.082 8.01807 6.01257 8.05279L5.04006 8.53904L5.52705 10H11.2929L11.324 9.99995C11.5158 9.99952 11.7062 9.99909 11.8535 10.1464L13.8535 12.1464C14.0488 12.3417 14.0488 12.6583 13.8535 12.8536C13.6583 13.0488 13.3417 13.0488 13.1464 12.8536L12.2929 12H6.8089L6.30891 13H10.4999C10.776 13 10.9999 13.2238 10.9999 13.5C10.9999 13.7761 10.776 14 10.4999 14H6.30891C5.56553 14 5.08203 13.2177 5.41448 12.5528L5.69087 12H5.22076C4.57512 12 4.00191 11.5869 3.79774 10.9743L1.02566 2.65811C0.974837 2.50564 1.00041 2.33803 1.09438 2.20764Z"
				fill={color}
			/>
		</svg>
	);
});

PremiumEconomyCabin.displayName = 'PremiumEconomyCabin';
