import * as React from 'react';

export const Cross = React.memo(
	({ size = 16, color = '#6b7280', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					fill={color}
					fillRule="evenodd"
					d="M4.14995 12.552L3.44995 11.852L7.29995 8.002L3.44995 4.152L4.14995 3.452L7.99995 7.302L11.85 3.452L12.55 4.152L8.69995 8.002L12.55 11.852L11.85 12.552L7.99995 8.702L4.14995 12.552Z"
				/>
			</svg>
		);
	}
);

Cross.displayName = 'Cross';
