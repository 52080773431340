export const errorTypeConstants = {
	404: 404,
	403: 403,
	401: 401,
	400: 400,
	503: 503,
	502: 502,
	500: 500,
	OFFLINE: 'offline',
	MAINTAINANCE: 'maintainance',
};
