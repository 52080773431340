import * as React from 'react';

export const Rewards = React.memo(
	({ size = 11, color = '#1B7E49', className, ...rest }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 11 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...rest}
			>
				<path
					d="M4 0C2.89543 0 2 0.895431 2 2C2 2.36429 2.09739 2.70583 2.26756 3H1C0.447715 3 0 3.44772 0 4V6C0 6.55229 0.447716 7 1 7L1 10.5C1 11.3284 1.67157 12 2.5 12H8.5C9.32843 12 10 11.3284 10 10.5V7C10.5523 7 11 6.55228 11 6V4C11 3.44772 10.5523 3 10 3H8.73244C8.90261 2.70583 9 2.36429 9 2C9 0.895431 8.10457 0 7 0C6.40265 0 5.86647 0.261878 5.5 0.677089C5.13353 0.261878 4.59735 0 4 0ZM6 4H10V6H6V4ZM5 4V6H1V4H5ZM5 7V11H2.5C2.22386 11 2 10.7761 2 10.5V7H5ZM6 11V7H9V10.5C9 10.7761 8.77614 11 8.5 11H6ZM5 2V3H4C3.44772 3 3 2.55228 3 2C3 1.44772 3.44772 1 4 1C4.55228 1 5 1.44772 5 2ZM8 2C8 2.55228 7.55228 3 7 3H6V2C6 1.44772 6.44772 1 7 1C7.55228 1 8 1.44772 8 2Z"
					fill={color}
				/>
			</svg>
		);
	}
);

Rewards.displayName = 'Rewards';
