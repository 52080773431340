import * as React from 'react';

export const Expense = React.memo(
	({ size = 24, color = '#6b7280', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				className={className}
				viewBox="0 0 24 24"
				{...rest}
			>
				<path
					fill={color}
					fillRule="evenodd"
					d="M4.403 4.085a.75.75 0 01.775.05l1.01.701 1.009-.702a.75.75 0 01.856 0l1.01.702 1.009-.702a.75.75 0 01.856 0l1.01.702 1.009-.702a.75.75 0 01.856 0l1.01.702 1.009-.702A.75.75 0 0117 4.75V14h2.25a.75.75 0 01.75.75v4A2.25 2.25 0 0117.75 21H7a3 3 0 01-3-3V4.75a.75.75 0 01.403-.665zM15.628 19.5H7A1.5 1.5 0 015.5 18V6.185l.26.18c.257.18.598.18.856 0l1.009-.701 1.01.702c.257.179.598.179.856 0l1.009-.702 1.01.702c.257.179.598.179.856 0l1.009-.702 1.01.702c.257.179.598.179.856 0l.259-.18V18.75c0 .263.045.515.128.75zm2.122 0a.75.75 0 00.75-.75V15.5H17v3.25c0 .414.336.75.75.75zM7 9.25a.75.75 0 01.75-.75h5.5a.75.75 0 010 1.5h-5.5A.75.75 0 017 9.25zm0 3.5a.75.75 0 01.75-.75h5.5a.75.75 0 010 1.5h-5.5a.75.75 0 01-.75-.75zm0 3.5a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75z"
					clipRule="evenodd"
				></path>
			</svg>
		);
	}
);

Expense.displayName = 'Expense';
