import { memo } from 'react';
import { Collapse as AntCollapse } from 'antd';
import PropTypes from 'prop-types';

import classes from './Collapse.module.scss';

const CollapseComp = ({ items = [], ghost = false, ...rest }) => {
	return (
		<AntCollapse
			className={classes.collapse}
			ghost={ghost}
			items={items}
			{...rest}
		/>
	);
};

CollapseComp.propTypes = {
	items: PropTypes.array,
	ghost: PropTypes.bool,
};

export const Collapse = memo(CollapseComp);
