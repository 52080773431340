import * as React from 'react';
import { Select as AntSelect } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './AsyncSelect.module.scss';

const AsyncSelectComp = (props) => {
	const {
		className = '',
		value = null,
		placeholder = '',
		handleSearch = () => {},
		handleChange = () => {},
		options = [],
		style = {},
		...rest
	} = props;
	return (
		<>
			<AntSelect
				className={clsx(classes.AsyncSelect, className)}
				showSearch
				value={value}
				placeholder={placeholder}
				style={style}
				defaultActiveFirstOption={false}
				showArrow={false}
				filterOption={false}
				onSearch={handleSearch}
				onChange={handleChange}
				notFoundContent={''}
				options={[...options]}
				{...rest}
			/>
		</>
	);
};

AsyncSelectComp.propTypes = {
	placeholder: PropTypes.string,
	className: PropTypes.string,
	handleSearch: PropTypes.func,
	handleChange: PropTypes.func,
	value: PropTypes.any,
	options: PropTypes.array,
	style: PropTypes.object,
};

export const AsyncSelect = React.memo(AsyncSelectComp);
