export const membership_map = {
	DT: 'hilton_honor',
	ES: 'hilton_honor',
	GI: 'hilton_honor',
	HG: 'hilton_honor',
	HH: 'hilton_honor',
	HT: 'hilton_honor',
	HX: 'hilton_honor',
	PY: 'hilton_honor',
	QQ: 'hilton_honor',
	RU: 'hilton_honor',
	SA: 'hilton_honor',
	UA: 'hilton_honor',
	UP: 'hilton_honor',
	IN: 'ihg_rewards_club',
	IC: 'ihg_rewards_club',
	KC: 'ihg_rewards_club',
	RE: 'ihg_rewards_club',
	YX: 'ihg_rewards_club',
	IG: 'ihg_rewards_club',
	VT: 'ihg_rewards_club',
	CP: 'ihg_rewards_club',
	VN: 'ihg_rewards_club',
	UL: 'ihg_rewards_club',
	VX: 'ihg_rewards_club',
	VA: 'ihg_rewards_club',
	HI: 'ihg_rewards_club',
	WE: 'ihg_rewards_club',
	YO: 'ihg_rewards_club',
	YZ: 'ihg_rewards_club',
};
export const membership_map_hotel_level = {
	DT: 'modes.hotel.cards.l1.tags.hilton_honor_available',
	ES: 'modes.hotel.cards.l1.tags.hilton_honor_available',
	GI: 'modes.hotel.cards.l1.tags.hilton_honor_available',
	HG: 'modes.hotel.cards.l1.tags.hilton_honor_available',
	HH: 'modes.hotel.cards.l1.tags.hilton_honor_available',
	HT: 'modes.hotel.cards.l1.tags.hilton_honor_available',
	HX: 'modes.hotel.cards.l1.tags.hilton_honor_available',
	PY: 'modes.hotel.cards.l1.tags.hilton_honor_available',
	QQ: 'modes.hotel.cards.l1.tags.hilton_honor_available',
	RU: 'modes.hotel.cards.l1.tags.hilton_honor_available',
	SA: 'modes.hotel.cards.l1.tags.hilton_honor_available',
	UA: 'modes.hotel.cards.l1.tags.hilton_honor_available',
	UP: 'modes.hotel.cards.l1.tags.hilton_honor_available',
	IN: 'modes.hotel.cards.l1.tags.ihg_rewards_club_available',
	IC: 'modes.hotel.cards.l1.tags.ihg_rewards_club_available',
	KC: 'modes.hotel.cards.l1.tags.ihg_rewards_club_available',
	RE: 'modes.hotel.cards.l1.tags.ihg_rewards_club_available',
	YX: 'modes.hotel.cards.l1.tags.ihg_rewards_club_available',
	IG: 'modes.hotel.cards.l1.tags.ihg_rewards_club_available',
	VT: 'modes.hotel.cards.l1.tags.ihg_rewards_club_available',
	CP: 'modes.hotel.cards.l1.tags.ihg_rewards_club_available',
	VN: 'modes.hotel.cards.l1.tags.ihg_rewards_club_available',
	UL: 'modes.hotel.cards.l1.tags.ihg_rewards_club_available',
	VX: 'modes.hotel.cards.l1.tags.ihg_rewards_club_available',
	VA: 'modes.hotel.cards.l1.tags.ihg_rewards_club_available',
	HI: 'modes.hotel.cards.l1.tags.ihg_rewards_club_available',
	WE: 'modes.hotel.cards.l1.tags.ihg_rewards_club_available',
	YO: 'modes.hotel.cards.l1.tags.ihg_rewards_club_available',
	YZ: 'modes.hotel.cards.l1.tags.ihg_rewards_club_available',
};

export const MEMBERSHIP_RATES = 'membership_rates';
