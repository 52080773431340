import * as React from 'react';

export const NoResult = React.memo(() => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="96"
			height="96"
			fill="none"
			viewBox="0 0 96 96"
		>
			<path
				stroke="#E5E9ED"
				strokeDasharray="1 4"
				strokeLinecap="round"
				strokeWidth="2"
				d="M88 41.07A7.997 7.997 0 0192 48v24a8 8 0 01-8 8H20a8 8 0 01-8-8V60"
			></path>
			<rect
				width="80"
				height="40"
				x="4"
				y="16"
				stroke="#E5E9ED"
				strokeDasharray="1 4"
				strokeLinecap="round"
				strokeWidth="2"
				rx="8"
			></rect>
			<rect
				width="16"
				height="6"
				x="72"
				y="70"
				fill="#E5E9ED"
				rx="3"
			></rect>
			<path
				stroke="#111827"
				strokeLinecap="round"
				strokeWidth="4"
				d="M56 56l16 16"
			></path>
			<path
				stroke="#EC5D25"
				strokeLinecap="round"
				strokeWidth="8"
				d="M63 63l13 13"
			></path>
			<path
				fill="#fff"
				d="M42 62c11.046 0 20-8.954 20-20s-8.954-20-20-20-20 8.954-20 20 8.954 20 20 20zm0-7c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13z"
			></path>
			<path
				stroke="#111827"
				strokeWidth="2"
				d="M42 62c11.046 0 20-8.954 20-20s-8.954-20-20-20-20 8.954-20 20 8.954 20 20 20zm0-7c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13z"
			></path>
			<path
				stroke="#111827"
				strokeLinecap="round"
				strokeWidth="2"
				d="M66 61l12 12"
			></path>
			<path
				stroke="#E5E9ED"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M86.5 6l-2 7M92.5 16L89 17.5"
			></path>
			<rect
				width="16"
				height="6"
				x="64"
				y="46"
				fill="#E5E9ED"
				rx="3"
			></rect>
		</svg>
	);
});

NoResult.displayName = 'No Result';
