import { createSlice } from '@reduxjs/toolkit';

export const authService = createSlice({
	name: 'authService',
	initialState: {
		clientInfo: {},
		navDetails: null,
		clientId: null,
		loadTrackingScripts: false, //Load GTM, Tawk
		loaderDetails: {
			loading: false,
			type: null,
		},
		redirectPostLogin: null, //redirect url post login using Auth URL
		authRefreshSuccess: false, // if Refresh API success post Login
	},
	reducers: {
		setClientInfo: (state, action) => {
			state.clientInfo = action.payload;
			// state.locale = action.payload.
		},
		setNavDetails: (state, action) => {
			state.navDetails = action.payload;
		},
		setStatusTrackingScripts: (state, action) => {
			state.loadTrackingScripts = action.payload;
		},
		setAuthSuccess: (state, action) => {
			state.authRefreshSuccess = action.payload;
		},
		setLoaderDetails: (state, action) => {
			state.loaderDetails = action.payload;
		},
		setRedirectionPostLogin: (state, action) => {
			state.redirectPostLogin = action.payload;
		},
	},
});

export const {
	setClientInfo,
	setNavDetails,
	setStatusTrackingScripts,
	setAuthSuccess,
	setRedirectionPostLogin,
	setLoaderDetails,
} = authService.actions;

export default authService.reducer;
