import * as React from 'react';

export const Alert = React.memo(
	({ size = 16, color = '#6b7280', className, ...rest }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...rest}
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8.00065 2.3335C4.87104 2.3335 2.33398 4.87055 2.33398 8.00016C2.33398 11.1298 4.87104 13.6668 8.00065 13.6668C11.1303 13.6668 13.6673 11.1298 13.6673 8.00016C13.6673 4.87055 11.1303 2.3335 8.00065 2.3335ZM1.33398 8.00016C1.33398 4.31826 4.31875 1.3335 8.00065 1.3335C11.6825 1.3335 14.6673 4.31826 14.6673 8.00016C14.6673 11.6821 11.6825 14.6668 8.00065 14.6668C4.31875 14.6668 1.33398 11.6821 1.33398 8.00016ZM8.00065 6.66683C8.27679 6.66683 8.50065 6.89069 8.50065 7.16683V10.8335C8.50065 11.1096 8.27679 11.3335 8.00065 11.3335C7.72451 11.3335 7.50065 11.1096 7.50065 10.8335V7.16683C7.50065 6.89069 7.72451 6.66683 8.00065 6.66683Z"
					fill={color}
				/>
				<path
					d="M8.66732 5.3335C8.66732 5.70169 8.36884 6.00016 8.00065 6.00016C7.63246 6.00016 7.33398 5.70169 7.33398 5.3335C7.33398 4.96531 7.63246 4.66683 8.00065 4.66683C8.36884 4.66683 8.66732 4.96531 8.66732 5.3335Z"
					fill={color}
				/>
			</svg>
		);
	}
);

Alert.displayName = 'Alert';
