import React from "react";
import clsx from "clsx";

const AvatarNameComp = ({ className, text }) => {

	return (
		<div className={clsx("tw-bg tw-text-orange-600 tw-font-medium tw-bg-[#FFF7ED] tw-rounded-full tw-flex tw-items-center tw-justify-center",
			className)}>
			{text}
		</div>
	);
};

export const AvatarName = React.memo(AvatarNameComp);
