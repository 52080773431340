import * as React from 'react';
import { Tag as AntTag } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './Tag.module.scss';

const TagComp = (props) => {
	const {
		type = 'success',
		className = '',
		title = '',
		icon = null,
		size = '',
		...rest
	} = props;
	return (
		<AntTag
			className={clsx(
				classes.tag,
				size === 'small' && classes.small,
				classes[type],
				className
			)}
			icon={icon}
			{...rest}
		>
			{title}
		</AntTag>
	);
};

TagComp.propTypes = {
	className: PropTypes.string,
	type: PropTypes.oneOf([
		'success',
		'warning',
		'blue',
		'processing',
		'failed',
	]),
	title: PropTypes.string,
	icon: PropTypes.element,
	size: PropTypes.string,
};

export const Tag = React.memo(TagComp);
