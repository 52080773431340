import { useRive } from '@rive-app/react-canvas';
import clsx from 'clsx';

export const RiveSpinner = (props) => {
	const { customClass = '' } = props;
	const { RiveComponent } = useRive({
		src: process.env.REACT_APP_IL_S3_URL + '/riv/il_animations.riv',
		artboard: 'Spinner',
		autoplay: true,
	});
	return (
		<div className={clsx('tw-text-center tw-h-6 tw-w-6', customClass)}>
			<RiveComponent />
		</div>
	);
};
