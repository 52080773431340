import * as React from 'react';

export const CircledChevronRight = React.memo(
	({ size = 16, color = '#6b7280', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="40"
				height="40"
				fill="none"
				viewBox="0 0 40 40"
				className={className}
				{...rest}
			>
				<rect
					xmlns="http://www.w3.org/2000/svg"
					y="0.0019989"
					width="40"
					height="40"
					rx="20"
					fill="#F1F3F5"
				/>
				<path
					fill={color}
					fillRule="evenodd"
					d="M15.4882 29.5138C14.8373 28.863 14.8373 27.8077 15.4882 27.1568L22.643 20.002L15.4882 12.8472C14.8373 12.1963 14.8373 11.141 15.4882 10.4902C16.139 9.83928 17.1943 9.83928 17.8452 10.4902L26.1785 18.8235C26.8294 19.4744 26.8294 20.5296 26.1785 21.1805L17.8452 29.5138C17.1943 30.1647 16.139 30.1647 15.4882 29.5138Z"
				/>
			</svg>
		);
	}
);

CircledChevronRight.displayName = 'CircledChevronRight';
