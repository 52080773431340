import * as React from 'react';

export const CheckInBaggage = React.memo(
	({ size = 16, color = '#363E4F', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					fill={color}
					d="M6 5.5a.5.5 0 011 0v6a.5.5 0 01-1 0v-6zM9.5 5a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5z"
				></path>
				<path
					fill={color}
					d="M7 1a1 1 0 00-1 1v1H4.75A1.75 1.75 0 003 4.75v7.5c0 .966.784 1.75 1.75 1.75H5v.5a.5.5 0 001 0V14h4v.5a.5.5 0 001 0V14h.25A1.75 1.75 0 0013 12.25v-7.5A1.75 1.75 0 0011.25 3H10V2a1 1 0 00-1-1H7zm2 2H7V2h2v1zm2.25 10h-6.5a.75.75 0 01-.75-.75v-7.5A.75.75 0 014.75 4h6.5a.75.75 0 01.75.75v7.5a.75.75 0 01-.75.75z"
				></path>
			</svg>
		);
	}
);

CheckInBaggage.displayName = 'CheckInBaggage';
