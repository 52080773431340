import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import { store } from 'redux/store';
dayjs.extend(duration);
dayjs.extend(utc);

const { default: LocalStorageService } = require('services/LocalStorage');

/**
 *
 * @param {number} value  total cost
 * @param {string} currency - INR | USD ..
 * @returns formatted total value with currency
 */

const currencyFormat = (value = '', currency) => {
	const state = store.getState();
	const fallbackCurrency = state.authService.clientInfo?.user_currency;
	return {
		value: value,
		formatParams: {
			value: {
				currency: currency || fallbackCurrency,
				locale: LocalStorageService.getLocale(),
				minimumFractionDigits: 0,
			},
		},
		ns: 'generic',
	};
};
const zeroPad = (num, places) => String(num).padStart(places, '0');

const randomNumber = (min = 0, max) => {
	if (min >= max) {
		throw new Error('Min must be less than max');
	}

	// Create a typed array to store random values
	let randomValues = new Uint32Array(1);

	// Generate a cryptographically secure random number
	window.crypto.getRandomValues(randomValues);

	// Calculate a random integer within the specified range
	return min + Math.floor(randomValues[0] / (4294967296 / (max - min)));
};

export const formatting = {
	currencyFormat,
	zeroPad,
	randomNumber,
};

export const getLocalDateTimeFromUTC = (
	dateTime,
	clientInfo,
	dateFormat = 'DD MMM'
) => {
	const timeFormat = clientInfo.timeformat === 12 ? 'hh:mm A' : 'HH:mm';
	const utcDateTime = dayjs.utc(dateTime);
	const localDateTime = utcDateTime.local();
	return {
		time: localDateTime.format(timeFormat),
		date: localDateTime.format(dateFormat),
	};
};
