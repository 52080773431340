import * as React from 'react';

export const Bulb = React.memo((props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<circle cx="8" cy="6.5" r="4" fill="#FAE17D"></circle>
			<path
				fill="#363E4F"
				d="M4.5 6.5a3.5 3.5 0 115.954 2.495c-.193.19-.363.437-.445.736L9.664 11H6.336L5.99 9.731a1.648 1.648 0 00-.444-.736A3.488 3.488 0 014.5 6.5zM6.61 12h2.78l-.171.632a.5.5 0 01-.483.368H7.264a.5.5 0 01-.483-.368L6.61 12zM8 2a4.5 4.5 0 00-3.155 7.708.653.653 0 01.18.287l.792 2.9A1.5 1.5 0 007.264 14h1.472a1.5 1.5 0 001.447-1.105l.791-2.9a.654.654 0 01.181-.287A4.5 4.5 0 008 2z"
			></path>
			<path
				stroke="#363E4F"
				strokeLinecap="round"
				d="M12.5 2.5l1.5-2M13.5 4.5L15 4"
			></path>
		</svg>
	);
});
