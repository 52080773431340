import * as React from 'react';

export const Membership = React.memo(
	({ size = 12, color = '#7855DF', className, ...rest }) => {
		return (
			<svg
				width={size + 4}
				height={size}
				viewBox="0 0 16 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...rest}
			>
				<path
					d="M2.13333 0C0.955126 0 0 0.976833 0 2.18182V9.81818C0 11.0232 0.955126 12 2.13333 12H13.8667C15.0449 12 16 11.0232 16 9.81818V2.18182C16 0.976833 15.0449 0 13.8667 0H2.13333ZM7.99999 2.29088C8.202 2.29088 8.38668 2.40761 8.47702 2.5924L9.41604 4.51312L11.2661 4.74964C11.4769 4.77659 11.652 4.92892 11.7118 5.13742C11.7716 5.34592 11.7046 5.57082 11.5414 5.70991L10.1911 6.86072L10.654 8.99074C10.7006 9.20531 10.6167 9.42754 10.4412 9.55444C10.2657 9.68134 10.0329 9.68811 9.85066 9.57161L7.99999 8.38865L6.14932 9.57161C5.96707 9.68811 5.73425 9.68134 5.55876 9.55444C5.38327 9.42754 5.2994 9.20531 5.34602 8.99074L5.80884 6.86072L4.45856 5.70991C4.29535 5.57082 4.22843 5.34592 4.2882 5.13742C4.34797 4.92892 4.52303 4.77659 4.73384 4.74964L6.58395 4.51312L7.52296 2.5924C7.61331 2.40761 7.79798 2.29088 7.99999 2.29088Z"
					fill={color}
				/>
			</svg>
		);
	}
);

Membership.displayName = 'Membership';
