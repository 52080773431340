export const CPH = 'cph';
export const ICH = 'ich';
export const ROOMS_L2 = 'roomsl2';
export const refundableMap = {
	0: 'modes.hotel.cards.cancellation_type.non_refundable',
	1: 'modes.hotel.cards.cancellation_type.free_cancellation',
	2: 'modes.hotel.cards.cancellation_type.partial_refundable',
	3: 'modes.hotel.cards.cancellation_type.cancellation_policy',
};
export const SUMMARY = 'summary';
