import * as React from 'react';

export const RefreshSearch = React.memo(() => {
	return (
		<svg
			width="96"
			height="96"
			viewBox="0 0 96 96"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M56 56L71.9999 72"
				stroke="#111827"
				strokeWidth="4"
				strokeLinecap="round"
			/>
			<path
				d="M63 63L76 76"
				stroke="#EC5D25"
				strokeWidth="8"
				strokeLinecap="round"
			/>
			<path
				d="M42 62C53.0457 62 62 53.0457 62 42C62 30.9543 53.0457 22 42 22C30.9543 22 22 30.9543 22 42C22 53.0457 30.9543 62 42 62ZM42 55C34.8203 55 29 49.1797 29 42C29 34.8203 34.8203 29 42 29C49.1797 29 55 34.8203 55 42C55 49.1797 49.1797 55 42 55Z"
				fill="white"
			/>
			<path
				d="M42 62C53.0457 62 62 53.0457 62 42C62 30.9543 53.0457 22 42 22C30.9543 22 22 30.9543 22 42C22 53.0457 30.9543 62 42 62ZM42 55C34.8203 55 29 49.1797 29 42C29 34.8203 34.8203 29 42 29C49.1797 29 55 34.8203 55 42C55 49.1797 49.1797 55 42 55Z"
				stroke="#111827"
				strokeWidth="2"
			/>
			<path
				d="M66 61L78 73"
				stroke="#111827"
				strokeWidth="2"
				strokeLinejoin="round"
			/>
			<path
				d="M56 87.1999C53.4151 87.7246 50.7397 88 48 88C25.9086 88 8 70.0914 8 48C8 25.9086 25.9086 8 48 8C70.0914 8 88 25.9086 88 48C88 50.7397 87.7246 53.4151 87.1999 56M87.1999 56L81.1999 50M87.1999 56L94 51"
				stroke="#E5E9ED"
				strokeWidth="4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
});

RefreshSearch.displayName = 'Refresh Search';
