import React from 'react';

export const ItiliteLogo = React.memo(() => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			fill="none"
			viewBox="0 0 40 40"
		>
			<path
				fill="#EB5C24"
				d="M40 20c0 11.043-8.957 20-20 20S0 31.043 0 20 8.957 0 20 0s20 8.957 20 20zm-5.03-8.926l-.4-.277-.276-.03h-1.349l-.552.276-.829.521-1.104.829-1.104.828-1.32 1.073-4.723 3.896-1.258.03-5.215-.46-3.404-.214-.368.092-3.804 2.117L9.08 20l-.03.215.214.122 7.607 3.743-3.803 3.834-4.724-.583-.951.031-2.454 1.626-.123.46 4.97 2.27h.92l2.392-1.074 6.472-3.497 7.7-6.564 5.43-4.847 1.686-1.687.46-.797.277-.522.276-.828v-.276l-.123-.276-.307-.276z"
			></path>
		</svg>
	);
});
