import * as React from 'react';

export const Lightning = React.memo(
	({ size = 16, color = '#EC5D25', ...rest }) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill="none"
			viewBox="0 0 16 16"
			{...rest}
		>
			<path
				fill={color}
				d="M9 1.5a.5.5 0 00-.924-.265L3.554 8.47A1 1 0 004.402 10H7v4.5a.5.5 0 00.924.265l4.522-7.235A1 1 0 0011.598 6H9V1.5z"
			></path>
		</svg>
	)
);

Lightning.displayName = 'Lightning';
