import * as React from 'react';

export const Download = React.memo(
	({ size = 16, color = '#111827', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					d="M11.0201 8.85355L8.85339 11.0202C8.65813 11.2155 8.34155 11.2155 8.14628 11.0202L5.97962 8.85355C5.78435 8.65829 5.78435 8.34171 5.97962 8.14645C6.17488 7.95118 6.49146 7.95118 6.68672 8.14645L7.99984 9.45956V2.5C7.99984 2.22386 8.22369 2 8.49984 2C8.77598 2 8.99984 2.22386 8.99984 2.5V9.45956L10.3129 8.14645C10.5082 7.95118 10.8248 7.95118 11.0201 8.14645C11.2153 8.34171 11.2153 8.65829 11.0201 8.85355Z"
					fill={color}
				/>
				<path
					d="M4.1665 11.8333C4.1665 11.5572 3.94265 11.3333 3.6665 11.3333C3.39036 11.3333 3.1665 11.5572 3.1665 11.8333V12.6667C3.1665 13.403 3.76346 14 4.49984 14H12.4998C13.2362 14 13.8332 13.403 13.8332 12.6667V11.8333C13.8332 11.5572 13.6093 11.3333 13.3332 11.3333C13.057 11.3333 12.8332 11.5572 12.8332 11.8333V12.6667C12.8332 12.8508 12.6839 13 12.4998 13H4.49984C4.31574 13 4.1665 12.8508 4.1665 12.6667V11.8333Z"
					fill={color}
				/>
			</svg>
		);
	}
);

Download.displayName = 'Download';
