export const styles = {
	colorPrimary: '#ec5d25',
	colorPrimaryBg: '#fff1e1',
	colorPrimaryHover: '#B94710',
	colorPrimaryActive: '#893404',
	colorSuccessBg: '#d9fbe5',
	colorSuccessBorder: '#70dc9d',
	colorPrimaryBorder: '#fec48c',
	colorSuccess: '#1b7e49',
	colorSuccessHover: '#116238',
	colorSuccessActive: '#044e2a',
	colorWarning: '#9a6700',
	colorWarningHover: '#7d4e00',
	colorWarningActive: '#633c01',
	colorWarningBg: '#fffbe0',
	colorWarningBorder: '#eac54f',
	colorError: '#cf2231',
	colorErrorBorder: '#ffaba9',
	colorErrorHover: '#a40e29',
	colorErrorActive: '#820720',
	colorInfo: '#0a65e7',
	colorInfoBg: '#ebf8ff',
	colorInfoBorder: '#86cbff',
	colorInfoHover: '#054db9',
	colorInfoActive: '#033a94',
	colorTextBase: '#111827',
	colorText: '#111827',
	colorTextSecondary: '#363e4f',
	colorTextTertiary: '#6b7280',
	colorTextQuaternary: '#868d9a',
	colorBorder: '#b6bac3',
	colorBorderSecondary: '#d3d8df',
	colorFill: '#f9fafb',
	colorFillSecondary: '#f1f3f5',
	colorFillQuaternary: '#f9fafb',
	colorBgSpotlight: '#363e4f',
	colorBgMask: '#11182799',
};

export const bluePrimary = '#383DA9';
export const colorPrimary = '#EB5C24';
export const bodyTextSecondary = '#636D85';
