import * as React from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './Checkbox.module.scss';

const CheckboxComp = (props) => {
	const {
		className = '',
		children,
		onChange = () => {},
		checked = false,
		disabled = false,
		...rest
	} = props;
	return (
		<AntCheckbox
			className={clsx(classes.checkbox, className)}
			onChange={onChange}
			checked={checked}
			disabled={disabled}
			{...rest}
		>
			{children}
		</AntCheckbox>
	);
};

CheckboxComp.propTypes = {
	className: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
};

export const Checkbox = React.memo(CheckboxComp);
