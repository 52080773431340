import * as React from 'react';
import { Alert as AntAlert } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './Alert.module.scss';

const AlertComp = ({
	type,
	message,
	description,
	closable,
	className,
	showIcon,
	icon,
	...rest
}) => {
	return (
		<AntAlert
			type={type}
			message={message}
			description={description}
			closable={closable}
			className={clsx(classes.alert, className, classes[type])}
			showIcon={showIcon}
			icon={icon}
			{...rest}
		/>
	);
};

AlertComp.propTypes = {
	type: PropTypes.oneOf(['success', 'warning', 'info', 'error', 'grey']),
	message: PropTypes.string,
	description: PropTypes.string,
	closable: PropTypes.bool,
	className: PropTypes.string,
	showIcon: PropTypes.bool,
	icon: PropTypes.node,
};

export const Alert = React.memo(AlertComp);
