import React from 'react';
import { logErrors } from 'utils';

import BuildUpdating from '../BuildUpdating/BuildUpdating';
import { SystemErrors } from '../SystemErrors';
import CancelError from '../SystemErrors/CancelError';

class ErrorBoundaryInner extends React.Component {
	constructor(props) {
		super(props);

		this.state = { hasError: false, buildUpdating: false };
	}

	static getDerivedStateFromError(_error) {
		const errorString = _error.toString();
		console.log('componentDidCatch', errorString.toLowerCase());
		if (!errorString.toLowerCase().includes('chunkloaderror'))
			return { hasError: true };
	}

	componentDidUpdate(prevProps, _previousState) {
		if (!this.props.hasError && prevProps.hasError) {
			this.setState({ hasError: false });
		}
	}

	componentDidCatch(_error, errorInfo) {
		const errorString = _error.toString();
		const company_ids = this.props.clientInfo?.company_ids;
		const user_ids = this.props.clientInfo?.user_ids;
		const company_name = this.props.clientInfo?.company_name;
		const error = {
			Issue: errorString,
			'Issue Type': 'UI error',
			URL: window.location.href,
			'Component Stack': errorInfo?.componentStack,
			company_ids,
			user_ids,
			company_name,
		};
		console.log(
			'componentDidCatch',
			errorString.toLowerCase(),
			errorString.toLowerCase().includes('chunkloaderror')
		);
		if (errorString.toLowerCase().includes('chunkloaderror')) {
			this.setState({ buildUpdating: true });
			setTimeout(() => {
				window.location.reload();
			}, 1500);
		} else {
			logErrors(error);
			this.props.setHasError(true);
		}
	}

	render() {
		if (this.state.hasError) {
			const urlPath = window.location.pathname;
			const isCancellationError =
				urlPath.includes('cancel-booking') ||
				urlPath.includes('cancel-summary');
			if (isCancellationError) {
				return <CancelError />;
			}
			return (
				<div>
					<SystemErrors errorType={500} />
				</div>
			);
		}
		if (this.state.buildUpdating) {
			return <BuildUpdating />;
		}

		return this.props.children;
	}
}

export default ErrorBoundaryInner;
