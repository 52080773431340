import * as React from 'react';

export const Credits = React.memo(
	({ size = 16, color = '#0A65E7', className, ...rest }) => (
		<svg
			width={size}
			height="10"
			viewBox="0 0 14 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M8 4C8 5.10457 7.10457 6 6 6C4.89543 6 4 5.10457 4 4C4 2.89543 4.89543 2 6 2C7.10457 2 8 2.89543 8 4ZM7 4C7 3.44772 6.55228 3 6 3C5.44772 3 5 3.44772 5 4C5 4.55228 5.44772 5 6 5C6.55228 5 7 4.55228 7 4ZM0 1.25C0 0.559644 0.559644 0 1.25 0H10.75C11.4404 0 12 0.559644 12 1.25V6.75C12 7.44036 11.4404 8 10.75 8H1.25C0.559644 8 0 7.44036 0 6.75V1.25ZM1.25 1C1.11193 1 1 1.11193 1 1.25V2H1.5C1.77614 2 2 1.77614 2 1.5V1H1.25ZM1 6.75C1 6.88807 1.11193 7 1.25 7H2V6.5C2 6.22386 1.77614 6 1.5 6H1V6.75ZM3 6.5V7H9V6.5C9 5.67157 9.67157 5 10.5 5H11V3H10.5C9.67157 3 9 2.32843 9 1.5V1H3V1.5C3 2.32843 2.32843 3 1.5 3H1V5H1.5C2.32843 5 3 5.67157 3 6.5ZM10 7H10.75C10.8881 7 11 6.88807 11 6.75V6H10.5C10.2239 6 10 6.22386 10 6.5V7ZM11 2V1.25C11 1.11193 10.8881 1 10.75 1H10V1.5C10 1.77614 10.2239 2 10.5 2H11ZM3.5 10C2.8334 10 2.26836 9.56517 2.07304 8.96369C2.21179 8.98756 2.35444 9 2.5 9H10.75C11.9926 9 13 7.99264 13 6.75V2.08535C13.5826 2.29127 14 2.84689 14 3.5V6.75C14 8.54493 12.5449 10 10.75 10H3.5Z"
				fill={color}
			/>
		</svg>
	)
);

Credits.displayName = 'Credits';
