import * as React from 'react';

export const Filter = React.memo(
	({ size = 14, color = '#EC5D25', className, ...rest }) => {
		return (
			<svg
				width="14"
				height="11"
				viewBox="0 0 14 11"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M9.11445 1.75C9.43302 0.735723 10.3806 0 11.5 0C12.8807 0 14 1.11929 14 2.5C14 3.88071 12.8807 5 11.5 5C10.3806 5 9.43302 4.26428 9.11445 3.25H0.75C0.335786 3.25 0 2.91421 0 2.5C0 2.08579 0.335786 1.75 0.75 1.75H9.11445ZM11.5 1.5C10.9477 1.5 10.5 1.94772 10.5 2.5C10.5 3.05228 10.9477 3.5 11.5 3.5C12.0523 3.5 12.5 3.05228 12.5 2.5C12.5 1.94772 12.0523 1.5 11.5 1.5ZM0 8.5C0 7.11929 1.11929 6 2.5 6C3.61941 6 4.56698 6.73572 4.88555 7.75H13.25C13.6642 7.75 14 8.08579 14 8.5C14 8.91421 13.6642 9.25 13.25 9.25H4.88555C4.56698 10.2643 3.61941 11 2.5 11C1.11929 11 0 9.88071 0 8.5ZM2.5 7.5C1.94772 7.5 1.5 7.94772 1.5 8.5C1.5 9.05229 1.94772 9.5 2.5 9.5C3.05228 9.5 3.5 9.05229 3.5 8.5C3.5 7.94772 3.05228 7.5 2.5 7.5Z"
					fill={color}
				/>
			</svg>
		);
	}
);
