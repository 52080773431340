import React from 'react';
import { Progress as AntProgress } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './Progress.module.scss';

const ProgressComp = (props) => {
	const {
		type = 'line',
		percent = 0,
		strokeColor = '#ec5d25',
		hidePercent = false,
	} = props;
	return (
		<div className={classes.progress}>
			<AntProgress
				className={clsx({
					[classes.hidePercent]: hidePercent,
				})}
				percent={percent}
				type={type}
				strokeColor={strokeColor}
				{...props}
			/>
		</div>
	);
};

ProgressComp.propTypes = {
	type: PropTypes.oneOf(['line', 'circle', 'dashboard']),
	percent: PropTypes.number,
	open: PropTypes.bool,
	successConfig: PropTypes.object,
};

export const Progress = React.memo(ProgressComp);
