import * as React from 'react';

export const Help = React.memo(
	({ size = 40, color = '#6b7280', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 40 40"
				{...rest}
			>
				<path
					fill={color}
					d="M20 10c5.523 0 10 4.478 10 10s-4.477 10-10 10-10-4.478-10-10 4.477-10 10-10zm0 1.667c-4.595 0-8.333 3.738-8.333 8.333 0 4.595 3.738 8.333 8.333 8.333 4.595 0 8.333-3.738 8.333-8.333 0-4.595-3.738-8.333-8.333-8.333zM20 23.5a1 1 0 110 2 1 1 0 010-2zm0-8.75a2.75 2.75 0 012.75 2.75c0 1.01-.297 1.574-1.051 2.359l-.169.171c-.622.622-.78.886-.78 1.47a.75.75 0 01-1.5 0c0-1.01.297-1.574 1.051-2.359l.169-.171c.622-.622.78-.886.78-1.47a1.25 1.25 0 00-2.494-.128l-.006.128a.75.75 0 01-1.5 0A2.75 2.75 0 0120 14.75z"
				></path>
			</svg>
		);
	}
);

Help.displayName = 'Help';
