import * as React from 'react';

export const Meals = React.memo(
	({ size = 16, color = '#363E4F', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					fill={color}
					d="M3.5 1a.5.5 0 01.5.5v3a1.5 1.5 0 001 1.415V1.5a.5.5 0 011 0v4.415A1.5 1.5 0 007 4.5v-3a.5.5 0 011 0v3a2.5 2.5 0 01-2 2.45v7.55a.5.5 0 01-1 0V6.95A2.5 2.5 0 013 4.5v-3a.5.5 0 01.5-.5zm6.979 1.479c.159-.16.338-.283.521-.364V7h-1V3.5c0-.337.174-.717.479-1.021zM11 8v6.5a.5.5 0 001 0v-13a.5.5 0 00-.5-.5c-.663 0-1.283.326-1.729.771C9.326 2.217 9 2.837 9 3.5v4a.5.5 0 00.5.5H11z"
				></path>
			</svg>
		);
	}
);

Meals.displayName = 'Meals';
