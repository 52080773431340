import * as React from 'react';

export const Support = React.memo(
	({ size = 16, color = '#EC5D25', className, ...rest }) => (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
			className={className}
		>
			<path
				d="M8.33333 1.3335C5.11167 1.3335 2.5 3.94517 2.5 7.16683V11.8335C2.5 12.6619 3.17157 13.3335 4 13.3335H5C5.82843 13.3335 6.5 12.6619 6.5 11.8335V9.50016C6.5 8.67174 5.82843 8.00016 5 8.00016H3.5V7.16683C3.5 4.49745 5.66396 2.3335 8.33333 2.3335H8.66667C11.336 2.3335 13.5 4.49745 13.5 7.16683V8.00016H12C11.1716 8.00016 10.5 8.67174 10.5 9.50016V11.8335C10.5 12.6619 11.1716 13.3335 12 13.3335H13.4882C13.4073 13.8989 12.9211 14.3335 12.3333 14.3335H10.4833C10.4061 13.9531 10.0698 13.6668 9.66667 13.6668H8.66667C8.20643 13.6668 7.83333 14.0399 7.83333 14.5002C7.83333 14.9604 8.20643 15.3335 8.66667 15.3335H12.3333C13.53 15.3335 14.5 14.3634 14.5 13.1668V7.16683C14.5 3.94517 11.8883 1.3335 8.66667 1.3335H8.33333ZM3.5 11.8335V9.00016H5C5.27614 9.00016 5.5 9.22402 5.5 9.50016V11.8335C5.5 12.1096 5.27614 12.3335 5 12.3335H4C3.72386 12.3335 3.5 12.1096 3.5 11.8335ZM12 9.00016H13.5V12.3335H12C11.7239 12.3335 11.5 12.1096 11.5 11.8335V9.50016C11.5 9.22402 11.7239 9.00016 12 9.00016Z"
				fill={color}
			/>
		</svg>
	)
);

Support.displayName = 'Support';
