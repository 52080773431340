import * as React from 'react';

export const CarDoor = React.memo(
	({ size = 16, color = '#6B7280', ...rest }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...rest}
			>
				<path
					d="M13.1667 9.33333H11.1667V10.6667H13.1667V9.33333ZM15.1667 14H2.5V7.33333L7.83333 2H14.5C14.6768 2 14.8464 2.07024 14.9714 2.19526C15.0964 2.32029 15.1667 2.48986 15.1667 2.66667V14ZM8.38667 3.33333L4.38667 7.33333H13.8333V3.33333H8.38667Z"
					fill={color}
				/>
			</svg>
		);
	}
);

CarDoor.displayName = 'CarDoor';
