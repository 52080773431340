import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setLoaderDetails } from 'redux/features/common/slice/authService.slice';

import ErrorBoundaryInner from './ErrorBounaryInner';

export const ErrorBoundary = ({ children }) => {
	const { clientInfo } = useSelector((state) => state.authService);
	const dispatch = useDispatch();
	const [hasError, setHasError] = useState(false);
	const location = useLocation();
	useEffect(() => {
		if (hasError) {
			setHasError(false);
			dispatch(setLoaderDetails({ loading: false, type: null }));
		}
	}, [dispatch, location.key]);
	return (
		<ErrorBoundaryInner
			hasError={hasError}
			setHasError={setHasError}
			clientInfo={clientInfo}
		>
			{children}
		</ErrorBoundaryInner>
	);
};
