import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { RiveSpinner } from 'components/atoms';

const BuildUpdating = () => {
	const { t } = useTranslation('generic');
	return (
		<div
			className={clsx(
				'tw-flex tw-items-center tw-justify-center tw-flex-col tw-h-[90vh]'
			)}
		>
			<RiveSpinner customClass="tw-h-10 tw-w-10 tw-mb-4" />
			<p className="tw-typography-body1 tw-text-color-text-subtle tw-text-center tw-mt-0 tw-whitespace-pre-line">
				{t('site_is_updating')}
			</p>
		</div>
	);
};

export default BuildUpdating;
