import * as React from 'react';

export const ArrowRight = React.memo(
	({ size = 16, color = '#868D9A', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox={`0 0 ${size} ${size}`}
			>
				<path
					fill={color}
					d="M8.22 3.22a.75.75 0 000 1.06l2.97 2.97H2.75a.75.75 0 100 1.5h8.44l-2.97 2.97a.75.75 0 101.06 1.06l4.25-4.25a.75.75 0 000-1.06L9.28 3.22a.75.75 0 00-1.06 0z"
				></path>
			</svg>
		);
	}
);

ArrowRight.displayName = 'ArrowRight';
