import { useInView } from 'react-intersection-observer';

import { Image } from './Image';

export const LazyImage = (props) => {
	const { className = ''} = props;
	const { ref, inView } = useInView({
		triggerOnce: true,
		fallbackInView: true,
	});

	return <div ref={ref} className={className}>{inView ? <Image {...props} /> : null}</div>;
};
