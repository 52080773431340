import * as React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Button } from '../Button/Button';

import classes from './MenuItem.module.scss';

const MenuItemComp = (props) => {
	const {
		label = '',
		onClick = () => {},
		active = false,
		disabled = false,
		size = 'normal',
		className,
		...rest
	} = props;
	return (
		<Button
			type={'default'}
			className={clsx(
				classes.navItem,
				active && classes.active,
				disabled && classes.disabled,
				classes[size],
				className
			)}
			onClick={onClick}
			{...rest}
		>
			{label}
		</Button>
	);
};

MenuItemComp.propTypes = {
	label: PropTypes.string,
	onClick: PropTypes.func,
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	size: PropTypes.oneOf(['small', 'normal']),
};

export const MenuItem = React.memo(MenuItemComp);
