import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	carBookedStatus: false,
};
export const carConfirmation = createSlice({
	name: 'carConfirmationSlice',
	initialState,
	reducers: {
		setCarBookedStatus: (state, action) => {
			state.carBookedStatus = action.payload;
		},
	},
});

export const { setCarBookedStatus } = carConfirmation.actions;

export default carConfirmation.reducer;
