import * as React from 'react';
import { Radio as AntRadio } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './Radio.module.scss';

const RadioComp = (props) => {
	const {
		checked = true,
		className = '',
		children,
		onChange = () => {},
		...rest
	} = props;
	return (
		<AntRadio
			className={clsx(classes.radio, className)}
			onChange={onChange}
			checked={checked}
			{...rest}
		>
			{children}
		</AntRadio>
	);
};

RadioComp.propTypes = {
	checked: PropTypes.bool,
	className: PropTypes.string,
	onChange: PropTypes.func,
};

export const Radio = React.memo(RadioComp);
