import * as React from 'react';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ROOMS_L2 } from 'views/CreateTrip/pages/Packaging/components/Hotels/constants';

import { DotSlider } from '../DotSlider';
import { Image } from '../Image';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import classes from './ImageCarousel.module.scss';

const ImageCarouselComp = (props) => {
	const {
		type = '',
		className = '',
		list = [],
		prevArrow = null,
		nextArrow = null,
		showStatus = false,
		showThumbs = false,
		showArrows = false,
		showIndicators = true,
		fallBackImage = '/images/results/hotel_placeholder.png',
		dotShadow = false,
		prevArrowClassName = 'tw-left-10',
		nextArrowClassName = 'tw-right-10',
		page = '',
		onClick = () => {},
		setCurrentImage = () => {},
		currentImage = 0,
		galleryView = false,
		...rest
	} = props;

	const [activeIndex, setActiveIndex] = React.useState(0);
	const [currentAction, setCurrentAction] = React.useState('rt');
	const [currentSlide, setCurrentSlide] = React.useState({
		currentSlide: 0,
		autoPlay: true,
	});
	const count = Math.min(list.length, 5);

	const numberOfDots = Array.from({ length: count }, (_, index) => index);

	return (
		<div
			className={clsx(
				'tw-flex tw-justify-center',
				className,
				classes.carousel,
				dotShadow && classes.dotShadow
			)}
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<ResponsiveCarousel
				showThumbs={showThumbs}
				showStatus={showStatus}
				showArrows={showArrows}
				showIndicators={false}
				// showIndicators={false && showIndicators && list.length > 1}
				{...rest}
				selectedItem={currentImage || currentSlide.currentSlide}
				renderArrowPrev={(onClickHandler, hasPrev, label) =>
					hasPrev && (
						<button
							type="button"
							onClick={(e) => {
								setCurrentAction('lt');
								onClickHandler(e);
							}}
							title={label}
							className={clsx(
								prevArrow === null &&
									'control-arrow control-prev',
								'tw-absolute tw-z-50 tw-top-0 tw-bottom-0 tw-flex tw-items-center tw-justify-center tw-opacity-50 tw-cursor-pointer tw-border-none tw-border-0 tw-bg-transparent tw-outline-none tw-w-auto',
								prevArrowClassName
							)}
						>
							{prevArrow}
						</button>
					)
				}
				renderArrowNext={(onClickHandler, hasNext, label) =>
					hasNext && (
						<button
							type="button"
							onClick={(e) => {
								setCurrentAction('rt');
								onClickHandler(e);
							}}
							title={label}
							className={clsx(
								nextArrow === null &&
									'control-arrow control-next',
								'tw-absolute  tw-top-0 tw-bottom-0 tw-w-auto tw-flex tw-items-center tw-justify-center tw-opacity-50 tw-cursor-pointer tw-border-none tw-border-0 tw-bg-transparent tw-outline-none',
								nextArrowClassName
							)}
						>
							{nextArrow}
						</button>
					)
				}
				onChange={(currentIndex) => {
					setActiveIndex(currentIndex);
				}}
			>
				{list?.map((item, index) => (
					<div
						className={clsx(
							'tw-max-w-full tw-h-full tw-max-h-[80%]',
							{
								'tw-h-full tw-w-4/5 tw-flex tw-items-center tw-justify-center':
									type === 'viewer',
								'tw-cursor-pointer': page === ROOMS_L2,
							}
						)}
						onClick={onClick}
					>
						<Image
							src={item}
							key={index}
							placeholder={fallBackImage}
							alt="carousel-img"
							className={clsx('', {
								'!tw-object-contain': type === 'viewer',
							})}
						/>
					</div>
				))}
			</ResponsiveCarousel>
			<DotSlider
				show={showIndicators && list?.length > 1}
				currentIndex={activeIndex}
				numberOfDots={numberOfDots}
				totalCount={list?.length}
				setCurrentSlide={setCurrentSlide}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
			/>
		</div>
	);
};

ImageCarouselComp.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	nextArrowClassName: PropTypes.string,
	prevArrowClassName: PropTypes.string,
	list: PropTypes.array,
	prevArrow: PropTypes.element,
	nextArrow: PropTypes.element,
	showStatus: PropTypes.bool,
	showThumbs: PropTypes.bool,
	showArrows: PropTypes.bool,
	showIndicators: PropTypes.bool,
};

export const ImageCarousel = React.memo(ImageCarouselComp);
