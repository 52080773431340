import * as React from 'react';

export const ArrivalIcon = React.memo(
	({ size = 16, color = '#6b7280', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					fill={color}
					fillRule="evenodd"
					d="M6.39456 1.63243C6.55949 1.50372 6.77528 1.4599 6.97735 1.51408L8.90935 2.03208C9.1404 2.09403 9.32054 2.27501 9.38142 2.50633L10.6508 7.32954L13.4928 8.09153C13.748 8.15648 13.9879 8.27119 14.1987 8.4291C14.411 8.58816 14.5896 8.78786 14.7239 9.01658C14.8583 9.24531 14.9459 9.49848 14.9815 9.76137C15.0171 10.0243 15 10.2916 14.9313 10.5478C14.8626 10.804 14.7436 11.044 14.5812 11.2538C14.4189 11.4636 14.2164 11.639 13.9856 11.7698C13.7548 11.9006 13.5003 11.9842 13.2368 12.0156C12.9754 12.0469 12.7103 12.0262 12.4569 11.9547L2.80217 9.36796C2.55826 9.30261 2.37221 9.10506 2.32158 8.85767L1.42491 4.47634C1.37859 4.25 1.45276 4.01581 1.62093 3.85739C1.78911 3.69898 2.02731 3.63893 2.25048 3.69869L4.18248 4.21603C4.35335 4.26178 4.49902 4.37356 4.58744 4.52676L5.39201 5.92082L6.20357 6.13813L6.13813 2.169C6.13468 1.95981 6.22963 1.76114 6.39456 1.63243ZM9.96278 8.5255C9.72401 8.47344 9.52532 8.29191 9.45999 8.04368L8.19044 3.21976L7.48585 3.03085L7.55128 6.99968C7.55473 7.20883 7.45982 7.40746 7.29494 7.53618C7.13006 7.66489 6.91432 7.70876 6.71227 7.65465L4.78027 7.13732C4.6094 7.09157 4.46372 6.97978 4.37531 6.82658L3.57074 5.43253L2.94741 5.26562L3.54523 8.18668L12.8066 10.6681L12.8168 10.6709C12.9018 10.6951 12.9909 10.7022 13.0787 10.6917C13.1665 10.6812 13.2513 10.6534 13.3282 10.6098C13.4052 10.5662 13.4727 10.5077 13.5268 10.4378C13.5809 10.3679 13.6206 10.2879 13.6435 10.2024C13.6664 10.117 13.6721 10.0279 13.6602 9.94029C13.6483 9.85267 13.6192 9.76827 13.5744 9.69203C13.5296 9.61579 13.4701 9.54922 13.3993 9.4962C13.3285 9.44319 13.2479 9.40478 13.1621 9.38321L13.1521 9.3806L9.96278 8.5255ZM1.33337 14C1.33337 13.6318 1.63185 13.3333 2.00004 13.3333H14C14.3682 13.3333 14.6667 13.6318 14.6667 14C14.6667 14.3682 14.3682 14.6667 14 14.6667H2.00004C1.63185 14.6667 1.33337 14.3682 1.33337 14Z"
				></path>
			</svg>
		);
	}
);

ArrivalIcon.displayName = 'ArrivalIcon';
