import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRive } from '@rive-app/react-canvas';
import clsx from 'clsx';
import { Bulb } from 'icons';

const CTLoader = (props) => {
	const { t } = useTranslation('generic');
	const { extraParam = {} } = props;
	const getTypeDef = {
		1: 'searching',
		2: 'retrieving',
		3: 'matching',
	};
	const trivia_list = [
		t('loaders.createTrip.trivia', { context: 'first' }),
		t('loaders.createTrip.trivia', { context: 'second' }),
		t('loaders.createTrip.trivia', { context: 'third' }),
		t('loaders.createTrip.trivia', { context: 'fourth' }),
		t('loaders.createTrip.trivia', { context: 'fifth' }),
		t('loaders.createTrip.trivia', { context: 'sixth' }),
	];

	const trivia = trivia_list[extraParam?.randomNumber];

	const { rive, RiveComponent } = useRive({
		src: process.env.REACT_APP_IL_S3_URL + '/riv/il_animations.riv',
		artboard: 'Searching',
		autoplay: true,
	});

	return (
		<div
			className={clsx(
				'tw-h-[calc(100%-96px)] tw-mt-[96px] tw-w-full tw-ml-0 tw-flex tw-items-center tw-justify-center',
				{
					'md:tw-ml-16': extraParam.navbarSpace,
					'md:tw-w-[calc(100%-64px)]': extraParam.navbarSpace,
				}
			)}
		>
			<div
				className={
					'tw-text-center tw-h-1/3 tw-w-full md:tw-w-96 tw-relative tw-top-[-10%]'
				}
			>
				<RiveComponent />
				{rive?.loaded && (
					<>
						<div>
							{extraParam?.type && (
								<p className=" tw-typography-body1Bold tw-mb-4 tw-text-color-text-default">
									{t('loaders.createTrip.title', {
										context: getTypeDef[extraParam?.type],
									})}
								</p>
							)}
							{extraParam?.type && trivia && (
								<p>
									<Bulb className="tw-mb-[-3px]" />
									<span className=" tw-typography-caption1 tw-text-color-text-subtle ">
										{trivia}
									</span>
								</p>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export const CreateTripLoader = React.memo(CTLoader);
