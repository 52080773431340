import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { ProfileIcon } from 'components/compounds/UserIcon';

const AvatarGroupComp = ({ pax_list, align = 'right', iconSize = 16 }) => {
	const { t } = useTranslation('generic');
	const iconOverlapConstant = {
		16: 14,
		20: 17,
		24: 20,
	};
	const iconClassConstant = {
		16: 'tw-w-4 tw-h-4',
		20: 'tw-w-5 tw-h-5',
		24: 'tw-w-6 tw-h-6',
	};
	let totalShift = 0;
	const getTotalShift = (i, total) => {
		totalShift = i * iconOverlapConstant[iconSize];
		if (align === 'right') {
			return {
				right: i * iconOverlapConstant[iconSize],
				zIndex: total - i,
			};
		} else if (align === 'left') {
			return {
				left: i * iconOverlapConstant[iconSize],
				zIndex: i,
			};
		}
	};
	let avatarText =
		pax_list.length > 1
			? `${pax_list[0]?.first_name.split(' ')[0]} ${t(
				'avatar_group.and'
			  )} ${t('avatar_group.other', {
				count: pax_list.length - 1,
			  })}`
			: pax_list[0].first_name.split(' ')[0];
	return (
		<div className="tw-flex tw-items-center">
			<div
				className="tw-flex-row-reverse tw-flex tw-relative "
				style={{ height: iconSize }}
			>
				{pax_list.map((trvlr, ind) => {
					if (trvlr) {
						if (trvlr.trip_owner)
							avatarText =
								pax_list.length > 1
									? `${t('avatar_group.you')} ${t(
										'avatar_group.and'
									  )} ${t('avatar_group.other', {
										count: pax_list.length - 1,
									  })}`
									: t('avatar_group.you');
						return (
							<div
								className={clsx(
									'tw-rounded-full tw-absolute tw-border-solid tw-border-[2px] tw-border-color-white tw-object-cover tw-top-0'
								)}
								style={getTotalShift(ind, pax_list.length)}
								key={'traveler' + ind}
							>
								<ProfileIcon
									version={2}
									img={trvlr.profile_pic}
									name={`${trvlr.first_name} ${trvlr.last_name}`}
									className={clsx(
										`tw-border-none`,
										iconClassConstant[iconSize]
									)}
									height={iconSize}
									width={iconSize}
									size="small"
								/>
							</div>
						);
					}
					return null;
				})}
			</div>
			<p
				className="tw-m-0 tw-ml-2 tw-typography-caption1 tw-text-color-text-weak tw-relative tw-mt-1"
				style={{
					left:
						align === 'right' ? '0' : `${totalShift + iconSize}px`,
				}}
			>
				{avatarText}
			</p>
		</div>
	);
};
AvatarGroupComp.propTypes = {
	pax_list: PropTypes.array,
	align: PropTypes.oneOf(['left', 'right']),
	iconSize: PropTypes.oneOf([16, 20, 24]),
};

export const AvatarGroup = React.memo(AvatarGroupComp);
