import { usmAPI } from 'redux/services/apiUSM';
import LocalStorageService from 'services/LocalStorage';
import { apiUrls } from 'utils/urls/apiUrls';

const authServices = usmAPI.injectEndpoints({
	endpoints: (build) => ({
		validateToken: build.query({
			//get apis use query, for other methods use mutation
			query: () => apiUrls.AUTH.VERIFY_TOKEN,
		}),
		fetchNavbar: build.query({
			query: (company_id = '') => `${apiUrls.AUTH.NAVBAR}${company_id}`,
		}),
		fetchNavbarPOST: build.mutation({
			query: (payload) => ({
				url: `${apiUrls.AUTH.NAVBAR}${payload.company_id}`,
				method: 'POST',
				body: payload.data,
			}),
		}),
		logout: build.mutation({
			query: () => ({
				url: apiUrls.AUTH.LOGOUT,
				method: 'POST',
				body: {
					refresh: LocalStorageService.getRefreshToken(),
				},
			}),
		}),
	}),
	overrideExisting: false,
});

export const {
	useValidateTokenQuery,
	useFetchNavbarQuery,
	useFetchNavbarPOSTMutation,
	useLogoutMutation,
} = authServices;
