import React from 'react';
import clsx from 'clsx';

const AvatarNameComp = ({ className, text, size }) => {
	const COLOR_MAP = {
		A: '#A7A3BF',
		B: '#B28C94',
		C: '#7BB3AD',
		D: '#D5A670',
		E: '#D1AFC7',
		F: '#A7A3BF',
		G: '#B28C94',
		H: '#7BB3AD',
		I: '#D5A670',
		J: '#D1AFC7',
		K: '#A7A3BF',
		L: '#B28C94',
		M: '#7BB3AD',
		N: '#D5A670',
		O: '#D1AFC7',
		P: '#A7A3BF',
		Q: '#B28C94',
		R: '#7BB3AD',
		S: '#D5A670',
		T: '#D1AFC7',
		U: '#A7A3BF',
		V: '#B28C94',
		W: '#7BB3AD',
		X: '#D5A670',
		Y: '#D1AFC7',
		Z: '#A7A3BF',
	};
	return (
		<div
			style={{ backgroundColor: COLOR_MAP[text[0]] }}
			className={clsx(
				{ 'tw-text-font-size-10': size === 'small' },
				`tw-text-white tw-font-medium tw-rounded-full tw-flex tw-items-center tw-justify-center `,
				className
			)}
		>
			{text[0]}
		</div>
	);
};

export const AvatarNameV2 = React.memo(AvatarNameComp);
