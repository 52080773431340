import * as React from 'react';

export const Wifi = React.memo(
	({ size = 16, color = '#363E4F', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					fill={color}
					d="M1.31 5.98A8.976 8.976 0 018 3a8.976 8.976 0 016.69 2.98.5.5 0 10.743-.67A9.976 9.976 0 008 2 9.976 9.976 0 00.566 5.31a.5.5 0 10.744.67z"
				></path>
				<path
					fill={color}
					d="M3.443 8.096A5.985 5.985 0 018 6c1.823 0 3.455.812 4.556 2.096a.5.5 0 10.76-.65A6.985 6.985 0 008 5c-2.127 0-4.033.95-5.316 2.445a.5.5 0 10.76.651z"
				></path>
				<path
					fill={color}
					d="M5.597 10.204A2.995 2.995 0 018 9c.982 0 1.855.472 2.403 1.204a.5.5 0 00.8-.6A3.995 3.995 0 008 8a3.995 3.995 0 00-3.204 1.604.5.5 0 00.8.6zM8 13a1 1 0 100-2 1 1 0 000 2z"
				></path>
			</svg>
		);
	}
);

Wifi.displayName = 'Wifi';
