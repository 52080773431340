import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { OfflineIcon } from 'icons/OfflineIcon';

const OfflineScreen = () => {
	const { t } = useTranslation(['generic']);

	let errorDetails = {
		image: <OfflineIcon />,
		label: t('error.offline.label'),
		description: t('error.offline.description'),
	};
	return (
		<div
			className={clsx(
				'tw-flex tw-items-center tw-justify-center tw-flex-col tw-h-[90vh]'
			)}
		>
			{errorDetails.image}
			<p className="tw-text-color-text-subtle tw-mb-3 tw-typography-title2">
				{errorDetails.label}
			</p>
			<p className="tw-typography-body2 tw-text-color-text-subtle tw-text-center tw-mt-0 tw-whitespace-pre-line">
				{errorDetails.description}
			</p>
		</div>
	);
};

export { OfflineScreen };
