import * as React from 'react';

export const EconomyCabin = React.memo(
	({ size = 16, color = '#868D9A', className }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				viewBox="0 0 16 16"
				fill="none"
				className={className}
			>
				<path
					d="M1.09438 2.20764C1.18836 2.07726 1.33928 2 1.5 2H2.13962C2.57005 2 2.95219 2.27543 3.08831 2.68377L4.72063 7.58073L5.56536 7.15836C5.77364 7.05422 6.00331 7 6.23618 7L9.00011 7C9.27625 7 9.50011 7.22386 9.50011 7.5C9.50011 7.77614 9.27625 8 9.00011 8H6.23618C6.15856 8 6.082 8.01807 6.01257 8.05279L5.04006 8.53904L5.52705 10H11C11.5523 10 12 10.4477 12 11C12 11.5523 11.5523 12 11 12H6.8089L6.30891 13H10.4999C10.776 13 10.9999 13.2238 10.9999 13.5C10.9999 13.7761 10.776 14 10.4999 14H6.30891C5.56553 14 5.08203 13.2177 5.41448 12.5528L5.69087 12H5.22076C4.57512 12 4.00191 11.5869 3.79774 10.9743L1.02566 2.65811C0.974837 2.50564 1.00041 2.33803 1.09438 2.20764Z"
					fill={color}
				/>
			</svg>
		);
	}
);

EconomyCabin.displayName = 'EconomyCabin';
