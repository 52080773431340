import * as React from 'react';

export const FareSelectionIcon = React.memo(
	({ size = 14, color = '#EC5D25', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="10"
				viewBox="0 0 24 10"
				fill="none"
			>
				<path d="M12 1L3 10H21L12 1Z" fill="#F1F3F5" />
				<path
					d="M23 9.5H20.5L12 1L3.5 9.5H1"
					stroke="#D3D8DF"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		);
	}
);

FareSelectionIcon.displayName = 'FareSelectionIcon';
