import { Skeleton as AntSkeleton } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './Skeleton.module.scss';

const AvatarSkeleton = (props) => {
	const { shape, size, className, ...rest } = props;
	return (
		<AntSkeleton.Avatar
			className={clsx(classes.skeleton, 'tw-mb-1', className)}
			shape={shape}
			size={size}
			{...rest}
		/>
	);
};

const SkeletonComp = (props) => {
	const {
		width = 10,
		widthType = '%',
		paragraph = false,
		active = true,
		height = 16,
		size = 'small',
		className,
		...rest
	} = props;
	return (
		<AntSkeleton
			className={clsx(classes.skeleton, 'tw-mb-1', className)}
			style={{ width: width + widthType, height: height }}
			paragraph={paragraph}
			size={size}
			active={active}
			{...rest}
		/>
	);
};

SkeletonComp.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	size: PropTypes.string,
	active: PropTypes.bool,
	paragraph: PropTypes.bool,
	widthType: PropTypes.oneOf(['%', 'px', 'rem']),
};

export { SkeletonComp as Skeleton, AvatarSkeleton };
