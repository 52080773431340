import * as React from 'react';

export const ChevronRedDown = React.memo(
	({ size = 24, color = '#EC5D25', className, ...rest }) => {
		return (
			<svg
				width="10"
				height="6"
				viewBox="0 0 10 6"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...rest}
			>
				<path
					d="M0.292893 0.292954C0.683417 -0.0975701 1.31658 -0.0975701 1.70711 0.292954L5 3.58585L8.29289 0.292954C8.68342 -0.0975701 9.31658 -0.0975701 9.70711 0.292954C10.0976 0.683479 10.0976 1.31664 9.70711 1.70717L5.70711 5.70717C5.31658 6.09769 4.68342 6.09769 4.29289 5.70717L0.292893 1.70717C-0.0976311 1.31664 -0.0976311 0.683479 0.292893 0.292954Z"
					fill={color}
				/>
			</svg>
		);
	}
);
