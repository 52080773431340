import * as React from 'react';

export const Tap = React.memo(
	({ size = 16, color = '#6B7280', className, ...rest }) => (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
			className={className}
		>
			<path
				d="M4.49887 5.50509C4.49887 3.56991 6.065 2.00113 7.99691 2.00113C9.92881 2.00113 11.4949 3.56991 11.4949 5.50509C11.4949 5.55895 11.4937 5.61253 11.4913 5.6658L12.4822 5.83962C12.4903 5.72917 12.4944 5.61761 12.4944 5.50509C12.4944 3.017 10.4808 1 7.99691 1C5.51302 1 3.49944 3.017 3.49944 5.50509C3.49944 6.15399 3.6364 6.77084 3.88294 7.32823C4.19072 7.24778 4.50584 7.20458 4.82229 7.19526C4.85858 7.19419 4.8948 7.19356 4.93094 7.19336C4.65556 6.69263 4.49887 6.11719 4.49887 5.50509ZM7.99699 3.00226C7.16903 3.00226 6.49783 3.6746 6.49783 4.50396V8.76021C5.91392 8.56204 5.25542 8.44394 4.6326 8.51138C4.17505 8.56093 3.71782 8.71318 3.33231 9.02758C2.94323 9.34487 2.66229 9.79888 2.51492 10.3893C2.47995 10.5295 2.50711 10.6779 2.58944 10.7965C2.67176 10.9151 2.80122 10.9923 2.94454 11.0083C5.53499 11.2966 6.85697 12.7108 7.49471 13.7392C7.97406 14.5121 8.90433 15.1482 9.92916 14.9697L11.4451 14.7056C12.3232 14.5526 13.0535 13.9427 13.3616 13.1049L14.3447 10.4316C14.8834 8.96656 13.9664 7.37044 12.4311 7.10112L9.49614 6.58627V4.50396C9.49614 3.6746 8.82495 3.00226 7.99699 3.00226ZM7.49727 4.50396C7.49727 4.2275 7.721 4.00339 7.99699 4.00339C8.27297 4.00339 8.49671 4.2275 8.49671 4.50396V7.00679C8.49671 7.24993 8.67113 7.45791 8.91022 7.49985L12.2587 8.08725C13.1799 8.24884 13.7301 9.20651 13.4069 10.0855L12.4237 12.7589C12.2389 13.2615 11.8007 13.6275 11.2739 13.7193L9.75791 13.9834C9.25652 14.0707 8.68176 13.7561 8.34367 13.2109C7.64317 12.0813 6.25132 10.5875 3.69762 10.1088C3.77724 9.98117 3.86774 9.88188 3.96331 9.80394C4.16602 9.63863 4.42716 9.5406 4.74002 9.50672C5.38184 9.43721 6.15753 9.64854 6.77407 9.95734C6.92898 10.0349 7.11294 10.0266 7.26027 9.93542C7.40759 9.84422 7.49727 9.6831 7.49727 9.50962V4.50396Z"
				fill={color}
			/>
		</svg>
	)
);

Tap.displayName = 'Tap';
