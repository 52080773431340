import { lazy } from 'react';

// export default function lazyComponent(component, attemptsLeft = 3) { //! Don't delete it. Will use it in next version
// 	return new Promise((resolve, reject) => {
// 		lazy()
// 			.then(resolve)
// 			.catch((error) => {
// 				// let us retry after 1500 ms
// 				setTimeout(() => {
// 					if (attemptsLeft === 1) {
// 						reject(error);
// 						return;
// 					}
// 					lazyComponent(component, attemptsLeft - 1).then(
// 						resolve,
// 						reject
// 					);
// 				}, 1500);
// 			});
// 	});
// }
export const lazyComponent = (componentImport) =>
	lazy(async () => {
		const pageHasAlreadyBeenForceRefreshed = JSON.parse(
			window.localStorage.getItem('page-has-been-force-refreshed') ||
				'false'
		);

		try {
			const component = await componentImport();

			window.localStorage.setItem(
				'page-has-been-force-refreshed',
				'false'
			);

			return component;
		} catch (error) {
			if (!pageHasAlreadyBeenForceRefreshed) {
				// Assuming that the user is not on the latest version of the application.
				// Let's refresh the page immediately.
				window.localStorage.setItem(
					'page-has-been-force-refreshed',
					'true'
				);
				return window.location.reload();
			}

			// The page has already been reloaded
			// Assuming that user is already using the latest version of the application.
			// Let's let the application crash and raise the error.
			throw error;
		}
	});
