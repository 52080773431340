import * as React from 'react';

export const Calendar = React.memo(
	({ size = 16, color = '#363E4F', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					fill={color}
					d="M5.248 8.997a.748.748 0 100-1.497.748.748 0 000 1.497zm.749 1.752a.748.748 0 11-1.497 0 .748.748 0 011.497 0zM8 8.996A.748.748 0 108 7.5a.748.748 0 000 1.497zm.749 1.752a.748.748 0 11-1.497 0 .748.748 0 011.497 0zm2-1.752a.748.748 0 100-1.497.748.748 0 000 1.497zM14 4.5A2.5 2.5 0 0011.5 2h-7A2.5 2.5 0 002 4.5v7A2.5 2.5 0 004.5 14h7a2.5 2.5 0 002.5-2.5v-7zM3 6h10v5.5a1.5 1.5 0 01-1.5 1.5h-7A1.5 1.5 0 013 11.5V6zm1.5-3h7A1.5 1.5 0 0113 4.5V5H3v-.5A1.5 1.5 0 014.5 3z"
				></path>
			</svg>
		);
	}
);

Calendar.displayName = 'Calendar';
