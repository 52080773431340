import * as React from 'react';

export const OutOfPolicy = React.memo(
	({ size = 16, color = '#CF2231', className, ...rest }) => (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			{...rest}
		>
			<path
				d="M11.0249 11.732L14.1464 14.8536C14.3417 15.0488 14.6583 15.0488 14.8536 14.8536C15.0488 14.6583 15.0488 14.3417 14.8536 14.1464L1.85355 1.14645C1.65829 0.951184 1.34171 0.951184 1.14645 1.14645C0.951184 1.34171 0.951184 1.65829 1.14645 1.85355L3.32457 4.03168C3.1335 4.10317 3 4.28725 3 4.49999V7.06548C3 9.07132 3.92607 10.9648 5.50939 12.1963L7.69303 13.8947C7.87358 14.0351 8.12642 14.0351 8.30697 13.8947L10.4906 12.1963C10.6781 12.0505 10.8563 11.8954 11.0249 11.732ZM10.3177 11.0248C10.1783 11.1591 10.0312 11.2867 9.87667 11.4069L8 12.8666L6.12333 11.4069C4.7836 10.3649 4 8.76273 4 7.06548V4.95248L4.22307 4.93017L10.3177 11.0248Z"
				fill={color}
			/>
			<path
				d="M12 7.06548C12 7.87239 11.8229 8.6578 11.4931 9.37175L12.2389 10.1175C12.7324 9.18959 13 8.14377 13 7.06548V4.49999C13 4.24311 12.8054 4.02803 12.5498 4.00247L11.6411 3.91161C10.7096 3.81845 9.83022 3.43705 9.12564 2.82053L8.32925 2.1237C8.14074 1.95875 7.85926 1.95875 7.67075 2.1237L6.87436 2.82053C6.51858 3.13184 6.11825 3.3832 5.68913 3.56777L6.43961 4.31826C6.83048 4.11592 7.19784 3.86626 7.53287 3.57311L8 3.16437L8.46713 3.57311C9.32829 4.32663 10.403 4.79278 11.5416 4.90664L12 4.95248V7.06548Z"
				fill={color}
			/>
		</svg>
	)
);

OutOfPolicy.displayName = 'OutOfPolicy';
