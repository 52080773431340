import * as React from 'react';

export const CheckIn = React.memo(
	({ size = 12, color = '#1B7E49', className, ...rest }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 12 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...rest}
			>
				<path
					d="M6.15 12V11H11V1H6.15V0H11C11.2667 0 11.5 0.1 11.7 0.3C11.9 0.5 12 0.733333 12 1V11C12 11.2667 11.9 11.5 11.7 11.7C11.5 11.9 11.2667 12 11 12H6.15ZM4.85 8.91667L4.13333 8.2L5.83333 6.5H0V5.5H5.8L4.1 3.8L4.81667 3.08333L7.75 6.01667L4.85 8.91667Z"
					fill={color}
				/>
			</svg>
		);
	}
);
