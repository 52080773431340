import { Dots } from 'icons';

export const FlightStops = ({ numberOfStops = 0 }) => {
	return (
		<div className="tw-flex tw-flex-col tw-relative">
			<div className="tw-flex tw-items-center">
				<Dots className="tw-z-[2]" color="#D3D8DF" />
				<div className="tw-h-[1px] tw-w-full tw-bg-color-border-default-subtle"></div>
				<Dots className="tw-z-[2]" color="#D3D8DF" />
			</div>
			<div className="tw-flex tw-self-center tw-relative tw-bottom-[5px]">
				{numberOfStops > 0 &&
					[...Array(numberOfStops)].map(() => (
						<Dots className="tw-z-[2] tw-mr-1" />
					))}
			</div>
		</div>
	);
};
