import * as React from 'react';

export const DepartureIcon = React.memo(
	({ size = 16, color = '#6b7280', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					fill={color}
					fillRule="evenodd"
					d="M5.80341 2.37673C6.03454 2.31476 6.28116 2.38142 6.4496 2.55141L9.9606 6.09457L12.8013 5.33353C13.0547 5.26133 13.3198 5.2399 13.5816 5.2705C13.8457 5.30138 14.101 5.38461 14.3325 5.51533C14.5641 5.64605 14.7673 5.82164 14.9302 6.03181C15.093 6.24198 15.2124 6.48253 15.2812 6.73937C15.35 6.99622 15.3669 7.26421 15.3309 7.52767C15.2949 7.79112 15.2068 8.04476 15.0716 8.27374C14.9364 8.50272 14.7569 8.70244 14.5436 8.86123C14.3323 9.01859 14.0919 9.13262 13.8363 9.19677L4.18263 11.7839C3.93867 11.8493 3.67872 11.7712 3.51118 11.5822L0.544511 8.23555C0.391251 8.06266 0.338434 7.82274 0.40492 7.60147C0.471407 7.38021 0.647719 7.20914 0.870892 7.14936L2.80223 6.63202C2.97296 6.58629 3.15487 6.61023 3.30796 6.69859L4.70221 7.50327L5.51317 7.28604L3.47225 3.88142C3.36471 3.70202 3.3476 3.48255 3.42604 3.28865C3.50448 3.09475 3.66938 2.9489 3.87141 2.89473L5.80341 2.37673ZM5.07016 3.95375L7.11119 7.35855C7.21874 7.53798 7.23584 7.75749 7.15737 7.9514C7.0789 8.14532 6.91395 8.29116 6.71188 8.34529L4.78055 8.86262C4.60981 8.90835 4.4279 8.88441 4.27481 8.79606L2.88056 7.99138L2.25774 8.15821L4.23555 10.3894L13.4968 7.90738L13.5094 7.90415C13.5954 7.88287 13.6763 7.84465 13.7474 7.79172C13.8185 7.73879 13.8784 7.67222 13.9234 7.59589C13.9685 7.51957 13.9979 7.43502 14.0099 7.3472C14.0219 7.25938 14.0162 7.17005 13.9933 7.08444C13.9704 6.99882 13.9306 6.91864 13.8763 6.84858C13.822 6.77853 13.7543 6.72 13.6771 6.67642C13.5999 6.63285 13.5148 6.60511 13.4268 6.59481C13.3387 6.58452 13.2495 6.59189 13.1644 6.61648L13.1519 6.61995L9.93191 7.48261C9.7008 7.54453 9.45424 7.47785 9.28584 7.30791L5.7749 3.7648L5.07016 3.95375ZM1.33339 14C1.33339 13.6318 1.63186 13.3333 2.00005 13.3333H14.0001C14.3682 13.3333 14.6667 13.6318 14.6667 14C14.6667 14.3682 14.3682 14.6667 14.0001 14.6667H2.00005C1.63186 14.6667 1.33339 14.3682 1.33339 14Z"
				></path>
			</svg>
		);
	}
);

DepartureIcon.displayName = 'DepartureIcon';
