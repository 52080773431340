import * as React from 'react';

export const Parking = React.memo(
	({ size = 16, color = '#6b7280', className, ...rest }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...rest}
			>
				<path
					d="M10 0.5C10 0.223858 10.2239 0 10.5 0H14.5C14.7761 0 15 0.223858 15 0.5V5.5C15 5.77614 14.7761 6 14.5 6H13V14.5C13 14.7761 12.7761 15 12.5 15C12.2239 15 12 14.7761 12 14.5V6H10.5C10.2239 6 10 5.77614 10 5.5V0.5ZM5.31876 2H9V3H5.31876C4.81599 3 4.39138 3.3733 4.327 3.87194L4.05222 6H9.08535C9.29127 6.5826 9.84689 7 10.5 7H3.5C3.22386 7 3 7.22386 3 7.5V10.5C3 10.7761 3.22386 11 3.5 11H11V12H5V13.25C5 13.6642 4.66421 14 4.25 14C3.83579 14 3.5 13.6642 3.5 13.25L3.5 12C2.67157 12 2 11.3284 2 10.5V7.5C2 6.83411 2.43391 6.26955 3.0344 6.07366L3.04392 6H2.50005C2.22391 6 2.00005 5.77614 2.00005 5.5C2.00005 5.22386 2.22391 5 2.50005 5H3.17304L3.33523 3.74388C3.464 2.74661 4.31321 2 5.31876 2ZM10.749 8.25098C10.837 8.25098 10.9215 8.26617 11 8.29408V9.70583C10.9215 9.73374 10.837 9.74893 10.749 9.74893C10.3353 9.74893 10 9.4136 10 8.99996C10 8.58631 10.3353 8.25098 10.749 8.25098ZM6 8.99996C6 9.4136 5.66467 9.74893 5.25102 9.74893C4.83737 9.74893 4.50204 9.4136 4.50204 8.99996C4.50204 8.58631 4.83737 8.25098 5.25102 8.25098C5.66467 8.25098 6 8.58631 6 8.99996Z"
					fill="#6B7280"
				/>
			</svg>
		);
	}
);

Parking.displayName = 'Parking';
