import { Toast } from 'components/atoms';

import AppRoutes from './routes/Routes';

import './App.css';
import './theming/__globals.scss';
import 'react-toastify/dist/ReactToastify.css';

function App() {
	return (
		<>
			<AppRoutes />
			<Toast />
		</>
	);
}

export default App;
