import * as React from 'react';

export const ChevronRightTopBar = React.memo(() => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
		>
			<g filter="url(#filter0_dd_2444_138736)">
				<rect x="8" y="4" width="32" height="32" rx="16" fill="white" />
				<path
					d="M21.2929 25.7071C20.9024 25.3166 20.9024 24.6834 21.2929 24.2929L25.5858 20L21.2929 15.7071C20.9024 15.3166 20.9024 14.6834 21.2929 14.2929C21.6834 13.9024 22.3166 13.9024 22.7071 14.2929L27.7071 19.2929C28.0976 19.6834 28.0976 20.3166 27.7071 20.7071L22.7071 25.7071C22.3166 26.0976 21.6834 26.0976 21.2929 25.7071Z"
					fill="#6B7280"
				/>
			</g>
			<defs>
				<filter
					id="filter0_dd_2444_138736"
					x="0"
					y="0"
					width="48"
					height="48"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="1" />
					<feGaussianBlur stdDeviation="1" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.0666667 0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0.1 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_2444_138736"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="4" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.0666667 0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0.15 0"
					/>
					<feBlend
						mode="normal"
						in2="effect1_dropShadow_2444_138736"
						result="effect2_dropShadow_2444_138736"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect2_dropShadow_2444_138736"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	);
});

ChevronRightTopBar.displayName = 'ChevronRightTopBar';
