import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Tag } from 'components/atoms';
import { ProfileIcon } from 'components/compounds/UserIcon';

export const User = (props) => {
	const {
		showName = true,
		showTag = true,
		name = '',
		you = false,
		image,
		guest = null,
		staff = null,
		size = 24,
		extra = null,
		paxType = 'ADT',
		className = '',
	} = props;
	const { t } = useTranslation('generic');
	return (
		<div className={clsx('tw-flex tw-items-center', className)}>
			<ProfileIcon
				version={2}
				img={image}
				name={name}
				className={clsx('tw-border-none', {
					'tw-w-[16px] tw-h-[16px] tw-text-font-size-20': size === 16,
					'tw-w-[24px] tw-h-[24px] tw-text-font-size-30': size === 24,
				})}
				size="small"
				height={size}
				width={size}
			/>
			{showName && (
				<p
					className={clsx(
						'tw-typography-body2 tw-m-0 tw-ml-1 tw-text-color-text-subtle tw-flex tw-items-center',
						{
							'tw-typography-caption1': size === 16,
							'tw-typography-body2': size === 24,
						}
					)}
				>
					{name}
					{extra}
					{showTag && (
						<>
							{you && (
								<Tag
									title={t('tags.you')}
									type="warning"
									className="tw-ml-2 tw-p-1"
									size="small"
								/>
							)}
							{guest !== null &&
								guest !== 0 &&
								(staff === null || staff === 0) &&
								!you &&
								paxType === 'ADT' && (
								<Tag
									title={t('tags.guest')}
									type="blue"
									className="tw-ml-2"
									size="small"
								/>
							)}
							{guest !== null && paxType === 'CHD' && (
								<Tag
									title={t('tags.child')}
									type="processing"
									className="tw-ml-2"
									size="small"
								/>
							)}
							{guest !== null && paxType === 'INF' && (
								<Tag
									title={t('tags.infant')}
									type="processing"
									className="tw-ml-2"
									size="small"
								/>
							)}
						</>
					)}
				</p>
			)}
		</div>
	);
};
