export const userRoles = {
	traveler: 'traveler',
	travel_admin: 'travel_admin',
	travel_approver: 'travel_approver',
	book_for_others_staff: 'book_for_others_staff',
	expense_admin: 'expense_admin',
	expense_approver: 'expense_approver',
	expense_finance_admin: 'expense_finance_admin',
	expense_itilite_admin: 'expense_itilite_admin',
	submitter: 'submitter',
	travel_finance_admin: 'travel_finance_admin',
	travel_itilite_admin: 'travel_itilite_admin',
};
