import * as React from 'react';

export const StarRating = React.memo(
	({ size = 10, color = '#363E4F', className, ...rest }) => (
		<svg
			width="10"
			height="10"
			viewBox="0 0 10 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
			className={className}
		>
			<path
				d="M3.52023 3L4.52195 0.321241C4.6843 -0.10708 5.3157 -0.107081 5.47805 0.32124L6.47977 3H9.49037C9.9877 3 10.1895 3.57833 9.78207 3.85195L7.41803 5.43958L8.51846 8.34287C8.68837 8.79115 8.15602 9.18298 7.74871 8.90944L5 7.06348L2.25129 8.90944C1.84398 9.18298 1.31163 8.79115 1.48154 8.34287L2.58197 5.43958L0.217928 3.85195C-0.189505 3.57833 0.0122972 3 0.509632 3H3.52023Z"
				fill={color}
			/>
		</svg>
	)
);

StarRating.displayName = 'StarRating';
