import * as React from 'react';

export const CheckOut = React.memo(
	({ size = 12, color = '#CF2231', className, ...rest }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 12 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...rest}
			>
				<path
					d="M1 12C0.733333 12 0.5 11.9 0.3 11.7C0.1 11.5 0 11.2667 0 11V1C0 0.733333 0.1 0.5 0.3 0.3C0.5 0.1 0.733333 0 1 0H5.85V1H1V11H5.85V12H1ZM9.1 8.91667L8.38333 8.2L10.0833 6.5H4.25V5.5H10.05L8.35 3.8L9.06667 3.08333L12 6.01667L9.1 8.91667Z"
					fill={color}
				/>
			</svg>
		);
	}
);
