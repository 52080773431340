import * as React from 'react';

export const Seat = React.memo(
	({ size = 16, color = '#363E4F', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					fill={color}
					d="M2.705 1a1 1 0 00-.944 1.33l3.15 9a1 1 0 00.944.67h.836l-.276.553A1 1 0 007.309 14H11.5a.5.5 0 000-1H7.31l.5-1h4.69a1.5 1.5 0 000-3H7.655L5.089 1.67A1 1 0 004.145 1h-1.44zm4.807 10H5.855l-3.15-9h1.44l2.683 7.665A.5.5 0 007.3 10h5.2a.5.5 0 010 1H7.512z"
				></path>
				<path
					fill={color}
					d="M8 6.5a.5.5 0 01.5-.5h3a.5.5 0 110 1h-3a.5.5 0 01-.5-.5z"
				></path>
			</svg>
		);
	}
);

Seat.displayName = 'Seat';
