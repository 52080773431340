import * as React from 'react';

export const OfflineIcon = React.memo(() => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="178"
			height="150"
			fill="none"
			viewBox="0 0 178 150"
		>
			<path
				stroke="#FFE9CF"
				strokeLinecap="round"
				strokeWidth="16"
				d="M155 110c-13.066-23.294-37.728-39-66-39s-52.934 15.706-66 39"
			></path>
			<path
				stroke="#FFE9CF"
				strokeLinecap="round"
				strokeWidth="16"
				d="M135 121c-9.075-16.121-26.275-27-46-27s-36.925 10.879-46 27"
			></path>
			<path
				stroke="#FFE9CF"
				strokeLinecap="round"
				strokeWidth="16"
				d="M65 134c4.564-8.329 13.6-14 24-14s19.436 5.671 24 14"
			></path>
			<path
				fill="#fff"
				d="M117.354 59.795l11.277 5.872L90.5 131.5l-12.174 3.241 39.028-74.946z"
			></path>
			<path
				stroke="url(#paint0_linear_12163_37172)"
				d="M128.631 65.668L93 129.5"
			></path>
			<path
				fill="#fff"
				d="M108.198 41.818l36.48-15.18-7.554 10.39-31.34 8.278a1 1 0 01-.819-.14l-4.772-3.254a.5.5 0 01.052-.857l1.439-.746a1 1 0 01.763-.066l5.065 1.605a1 1 0 00.686-.03z"
			></path>
			<path
				fill="#EC5D25"
				d="M102.447 40.243l4.091 1.297a1 1 0 01.507 1.541l-1.132 1.557a1 1 0 01-1.372.238l-4.348-2.964a.5.5 0 01.052-.857l1.439-.746a1 1 0 01.763-.066z"
			></path>
			<path
				stroke="#000"
				strokeLinecap="round"
				d="M144.678 26.638l-36.48 15.18a1 1 0 01-.686.03l-5.065-1.605a1 1 0 00-.763.066l-1.439.746a.5.5 0 00-.052.857l4.772 3.253a1 1 0 00.819.14l31.34-8.277"
			></path>
			<path
				fill="#fff"
				stroke="#000"
				strokeLinecap="round"
				d="M112.905 64.747l-2.69 2.08c-.393.304-.15.933.346.894l8.163-.654 4.187-6.486-9.539 3.896a2.005 2.005 0 00-.467.27z"
			></path>
			<path
				fill="#9CA3AF"
				d="M135.3 58.47c-11.282 13.186-15.425 13.933-16.086 12.658-.478-.21-1.518-1.038-1.859-2.668 1.4-4.938 17.577-28.954 26.247-41.634 6.936-10.144 14.164-14.5 16.91-15.411 2.566-.252 4.959 2.282 3.118 8.09-1.365 4.306-14.228 22.481-28.33 38.965z"
			></path>
			<path
				fill="#fff"
				d="M118.211 70.965c-2.909.215 4.369-13.359 10.165-21.991 5.939-8.653 18.362-26.783 20.541-30.07 2.722-4.109 6.343-5.447 8.688-6.124 2.346-.678 3.464 2.518 1.811 6.61-1.322 3.275-8.798 13.193-12.371 17.743-8.399 11.188-25.925 33.618-28.834 33.832z"
			></path>
			<path
				fill="#E5E7EB"
				d="M155.779 18.339c-13.807 19.543-21.472 27.803-25.984 28.029l-12.284 22.566c-.158.889.153 2.228 2.667.476 2.513-1.751 10.561-11.64 14.271-16.365 6.685-8.55 20.828-26.886 23.913-31.837 3.856-6.19 1.155-8.391.214-8.903-.752-.41-2.067.662-2.63 1.25 1.285.504.424 3.4-.167 4.784z"
			></path>
			<path
				stroke="#000"
				strokeLinecap="round"
				d="M121.841 60.596c-2.377 3.39-6.403 10.117-3.494 9.902 2.909-.214 20.435-22.644 28.835-33.832 3.572-4.55 11.048-14.468 12.37-17.742 1.653-4.093.535-7.289-1.811-6.611-2.345.677-5.966 2.015-8.688 6.124-2.179 3.287-14.601 21.416-20.54 30.069"
			></path>
			<path
				fill="#E5E7EB"
				stroke="#000"
				d="M115.838 56.754l7.441 1.02c.716.097 1.095.9.716 1.514l-4.449 7.227a1 1 0 01-1.486.248l-6.662-5.469a1 1 0 01-.027-1.522l2.871-2.536a2.001 2.001 0 011.596-.482z"
			></path>
			<path
				stroke="#000"
				strokeLinecap="round"
				d="M119.214 71.127c.661 1.275 4.804.528 16.086-12.658 14.103-16.483 26.966-34.659 28.33-38.964 1.841-5.81-.552-8.343-3.118-8.091"
			></path>
			<path
				fill="#fff"
				stroke="#000"
				strokeLinecap="round"
				d="M132.991 68.18l-9.144-1.524a1 1 0 01-.608-1.62l2.985-3.638a1 1 0 011.282-.226l5.819 3.439a2.001 2.001 0 01.944 2.111l-.133.666a1 1 0 01-1.145.791z"
			></path>
			<path
				fill="#fff"
				d="M165.616 37.89c-7.292-6.572-9.283-9.926-9.367-10.781L147.86 39.01l23.153 9.074c.333.13.711.073.991-.151l1.348-1.081a1 1 0 00.183-.192l1.645-2.262a.5.5 0 00-.52-.78l-1.458.346a2 2 0 01-1.856-.51l-5.73-5.563z"
			></path>
			<path
				fill="#E5E7EB"
				d="M150.411 34.92l-2.441 4.034 23.162 8.951.631-1.607-21.352-11.379zM138.687 33.169l-2.44 4.034-30.649 7.938.631-1.607 32.458-10.365z"
			></path>
			<path
				fill="#EC5D25"
				d="M171.783 45.509l-.651 2.397 3.713-1.526a1 1 0 00.532-.516l.615-1.37a.5.5 0 00-.52-.7l-2.012.255a1.999 1.999 0 00-1.677 1.46z"
			></path>
			<path
				stroke="#000"
				strokeLinecap="round"
				d="M156.249 27.109c.084.855 2.075 4.209 9.367 10.782l5.785 5.615c.46.447 1.107.646 1.739.535l2.588-.455a.5.5 0 01.526.732l-.903 1.658a.996.996 0 01-.386.392l-2.999 1.693c-.262.148-.577.17-.857.06l-23.249-9.11"
			></path>
			<path
				fill="#000"
				d="M155.996 18.6l.426-.008a.972.972 0 00.911-.67c1.078-3.373.414-4.558-.483-5.581l-1.178.821-1.178.821c1.391.546 1.22 2.618.995 4.03a.505.505 0 00.507.588zM167.381 38.226l-.546 1.002 2.309 2.476c.302-.405.874-1.426.75-2.274-.08-.546-.642-1.111-1.199-1.536-.439-.335-1.05-.153-1.314.332zM125.252 32.996l.092 1.137-3.116 1.325c-.111-.493-.219-1.658.238-2.382.295-.467 1.038-.756 1.72-.917.537-.128 1.022.286 1.066.837zM160.883 32.251l-.546 1.002 2.309 2.476c.302-.405.874-1.426.75-2.273-.08-.546-.642-1.112-1.199-1.537-.44-.334-1.05-.153-1.314.332zM133.618 30.175l.091 1.138-3.116 1.324c-.111-.492-.219-1.658.239-2.382.294-.466 1.038-.755 1.719-.916.538-.128 1.022.286 1.067.836z"
			></path>
			<path
				stroke="#000"
				strokeLinecap="round"
				d="M153.648 24.493c-.813 1.345-3.061 4.818-5.546 7.95M142.793 39.06c-1.98 2.742-10.47 13.95-12.546 16.33"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_12163_37172"
					x1="128.674"
					x2="95.457"
					y1="65.689"
					y2="129.477"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EBC598"></stop>
					<stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
				</linearGradient>
			</defs>
		</svg>
	);
});

OfflineIcon.displayName = 'OfflineIcon';
