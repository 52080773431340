import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	approvalProcessDone: false,
};
export const approvals = createSlice({
	name: 'approvalSlice',
	initialState,
	reducers: {
		setApprovalProcessComplete: (state, action) => {
			state.approvalProcessDone = action.payload;
		},
	},
});

export const { setApprovalProcessComplete } = approvals.actions;

export default approvals.reducer;
