import * as React from 'react';

export const BaseProgress = React.memo(
	({ size = 16, color = '#868D9A', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				viewBox="0 0 16 16"
				fill="none"
			>
				<rect
					x="0.5"
					y="0.5"
					width="15"
					height="15"
					rx="7.5"
					fill="white"
				/>
				<rect
					x="0.5"
					y="0.5"
					width="15"
					height="15"
					rx="7.5"
					stroke="#9CA3AF"
				/>

				<rect
					xmlns="http://www.w3.org/2000/svg"
					x="0.5"
					y="0.5"
					width="15"
					height="15"
					rx="7.5"
					fill="white"
				/>
				<rect
					xmlns="http://www.w3.org/2000/svg"
					x="0.5"
					y="0.5"
					width="15"
					height="15"
					rx="7.5"
					stroke="#9CA3AF"
				/>
			</svg>
		);
	}
);

BaseProgress.displayName = 'BaseProgress';
