import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	fraudCheck: null,
};
export const paymentService = createSlice({
	name: 'paymentSlice',
	initialState,
	reducers: {
		setFraudCheckStatus: (state, action) => {
			state.fraudCheck = action.payload;
		},
	},
});

export const { setFraudCheckStatus } = paymentService.actions;

export default paymentService.reducer;
