import React from 'react';
import { useRive } from '@rive-app/react-canvas';
import clsx from 'clsx';

const DefaultLoader = () => {
	const { RiveComponent } = useRive({
		src: process.env.REACT_APP_IL_S3_URL + '/riv/il_animations.riv',
		artboard: 'Spinner',
		autoplay: true,
	});
	return (
		<div className={clsx('tw-text-center tw-h-10 tw-w-10')}>
			<RiveComponent />
		</div>
	);
};

export const DefaultPageLoader = React.memo(DefaultLoader);
