import * as React from 'react';

export const HandBaggage = React.memo(
	({ size = 16, color = '#363E4F', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					fill={color}
					d="M6 8.5a.5.5 0 011 0v3a.5.5 0 01-1 0v-3zM9.5 8a.5.5 0 00-.5.5v3a.5.5 0 001 0v-3a.5.5 0 00-.5-.5z"
				></path>
				<path
					fill={color}
					d="M7 1a1 1 0 00-1 1v4H4.75A1.75 1.75 0 003 7.75v4.5c0 .966.784 1.75 1.75 1.75H5v.5a.5.5 0 001 0V14h4v.5a.5.5 0 001 0V14h.25A1.75 1.75 0 0013 12.25v-4.5A1.75 1.75 0 0011.25 6H10V2a1 1 0 00-1-1H7zm2 5H7V2h2v4zm2.25 7h-6.5a.75.75 0 01-.75-.75v-4.5A.75.75 0 014.75 7h6.5a.75.75 0 01.75.75v4.5a.75.75 0 01-.75.75z"
				></path>
			</svg>
		);
	}
);

HandBaggage.displayName = 'HandBaggage';
