import { memo, useCallback, useState } from 'react';
// import clsx from 'clsx';

export const Image = memo(
	({
		src = '',
		alt = '',
		placeholder = '',
		width = '100%',
		height = '100%',
		className = '',
		srcSet = '',
		...rest
	}) => {
		const [isError, setIsError] = useState(false);
		let source = src,
			sourceSet = srcSet;
		if (source?.startsWith('http://')) {
			source = source.replace(/^http:/, 'https:');
		}
		if (srcSet) {
			sourceSet = srcSet.replace(/http:\/\//g, 'https://');
		}
		const handleImageError = useCallback(
			({ target }) => {
				target.onerror = null;
				if (placeholder && !isError) {
					target.src = placeholder;
					if (srcSet) {
						target.srcset = `${placeholder} 768w, ${placeholder} 1280w`;
					}
				}
				setIsError(true);
			},
			[placeholder, isError]
		);
		return (
			<img
				// className={clsx(
				// 	{
				// 		'tw-bg-[#ec5d252e]': isLoading,
				// 		'tw-bg-[#cf81622e]': isError,
				// 	},gs
				// 	className
				// )}
				className={className}
				src={source ? source : placeholder}
				placeholder={placeholder}
				alt={alt}
				onError={handleImageError}
				width={width}
				height={height}
				srcSet={sourceSet}
				{...rest}
			/>
		);
	}
);
