import clsx from 'clsx';
import { LoyaltyPoints } from 'icons';
import { MEMBERSHIP_RATES } from 'utils/constants/membership';

export const TagCurved = (props) => {
	const { type = MEMBERSHIP_RATES, text = '' } = props;

	const bgColors = {
		membership_rates: {
			bg: '#7855DF',
			icon: <LoyaltyPoints color={'#fff'} />,
			text: text,
		},
	};
	return (
		<div className="tw-h-7 tw-flex tw-absolute -tw-top-[1px] -tw-right-[1px]">
			<svg
				width="36"
				height="28"
				viewBox="0 0 36 28"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M36 0V28H34.7799C31.008 28 27.3905 25.8546 24.7233 22.0356L13.4989 5.96438C10.8317 2.14545 7.77197 0 4 0H36Z"
					fill={bgColors[type].bg}
				/>
			</svg>
			<div
				className={clsx(
					'tw-flex tw-items-center -tw-ml-[1px] tw-w-max tw-typography-body2Bold tw-py-1 tw-rounded-tr-lg tw-pr-5 tw-h-7 tw-text-white',
					{
						'tw-bg-[#7855DF]': type === 'membership_rates',
					}
				)}
			>
				<span className="tw-mt-0.5">{bgColors[type].icon}</span>
				<span className="tw-ml-1">{bgColors[type].text}</span>
			</div>
		</div>
	);
};
