import * as React from 'react';

export const Search = React.memo(
	({ size = 16, color = '#6B7280', ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 24 24"
				{...rest}
			>
				<path
					fill={color}
					d="M14.391 15.452a7 7 0 111.06-1.06l5.33 5.328a.75.75 0 11-1.061 1.06l-5.329-5.328zM15.5 10a5.5 5.5 0 10-11 0 5.5 5.5 0 0011 0z"
				></path>
			</svg>
		);
	}
);

Search.displayName = 'Search';
