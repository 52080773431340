import { createSlice } from '@reduxjs/toolkit';
import { ENTER_FARE_QUOTE } from 'views/CreateTrip/constants/animationConstants';

const initialState = {
	loaderAnimations: {
		currentStep: ENTER_FARE_QUOTE,
		presentState: 'enter',
	},
};

export const carSelection = createSlice({
	name: 'carSelection',
	initialState,
	reducers: {
		setLoaderAnimationSteps: (state, action) => {
			state.loaderAnimations.currentStep = action.payload;
		},
		setLoaderAnimationCurrentState: (state, action) => {
			state.loaderAnimations.presentState = action.payload;
		},
	},
});

export const { setLoaderAnimationSteps, setLoaderAnimationCurrentState } =
	carSelection.actions;

export default carSelection.reducer;
