export const EXIT_FARE_QUOTE = 'exit_fare_quote';
export const ENTER_FARE_QUOTE = 'enter_fare_quote';
export const ENTER = 'enter';
export const EXIT = 'exit';
export const EXIT_RESULTS = 'exit_results';

export const FARE_CHECK_ENTRY_ANIMATION = {
	src: process.env.REACT_APP_IL_CDN_URL + '/riv/farecheck_dummy_state.riv',
	animations: '',
	artboard: '1st animation',
	stateMachines: 'State Machine 1',
};

export const FARE_CHECK_EXIT_ANIMATION = {
	src: process.env.REACT_APP_IL_CDN_URL + '/riv/loader_farecheck_exit.riv',
	animations: '',
	artboard: '2nd animation',
	stateMachines: 'State Machine 1',
};

export const CONFIRMATION_PAGE_ENTRY_ANIMATION_URL = {
	src:
		process.env.REACT_APP_IL_CDN_URL +
		'/riv/combined_confirmation_loader.riv',
	artboard: 'Final 7 feb',
	animations: '',
	stateMachines: 'State Machine 1',
};
export const CONFIRMATION_PAGE_EXIT_ANIMATION_URL = {
	src:
		process.env.REACT_APP_IL_CDN_URL +
		'/riv/combined_confirmation_loader.riv',
	artboard: 'Final 7 feb',
	animations: '',
	stateMachines: 'State Machine 1',
};

export const BOOKING_PAGE_ANIMATION = {
	src: process.env.REACT_APP_IL_S3_URL + '/riv/bookingLoader.riv',
	name: 'Loading_confirmation',
	artboard: '',
	animations: '',
	stateMachines: '',
};
