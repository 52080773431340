import * as React from 'react';
import { Carousel as AntCarousel } from 'antd';
import clsx from 'clsx';
import { ChevronLeftTopBar } from 'icons/ChevronLeftTopBar';
import { ChevronRightTopBar } from 'icons/ChevronRightTopBar';
import PropTypes from 'prop-types';

import classes from './Carousel.module.scss';

const CarouselComp = React.forwardRef((props, ref) => {
	const {
		className = '',
		list = [],
		prevArrow = <ChevronLeftTopBar />,
		nextArrow = <ChevronRightTopBar />,
		dots = true,
		slidesToShow = 1,
		fadeEffect,
		...rest
	} = props;

	const PrevBtn = (btnProps) => {
		const { onClick } = btnProps;
		return (
			onClick && (
				<div
					className={clsx(classes.prev, {
						[classes.prevFade]: fadeEffect,
					})}
					onClick={onClick}
				>
					{prevArrow}
				</div>
			)
		);
	};

	const NextBtn = (btnProps) => {
		const { onClick } = btnProps;
		return (
			onClick && (
				<div
					className={clsx(classes.next, {
						[classes.nextFade]: fadeEffect,
					})}
					onClick={onClick}
				>
					{nextArrow}
				</div>
			)
		);
	};

	return (
		<div className={classes.carouselWrapper}>
			<AntCarousel
				ref={ref}
				className={clsx(classes.carouselMain, className)}
				arrows={true}
				dots={dots}
				slidesToShow={slidesToShow}
				prevArrow={<PrevBtn />}
				nextArrow={<NextBtn />}
				{...rest}
			>
				{list.map((item) => item)}
			</AntCarousel>
		</div>
	);
});

CarouselComp.propTypes = {
	className: PropTypes.string,
	prevArrow: PropTypes.element,
	nextArrow: PropTypes.element,
	list: PropTypes.array,
};

export const Carousel = React.memo(CarouselComp);
