import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import Backend from 'i18next-http-backend';
import Locize from 'i18next-locize-backend';

const locizeOptions = {
	projectId: process.env.REACT_APP_LOCIZE_PROJECTID,
	// apiKey: process.env.REACT_APP_LOCIZE_APIKEY, //! YOU should not expose your apps API key to production!!!
	referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
	version: process.env.REACT_APP_LOCIZE_VERSION,
};
// const options = {
// 	order: ['querystring', 'navigator'],
// 	lookupQuerystring: 'lng',
// 	lookupLocalStorage: 'il_app_lang',
// 	caches: ['localStorage'],
// };
//! Local i18 file path..fetch from absoulte path
const BASE_URL = window.location.origin;
const locali18Loadpath = `${BASE_URL || '.'}/locales/{{lng}}/{{ns}}.json`;

i18n.use(ChainedBackend)
	.use(initReactI18next)
	// .use(LanguageDetector)
	.init({
		fallbackLng: 'en', //! if i18next didn't find the translation, show the fallback language text
		// detection: options,//! Enable it when we will add multiple languages options
		ns: ['generic'], //! this will have common namespace used across project
		interpolation: {
			escapeValue: false,
			skipOnVariables: false,
		},
		backend: {
			backends: [Locize, Backend], //! will check for locize backend first...if not will load from build cache
			backendOptions: [
				locizeOptions,
				{
					loadPath: locali18Loadpath,
				},
			],
		},
		saveMissing: false, // you should not use saveMissing in production
	});

export default i18n;
