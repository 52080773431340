import * as React from 'react';

export const Traveler = React.memo(
	({ size = 16, color = '#6b7280', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				{...rest}
			>
				<path
					d="M12 8C12.8284 8 13.5 8.67157 13.5 9.5V10C13.5 11.9714 11.6405 14 8.5 14C5.35951 14 3.5 11.9714 3.5 10V9.5C3.5 8.67157 4.17157 8 5 8H12ZM12 9H5C4.72386 9 4.5 9.22386 4.5 9.5V10C4.5 11.4376 5.93216 13 8.5 13C11.0678 13 12.5 11.4376 12.5 10V9.5C12.5 9.22386 12.2761 9 12 9ZM8.5 1.5C10.0188 1.5 11.25 2.73122 11.25 4.25C11.25 5.76878 10.0188 7 8.5 7C6.98122 7 5.75 5.76878 5.75 4.25C5.75 2.73122 6.98122 1.5 8.5 1.5ZM8.5 2.5C7.5335 2.5 6.75 3.2835 6.75 4.25C6.75 5.2165 7.5335 6 8.5 6C9.4665 6 10.25 5.2165 10.25 4.25C10.25 3.2835 9.4665 2.5 8.5 2.5Z"
					fill={color}
				/>
			</svg>
		);
	}
);

Traveler.displayName = 'Traveler';
