import * as React from 'react';

export const Breakfast = React.memo(
	({ size = 16, color = '#6B7280', ...rest }) => (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M2.5 0C2.77614 0 3 0.223858 3 0.5C3 0.969538 3.27449 1.20587 3.8 1.6L3.83977 1.62978C4.31392 1.98457 5 2.49793 5 3.5C5 3.77614 4.77614 4 4.5 4C4.22386 4 4 3.77614 4 3.5C4 3.03046 3.72551 2.79413 3.2 2.4L3.16023 2.37022C2.68608 2.01543 2 1.50207 2 0.5C2 0.223858 2.22386 0 2.5 0ZM5.5 0C5.77614 0 6 0.223858 6 0.5C6 0.969538 6.27449 1.20587 6.8 1.6L6.83977 1.62978C7.31392 1.98457 8 2.49793 8 3.5C8 3.77614 7.77614 4 7.5 4C7.22386 4 7 3.77614 7 3.5C7 3.03046 6.72551 2.79413 6.2 2.4L6.16023 2.37022C5.68608 2.01543 5 1.50207 5 0.5C5 0.223858 5.22386 0 5.5 0ZM9 0.5C9 0.223858 8.77614 0 8.5 0C8.22386 0 8 0.223858 8 0.5C8 1.50207 8.68608 2.01543 9.16023 2.37022L9.2 2.4C9.72551 2.79413 10 3.03046 10 3.5C10 3.77614 10.2239 4 10.5 4C10.7761 4 11 3.77614 11 3.5C11 2.49793 10.3139 1.98457 9.83977 1.62978L9.8 1.6C9.27449 1.20587 9 0.969538 9 0.5ZM13 6H13.5C14.8807 6 16 7.11929 16 8.5C16 9.88071 14.8807 11 13.5 11H12.793C12.14 13.3085 10.0176 15 7.5 15C4.46243 15 2 12.5376 2 9.5V5.8913C2 5.39905 2.39905 5 2.8913 5H12.1087C12.6009 5 13 5.39905 13 5.8913V6ZM3 6V9.5C3 11.9853 5.01472 14 7.5 14C9.98528 14 12 11.9853 12 9.5V6H3ZM13 7V9.5C13 9.66854 12.9924 9.83532 12.9776 10H13.5C14.3284 10 15 9.32843 15 8.5C15 7.67157 14.3284 7 13.5 7H13Z"
				fill="#6B7280"
			/>
		</svg>
	)
);

Breakfast.displayName = 'Breakfast';
