import * as React from 'react';
import { Slider as AntSlider } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './Slider.module.scss';

const SliderComp = (props) => {
	const {
		label = '',
		className = '',
		onChange = () => {},
		onAfterChange = () => {},
		defaultValue = [],
		step = 10,
		range = true,
		disabled = false,
		value,
		...rest
	} = props;
	return (
		<>
			{label && <p className={classes.label}>{label}</p>}
			<AntSlider
				className={clsx(classes.slider, className)}
				range={range}
				step={step}
				defaultValue={defaultValue}
				onChange={onChange}
				onAfterChange={onAfterChange}
				disabled={disabled}
				value={value}
				{...rest}
			/>
		</>
	);
};

SliderComp.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	onAfterChange: PropTypes.func,
	defaultValue: PropTypes.array,
	step: PropTypes.number,
	range: PropTypes.bool,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	value: PropTypes.any,
};

export const Slider = React.memo(SliderComp);
