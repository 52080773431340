import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import classes from './LoadingSpinner.module.scss';

const sizeMapping = {
	small: 24,
	medium: 36,
	large: 48,
};

export const LoadingSpinner = ({ size = 'small', ...rest }) => (
	<div className={classes.spinnerContainer} {...rest}>
		<Spin
			indicator={
				<LoadingOutlined style={{ fontSize: sizeMapping[size] }} spin />
			}
		/>
	</div>
);
