import * as React from 'react';

export const Travel = React.memo(
	({ size = 24, color = '#6b7280', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 24 24"
				{...rest}
			>
				<path
					fill={color}
					fillRule="evenodd"
					d="M10.5 3.5V7h3V3.5h-3zM15 7V3a1 1 0 00-1-1h-4a1 1 0 00-1 1v4H8a3 3 0 00-3 3v8c0 1.398.956 2.573 2.25 2.905v.345a.75.75 0 001.5 0V21h6.5v.25a.75.75 0 001.5 0v-.345A3.001 3.001 0 0019 18v-8a3 3 0 00-3-3h-1zM8 8.5A1.5 1.5 0 006.5 10v8A1.5 1.5 0 008 19.5h8a1.5 1.5 0 001.5-1.5v-8A1.5 1.5 0 0016 8.5H8zM9.75 10a.75.75 0 01.75.75v6.5a.75.75 0 01-1.5 0v-6.5a.75.75 0 01.75-.75zm4.5 0a.75.75 0 01.75.75v6.5a.75.75 0 01-1.5 0v-6.5a.75.75 0 01.75-.75z"
					clipRule="evenodd"
				></path>
			</svg>
		);
	}
);

Travel.displayName = 'Travel';
