import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { SystemError } from 'icons';

import { Button } from 'components/atoms';

const CancelError = ({ errorType = 500 }) => {
	const { t } = useTranslation(['trip_cancellation', 'generic']);
	const errorDescription =
		errorType === 404
			? t('cancel_booking.error.not_found')
			: t('cancel_booking.error.description');
	return (
		<>
			<div
				className={clsx(
					'tw-flex tw-items-center tw-justify-center tw-flex-col tw-h-[90vh]'
				)}
			>
				<SystemError />
				<p className="tw-text-color-text-subtle tw-mb-0 !tw-font-medium tw-typography-title2 tw-text-center tw-px-[20%]">
					{errorDescription}
				</p>
				<div className="tw-flex tw-justify-between">
					<ul className="tw-flex tw-mt-0 tw-pl-0 marker:text-green">
						<li className="tw-list-none">
							<Button
								type="link"
								href="javascript:void(Tawk_API.toggle())"
								className="tw-mt-2 tw-text-center tw-underline tw-capitalize"
							>
								{t(
									'card.amount_not_available_footer.contact_support'
								)}
							</Button>
						</li>
						<li className="tw-text-color-text-primary tw-ml-6">
							<Button
								type="link"
								href="https://www.itilite.com/in/help-center"
								className="tw-mt-2 tw-text-center tw-underline"
							>
								{t('error.404.button.link2', {
									ns: 'generic',
								})}
							</Button>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
};

export default CancelError;
