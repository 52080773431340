import * as React from 'react';

export const InfoInverted = React.memo(
	({ size = 16, color = '#E11D48', ...rest }) => (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM7 5C7 4.44772 7.44772 4 8 4C8.55229 4 9 4.44772 9 5V7C9 7.55229 8.55229 8 8 8C7.44772 8 7 7.55229 7 7V5ZM7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11Z"
				fill={color}
			/>
		</svg>
	)
);

InfoInverted.displayName = 'InfoInverted';
