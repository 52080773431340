const GTMService = (function () {
	function _init(__res) {
		// you have to provide user information from your store
		const userinfo = {
			username: __res?.userName,
			email: __res?.email,
			first_name: __res?.firstName,
			last_name: __res?.lastName,
			role: __res?.role,
			client_id: __res?.clientId,
			user_id: __res?.userId,
			company_name: __res?.companyName,
			usm_company_id: __res?.usmCompanyId,
		};
		window.dataLayer.push(userinfo);
		window.dataLayer.push({ event: 'user-data-set-4' });
	}
	return {
		init: _init,
	};
})();

export default GTMService;
