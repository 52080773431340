import * as React from 'react';

export const Hotel = React.memo(
	({ size = 16, color = '#6B7280', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					fill={color}
					fillRule="evenodd"
					d="M1.25 3.8a.75.75 0 01.75.75v6.25h6V7.3a1.5 1.5 0 011.5-1.5h4A2.5 2.5 0 0116 8.3v5.75a.75.75 0 01-1.5 0V12.3H2v1.75a.75.75 0 01-1.5 0v-9.5a.75.75 0 01.75-.75zm13.25 7V8.3a1 1 0 00-1-1h-4v3.5h5zM3 7.8a2 2 0 114 0 2 2 0 01-4 0zm2-.5a.5.5 0 100 1 .5.5 0 000-1z"
					clipRule="evenodd"
				></path>
			</svg>
		);
	}
);

Hotel.displayName = 'Hotel';
