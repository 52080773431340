import * as React from 'react';
import LogEvent from 'analytics/LogEvent';
import { Button as AntButton } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './Button.module.scss';

const ButtonComp = (props) => {
	const {
		type = 'primary',
		className = '',
		children,
		onClick = () => {},
		disabled = false,
		analytics = {},
		...rest
	} = props;
	return (
		<LogEvent analytics={analytics}>
			<AntButton
				disabled={disabled}
				className={clsx(
					classes.button,
					className,
					{
						'tw-cursor-not-allowed': disabled,
						'tw-opacity-40': disabled,
					},
					classes[type],
					disabled && classes.disabled
				)}
				onClick={onClick}
				type={type}
				{...rest}
			>
				{children}
			</AntButton>
		</LogEvent>
	);
};

ButtonComp.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	onClick: PropTypes.func,
	analytics: PropTypes.object,
};

export const Button = React.memo(ButtonComp);
