import { createSlice } from '@reduxjs/toolkit';
import { reschedulePageType } from 'views/CreateTrip/constants/rescheduleConstants';

const initialState = {
	tripID: '',
	pageView: reschedulePageType.SELECTION,
	selectedPax: [],
	selectedIndex: null,
	selectedSubIndex: [],
};

export const rescheduleBookingSlice = createSlice({
	name: 'rescheduleBookingSlice',
	initialState,
	reducers: {
		setRescheduleTripID: (state, action) => {
			state.tripID = action.payload;
		},
		setRescheduleView: (state, action) => {
			state.pageView = action.payload;
		},
		setRescheduleSelectedPax: (state, action) => {
			state.selectedPax = action.payload;
		},
		setRescheduleSelectedLegIndex: (state, action) => {
			state.selectedIndex = action.payload;
		},
		setRescheduleSelectedLegSubIndex: (state, action) => {
			state.selectedSubIndex = action.payload;
		},
		resetRescheduleRedux: (state) => {
			state.tripID = '';
			state.selectedPax = [];
			state.selectedSubIndex = [];
			state.selectedIndex = null;
			state.pageView = reschedulePageType.SELECTION;
		},
	},
});

export const {
	setRescheduleTripID,
	setRescheduleView,
	setRescheduleSelectedPax,
	setRescheduleSelectedTripType,
	setRescheduleBookingDetails,
	setRescheduleSelectedLegIndex,
	setRescheduleSelectedLegSubIndex,
	resetRescheduleRedux,
} = rescheduleBookingSlice.actions;

export default rescheduleBookingSlice.reducer;
