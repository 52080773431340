import React from 'react';

export const Lock = React.memo(({ size = 16, color = '#000', ...rest }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		fill="none"
		viewBox="0 0 16 16"
		{...rest}
	>
		<path
			fill={color}
			d="M9 9a1 1 0 11-2 0 1 1 0 012 0zM5 4h-.5A2.5 2.5 0 002 6.5v5A2.5 2.5 0 004.5 14h7a2.5 2.5 0 002.5-2.5v-5A2.5 2.5 0 0011.5 4H11v-.5a3 3 0 00-6 0V4zm1-.5a2 2 0 114 0V4H6v-.5zM11.5 5A1.5 1.5 0 0113 6.5v5a1.5 1.5 0 01-1.5 1.5h-7A1.5 1.5 0 013 11.5v-5A1.5 1.5 0 014.5 5h7z"
		></path>
	</svg>
));
