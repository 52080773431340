import * as React from 'react';

export const Forks = React.memo(
	({ size = 16, color = '#363E4F', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					d="M3.5 1C3.77614 1 4 1.22386 4 1.5V4.5C4 5.15311 4.4174 5.70873 5 5.91465V1.5C5 1.22386 5.22386 1 5.5 1C5.77614 1 6 1.22386 6 1.5V5.91465C6.5826 5.70873 7 5.15311 7 4.5V1.5C7 1.22386 7.22386 1 7.5 1C7.77614 1 8 1.22386 8 1.5V4.5C8 5.70948 7.14112 6.71836 6 6.94999V14.5C6 14.7761 5.77614 15 5.5 15C5.22386 15 5 14.7761 5 14.5V6.94999C3.85888 6.71836 3 5.70948 3 4.5V1.5C3 1.22386 3.22386 1 3.5 1ZM10.4786 2.47855C10.6376 2.31949 10.8169 2.19613 11 2.11452V7H10V3.5C10 3.16262 10.1737 2.78338 10.4786 2.47855ZM11 8V14.5C11 14.7761 11.2239 15 11.5 15C11.7761 15 12 14.7761 12 14.5V1.5C12 1.22386 11.7761 1 11.5 1C10.8374 1 10.2166 1.32627 9.77145 1.77145C9.32627 2.21662 9 2.83738 9 3.5V7.5C9 7.77614 9.22386 8 9.5 8H11Z"
					fill={color}
				/>
			</svg>
		);
	}
);

Forks.displayName = 'Forks';
