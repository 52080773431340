import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { validateAccessToken } from 'auth/validateToken';
import LocalStorageService from 'services/LocalStorage';

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_USM_URL,
	credentials: 'same-origin',
	prepareHeaders: async (headers) => {
		// By default, if we have a token in the localstorage, validate expiry and use that for authenticated requests
		const token = await validateAccessToken().then((r) => r);
		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
			headers.set('role', LocalStorageService.getRole());
			headers.set('client-id', LocalStorageService.getClientID());
		}
		return headers;
	},
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const usmAPI = createApi({
	/**
	 * `reducerPath` is optional and will not be required by most users.
	 * This is useful if you have multiple API definitions,
	 * e.g. where each has a different domain, with no interaction between endpoints.
	 * Otherwise, a single API definition should be used in order to support tag invalidation,
	 * among other features
	 */
	reducerPath: 'API/USM',
	/**
	 * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
	 */
	baseQuery: baseQueryWithRetry,
	/**
	 * Tag types must be defined in the original API definition
	 * for any tags that would be provided by injected endpoints
	 */
	tagTypes: ['USM'],
	/**
	 * This api has endpoints injected in adjacent files,
	 * which is why no endpoints are shown below.
	 * If you want all endpoints defined in the same file, they could be included here instead
	 */
	endpoints: () => ({}),
});
