import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	mode: 'edit',
	bookingAmt: {},
	cancelSelections: {},
	totalDeductionAmt: {},
	totalBookingAmt: {},
	timeExpired: false,
	oldFares: null,
	oldSelections: null,
};

export const cancelBookingSlice = createSlice({
	name: 'userTripSelections',
	initialState,
	reducers: {
		setCancelMode: (state, action) => {
			state.mode = action.payload;
		},
		setCancelSelections: (state, action) => {
			state.cancelSelections = action.payload;
		},
		setBookingAmt: (state, action) => {
			state.bookingAmt = action.payload;
		},
		setTotalDeductionAmt: (state, action) => {
			state.totalDeductionAmt = action.payload;
		},
		setTotalBookingAmt: (state, action) => {
			state.totalBookingAmt = action.payload;
		},
		setOldFares: (state, action) => {
			state.timeExpired = true;
			state.oldFares = action.payload;
		},
		setOldSelections: (state, action) => {
			state.oldSelections = action.payload;
		},
	},
});

export const {
	setCancelMode,
	setCancelSelections,
	setBookingAmt,
	setTotalDeductionAmt,
	setTotalBookingAmt,
	setOldFares,
	setOldSelections,
} = cancelBookingSlice.actions;

export default cancelBookingSlice.reducer;
