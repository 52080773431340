import * as React from 'react';

export const PriceIncrease = React.memo(
	({ size = 16, color = '#6b7280', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path
					fill={color}
					d="M10.669 3a.75.75 0 000 1.5h1.69l-3.91 3.909-1.47-1.47a.75.75 0 00-1.06 0l-4.5 4.5a.75.75 0 001.06 1.061l3.97-3.97L7.92 10a.75.75 0 001.06 0l4.44-4.44v1.69a.75.75 0 001.5 0v-3.5a.75.75 0 00-.75-.75h-3.5z"
				></path>
			</svg>
		);
	}
);

PriceIncrease.displayName = 'Price Increase';
